import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import {Auth} from 'aws-amplify';
import { Link } from "react-router-dom";

import Typography from '../../../StorybookComponents/Typography';

import localStorage from '../../../components/localStorage';
import logger from '../../../components/Logger';

import ChangePasswordPage from './component';

import {
  forgotPasswordSubmit,
  forgotPassword,
  changePassword,
  no_redirects,
  redirectAfterAuth,
  getAuthState,
  login
} from '../../../utils/auth_functions';


import { Formik } from 'formik'

const Yup = require('yup');

let notification_id = 0;
let do_once;

const NotificationBody = React.memo(withStyles(theme => ({
  typo: {
    fontFamily: theme.openSans,
    fontSize:14,
    fontWeight: theme.fontWeight.bold,
    lineHeight:1.5,
    padding:`auto ${theme.margins.xs}px`,
    textDecoration: 'none',
    color: 'white',
  },
  underline: {
    textDecoration: 'underline',
  }
}))(({classes, content}) => (
    <Typography
        color = "white"
        className = {classes.typo}
        component = {'span'}
    >
    {content}
      <Link
        className = {classes.typo + ' ' + classes.underline}
        to = { '/login' }>
        {' Sign In'}
      </Link>
    </Typography>
)));


const success_notification = id => ({
  id: `success-email-reset-notification${id}`,
  type: "warning",
  body: "Please check your email to reset password: If your email is valid, we have sent you instructions to reset your password."
});

const fail_notification = id => ({
  id: `fail-email-reset-notification${id}`,
  type: "fail",
  body: `The password reset link has expired since it has been used or is too old. Please Resend Verification Email.`
});

const no_user_notification = id => ({
  id: `something-went-wrong-notification${id++}`,
  type: "fail",
  body: "The email you entered didn't match any existing member."
});

const limit_exceeded_notification = id => ({
  id: `limit-exceeded-reset-notification${id}`,
  type: "warning",
  body: "Attempt limit exceeded, please try after some time."
});

const unauthorised = id => ({
  id: `unauthorised-${id}`,
  type: 'warning',
  body: "We were unable to set your password, please verify the email again."
})

const hr_reset = () => window.setTimeout(() => window.location.href = process.env.REACT_APP_ACCOUNTS_LINK + '/set-password', 1200)

const ChangePassword = ({
  oldPassword,
  classes,
  globalError,
  email,
  code,
  history,
  set_notifications,
  set_dispaly_page,
  redirectAppAfterLogin,
  redirectAppAfterLoginAlias,
  displayPage
}) => {

    async function submitRequiredPassword(
      { password__password__required: password },
      { setErrors, setSubmitting, setStatus }
    ) {
      try {
        console.log("submitting changePasswordRequired form...");
        const user = await login(email, code);
        logger.info(email,"set-password","submitRequiredPassword", {user});

        if (user && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          const loggedUser = await Auth.completeNewPassword(user,password);
          await redirectAfterAuth(redirectAppAfterLogin, redirectAppAfterLoginAlias);
        }
        else {
          history.push('/set-password');
        }

      } catch(err) {
        logger.error(email,"set-password",err,"submitRequiredPassword");

        if (err === "Password cannot be empty") {
          history.push('/set-password');
          return;
        }
        const {code} = err;
        let errors = {};
        switch(code) {
          case 'InvalidPasswordException':
          case "InvalidParameterException":
            errors.password__password__required = "Your password should be at least 12 characters long. It should contain at least one alphabetic character and one numeric character.";
            break;
          case "LimitExceededException":
          case "TooManyRequestsException":
            set_notifications(limit_exceeded_notification(notification_id++));
            hr_reset();
            break;
          case "PasswordResetRequiredException":
            history.push('/set-password');
            break;
          case 'UserNotConfirmedException':
            history.push('/verify');
            break;
          case "NotAuthorizedException":
            set_notifications(unauthorised(notification_id++));
            hr_reset();
            break;
          default:
            console.error("Error in submitPassword(): ",err);
            errors = {}
        }
        if (Object.keys(errors).length) {
          setErrors(errors);
        }
        else {
          setStatus({ global: err.message || err });
        }
      } finally {
          window.history.replaceState({},"set-password","set-password");
          setSubmitting(false);
      }
   }


  /* /////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  //                                CHANGE PASSWORD PAGE                            //
  ///////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////// */

    async function confirmPSResetCode(code, email, setSubmitting) {
      try {
        const user = await forgotPasswordSubmit(email.toLowerCase(), code, oldPassword); // temporary password
        logger.info(email,"set-password","confirmPSResetCode", {user});

        return user;
      } catch(error) {
        logger.error(email,"set-password",error,"confirmPSResetCode");

        setSubmitting(false)
        if (error === 'Code cannot be empty') {
          return set_notifications(
            fail_notification(notification_id++),
            () => {
              history.push('/set-password');
              set_dispaly_page('resetPassword')
            }
          );
        }

        const {code} = error;
        switch(code) {
          case "UserNotFoundException":
          case "NotAuthorizedException":
            break;
          case "TooManyRequestsException":
          case "TooManyFailedAttemptsException":
          case "LimitExceededException":
            set_notifications(limit_exceeded_notification(notification_id++));
            hr_reset();
            break;
          case "UserNotConfirmedException":
            history.push('/verify');
            break;
          case "CodeMismatchException":
          case "InvalidParameterException":
          case "ExpiredCodeException":
          default:
            console.error("Error in confirmPSResetCode(): ", error);
            return set_notifications(
              fail_notification(notification_id++),
              () => {
                history.push('/set-password');
                set_dispaly_page('resetPassword')
              }
            );
          }
        }
      }

      async function submitPassword(
        { password__password__required: password },
        { setErrors, setSubmitting, setStatus }
      ) {
        try {
          console.log("submitting changePassword form...");

          const user = await confirmPSResetCode(code, email, setSubmitting);
          logger.info(email,"set-password","submitPassword", {user});

          if (Boolean(user)) {
            await changePassword(oldPassword, password);
            console.log("success! removing attempt limit for user from cache...");
            localStorage.removeItem(`${user.attributes.sub}-attempt-limit`);
            localStorage.removeItem(`${email}-login-limit`);

            console.log("redirecting to ",redirectAppAfterLogin, " alias: ",redirectAppAfterLogin);

            await redirectAfterAuth(redirectAppAfterLogin, redirectAppAfterLoginAlias);

          }
        } catch(err) {
          logger.error(email,"set-password",err,"submitPassword");

          const {code} = err;
          let errors = {};
          setSubmitting(false);
          switch(code) {
            case 'InvalidPasswordException':
            case "InvalidParameterException":
              errors.password__password__required = "Your password should be at least 12 characters long. It should contain at least one alphabetic character and one numeric character.";
              break;
            case "LimitExceededException":
            case "TooManyRequestsException":
              set_notifications(limit_exceeded_notification(notification_id++));
              hr_reset();
              break;
            case "PasswordResetRequiredException":
              history.push('/set-password');
              break;
            case 'UserNotConfirmedException':
              history.push('/verify');
              break;
            default:
              console.error("Error in submitPassword(): ",err);
              errors = {}
          }
          if (Object.keys(errors).length) {
            setErrors(errors);
          }
          else {
            setStatus({ global: err.message || err });
            hr_reset();
          }
        }
      }

      async function resendEmail(email, setFieldValue, setSubmitting, setStatus) {
         try {
           await forgotPassword(email.toLowerCase());
           logger.info(email,"set-password","resendEmail");

           set_notifications(success_notification(notification_id++));
         }
         catch(err) {
           logger.error(email,"set-password",err,"resendEmail");

           if (err.message.indexOf('Bad user') > -1 || err.code === "InvalidParameterException") {
             set_notifications(success_notification(notification_id++));
           }
           else {
             switch(err.code) {
               case "UserNotFoundException":
                 set_notifications(success_notification(notification_id++));
                 break;
               case "LimitExceededException":
                 set_notifications(limit_exceeded_notification(notification_id++));
                 break;
               case "InvalidParameterException":
                 history.push('/verify');
                 break;
               case "PasswordResetRequiredException":
                 history.push('/set-password');
                 break;
               default:
                 console.error("Error in sendResetEmail(): ",err);
                 set_notifications(success_notification(notification_id++));

             }
           }
         }
         finally {
           setSubmitting(false);
         }
    }


    const fields = [
      {name: "email", type: "email", label: "Email", required: true},
      {name: "password", type: "password", label: "New Password", required: true},
      {name: "ps_confirm", type: "password_confirm", label: "Confirm Password", required: true}
    ];

    return (
      <ChangePasswordPage
        onSubmit = { displayPage === "changePassword" ? submitPassword : submitRequiredPassword }
        resendEmail = { displayPage === "changePassword" ? resendEmail : null }
        email = {email}
      />
    )
}


export default React.memo(ChangePassword);
