import React from 'react';
import { withRouter } from "react-router";

import { Auth } from 'aws-amplify';

import Loader from './app/components/loading';
import logger from './app/components/Logger';
import localStorage from "./app/components/localStorage";

import {
  logout,
  getQueryStringValue
} from './app/utils/auth_functions';

import {  Route, Redirect, Switch } from "react-router-dom";


import './index.css';


import Login from './app/pages/login/login_page';
import NewLogin from './app/pages/NewAuthForm';
import ForgotPassword from './app/pages/reset';
import Verify from './app/pages/verify';
import Profile from './app/pages/profile';
import { DateTime } from 'luxon';
import Version from "./app/StorybookComponents/PopUp/version";
import { isLatest, getVersion, getDrawer, setVersion } from "./services"

export const isDev = process.env.NODE_ENV !== "production";

export const logoutInMyhyly = (user, goto = getQueryStringValue('goto')) => {
  const logoutPath = [
    "https://"
    , process.env.REACT_APP_MyhylyHost
    , "/logout"
    , "?goto="
    , goto
    , "&token="
    , user.signInUserSession.idToken.jwtToken
  ].join('');

  window.location.href = logoutPath;
}

export const shortExpiration = () => new Date(
  DateTime.local().plus({ seconds: 20 }).toString()
).toUTCString();

const getRedirect = () => window.localStorage.getItem('redirect-app-after-auth');

export const handleGoToCache = (cookieName = 'redirect-app-after-auth') => {
  const goto      = getQueryStringValue('goto') || "accounts",
    goToAuthRoute = !['accounts', 'set-password', 'login', 'verify'].includes(goto) && goto;
  if (
    goto 
    && goToAuthRoute
    && (
      !!!getRedirect() || !['myhyly', 'myhyly-probe'].includes(goto)
    )
  ) {
    window.localStorage.setItem(cookieName, goto)
  }
  return { goToAuthRoute, goto };
}

const LogoutComponent = ({ user }) => {
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => logoutInMyhyly(user), [user]);
  if (loading) { return <Loader />; }
  return null;
}

const CognitoLogoutComponent = ({ setState, user }) => {

  const attr = user && user.attributes;
  const email = attr.email || JSON.parse(attr.identities)[0].userId;

  React.useEffect(() => {
    async function logout_() {

      try {
        await logout();
        const { goto } = handleGoToCache();
        setState(state => ({ ...state, user: null, isLoggingOut: true, goToAuthRoute: goto }))

        logger.info(email, "cognito_logout", "logout", user);
      }
      catch (err) {
        console.error("Error in cognito-logout! ", err);
        logger.error(email, "cognito_logout", err, "logout", user);
      }

    }
    logout_()
  }, [])
  return null;
}

export async function getCurrentUser() {
  try {
    const user = await Auth.currentAuthenticatedUser()
    return user;
  }
  catch (e) {
    return null;
  }
}

function accountsLoginLoop() {
  const accounts_login_loop = localStorage.getItem('accounts-login-loop');

  if (accounts_login_loop > 1) {
    localStorage.removeItem('saml-redirect');
    localStorage.removeItem('accounts-login-loop');
    return 0;
  }

  return accounts_login_loop;
}

export default React.memo(withRouter(props => {

  const [state, setState] = React.useState({
    isLoading: true,
    user: null,
    isLoggingOut: false,
    goToAuthRoute: null
  })

  const [isVersionLatest, setLatest] = React.useState(isLatest())
  const [later, setLater] = React.useState();

  
  async function handleSAML(accounts_login_loop = 0) {
    localStorage.setItem('saml-redirect', 1, shortExpiration());
    localStorage.setItem('accounts-login-loop', accounts_login_loop + 1, shortExpiration());
    
    // ** comment out this line to test idp
    if (isDev) { window.location.reload(); return; }
    Auth.federatedSignIn({ provider: process.env.REACT_APP_SAML_PROVIDER });
  }

  React.useEffect(() => {
    if (!state.isLoading || state.isLoggingOut) { return }

    async function checkAuth() {
      const accounts_login_loop = accountsLoginLoop(),
        user                    = await getCurrentUser(),
        saml                    = localStorage.getItem('saml-redirect'),
        authRoutes              = [
          "/logout", "/cognito_logout", 
          "/set-password", "/verify"
        ].includes(props.location.pathname);

      if (!user && !saml) {

        handleGoToCache();

        if (!authRoutes) {
          handleSAML(accounts_login_loop);
          return;
        }
      }
      else if (saml) {
        localStorage.removeItem('saml-redirect');
        localStorage.removeItem('accounts-login-loop');
      }

      setState(state => ({
        ...state,
        user,
        isLoading: false
      }))
    }
    checkAuth();
  }, [state.isLoading, state.isLoggingOut])


  const handleLater = () => {
    setLatest(false);
    setLater(true);
  };

  const handleRefresh = () => {
    var latestVersion = getVersion().new;
    setVersion(latestVersion, latestVersion);
    setLatest(false);
    setLater(false);;
    handleCacheAndService();
  };

  const handleCacheAndService = () => {
    if (caches) {
      caches.keys().then(async function (names) {
        await Promise.all(names.map((name) => caches.delete(name)));
      });
    }
    window.location.reload(true);
  };

  React.useEffect(() => {
    if (state.isLoggingOut && state.goToAuthRoute) {
      setState(state => ({ ...state, goToAuthRoute: null, isLoggingOut: false }))
      props.history.push(state.goToAuthRoute)
    }
  }, [state.goToAuthRoute, state.isLoggingOut])

  const goToAuthRoute = goto => {
    setState(state => ({ ...state, goToAuthRoute: goto, isLoggingOut: true }))
  }

  function redirectToAuthRoute(goto = "accounts") {
    logoutInMyhyly(state.user, goto);
    setState(state => ({ ...state, isLoading: false, isLoggingOut: true }));
  }

  if (state.isLoading) { return null }
  if (state.isLoggingOut) { return <Loader /> }

  const attr = state.user && state.user.attributes;

  return (
    <>
      {
        isVersionLatest &&
        <Version
          orgLevel={false}
          arrow={false}
          message={
            <>
              A < b > new version</b>{" "}
              <span style={{ color: "#999" }}>(v{getVersion().new})</span>{" "}
              of Hy.ly Accounts is available.
                      <br />
              <br />
              Refresh to upgrade{" "}
              <span style={{ color: "#999" }}>
                (from v{getVersion().old})
                  </span>
            </>
          }
          laterButton="Later"
          actionButton="Refresh"
          handleLater={handleLater}
          handleAction={handleRefresh}
        />

      }
      <Switch>
        {
          state.user &&
          <Route path="/logout">
            <LogoutComponent user={state.user} />
          </Route>
        }
        {
          state.user &&
          <Route path="/cognito_logout">
            <CognitoLogoutComponent goToAuthRoute={goToAuthRoute} setState={setState} user={state.user} />
          </Route>
        }
        {
          state.user && !attr.identities &&
          <Route path='/change-password'>
            <Profile
              {...props}
              page={"change-password"}
              user={state.user}
              redirectToAuthRoute={redirectToAuthRoute}
            />
          </Route>
        }
        {
          state.user &&
          <Route exact path='/'>
            <Profile
              {...props}
              page={"profile"}
              user={state.user}
              redirectToAuthRoute={redirectToAuthRoute}
            />
          </Route>
        }
        {state.user && <Redirect to="/" />}
        <Route path="/set-password"><ForgotPassword {...props} /></Route>
        {/* <Route path="/login"><Login {...props} /></Route> */}
        <Route path="/login"><NewLogin {...props} /></Route>
        <Route path="/verify"><Verify {...props} /></Route>
        {!state.user && <Redirect to="/login" />}
      </Switch>
    </>
  )
}
))
