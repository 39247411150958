import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import {ReactComponent as HylyLogoBlue} from '../images/hylylogo.svg';
import {ReactComponent as HylyLogoWhite} from '../images/hylyLogoWhite.svg';

const createClasses = (obj, properties, name_addition = '') => (
  Object.keys(obj).reduce((a, c) =>(
    {
      ...a,
      [`${name_addition}${c}`]: {
        ...properties.reduce((a_p, c_p) => ({ ...a_p, [c_p]: obj[c] }),{})
      }
    }),{})
);

const size_obj = { small: 34, medium: 64, large: 128 };

//console.log("getFontSize(sm): ",getFontSize("sm"));
const styles = theme => ({
  defaultClass: {
    padding:0,
    margin:0,
  },
  ...createClasses(size_obj, ['width','height'],'size_')
})

function get_component_by_color(color) {
  switch(color) {
    case "white": return HylyLogoWhite;
    case "blue":
    default: return HylyLogoBlue;
  }
}

const HylyLogo = ({
  classes: {
    defaultClass,
    ...other
  },
  size,
  color,
  className,
  ...props
}) => {

  const Component = get_component_by_color(color);
  return (
    <Component
      className = {`
        ${`${other[`size_${size}`]} `}
        ${className || ''}
        `
      }
      {...props}
    />
  )
}


HylyLogo.propTypes = {
  size: PropTypes.oneOf(['small','medium','large']),
  color: PropTypes.oneOf(['white','blue'])
}

HylyLogo.defaultProps = {
  size: "medium",
  color: "blue"
};

export default withStyles(styles)(HylyLogo);
