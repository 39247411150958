import { Auth } from "aws-amplify";
import RelayQueryResponseCache from "relay-runtime/lib/RelayQueryResponseCache";
const { Environment, Network, RecordSource, Store } = require("relay-runtime");

async function get_myhyly_token(operation) {

  // if (process.env.REACT_APP_env === "dev") {
    return "tn+graphql@hy.ly-temp-token";
  // }
  // 
  // const user = await Auth.currentAuthenticatedUser();
  // return user.signInUserSession.idToken.jwtToken;
}

const cache = new RelayQueryResponseCache({
  size: 250,
  ttl: 60 * 5 * 1000
});

const store = new Store(new RecordSource());

const network = Network.create(async (operation, variables) => {

  const token = await get_myhyly_token(operation);
  let queryId = operation.name;
  let cacheResponse = cache.get(queryId, variables);
  if (cacheResponse) return cacheResponse;

  const response = await fetch(process.env.REACT_APP_MyhylyLoggingEndpoint, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token
    },
    body: JSON.stringify({
      query: operation.text,
      variables
    })
  });

  const data = await response.json();

  if (operation.operationKind === "mutation") {
    cache.clear();
  }
  if (operation.operationKind !== "mutation") {
    cache.set(queryId, variables, data);
  }
  return data;
});

const environment = new Environment({
  network,
  store
});

export default environment;
