import React from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  root: {

    position:'absolute',
    top:0,
    left:0,
    bottom:0,
    flex: 1
    /*...theme.margin,
    [theme.breakpoints.up('md')]: {
      ...theme.marginBig,
    },*/

  },
  loading: {
    color: theme.palette.hylyBlue || theme.palette.primary.main
  }
});

const Loader = ({
  classes: {
    root,
    loading
  },
}) => {

  return (
    <Grid
      container
      className = { root }//classes.margin }
      direction  ='column'
      justify    = 'center'
      alignItems ='center'
    >
    <CircularProgress className = {loading} color="primary" />
  </Grid>
  );
}


export default React.memo(withStyles(styles)(Loader));
