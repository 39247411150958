/**
 * @flow
 * @relayHash f1094b238364691f839957c9c6113d29
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type queriesQueryVariables = {|
  email: string
|};
export type queriesQueryResponse = {|
  +auth: {|
    +name: string,
    +url: ?string,
    +cognitoUserStatus: ?string,
  |}
|};
export type queriesQuery = {|
  variables: queriesQueryVariables,
  response: queriesQueryResponse,
|};
*/


/*
query queriesQuery(
  $email: String!
) {
  auth(email: $email) {
    name
    url
    cognitoUserStatus
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "email",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "auth",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email",
        "type": "String!"
      }
    ],
    "concreteType": "CognitoUserStatus",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "url",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "cognitoUserStatus",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "queriesQuery",
  "id": null,
  "text": "query queriesQuery(\n  $email: String!\n) {\n  auth(email: $email) {\n    name\n    url\n    cognitoUserStatus\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "queriesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "queriesQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dfc872fe3ddd5d899bf29e2d3f14688d';
module.exports = node;
