import React from 'react';

import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';

import Loader from '../../components/loading';

import Button from '../../StorybookComponents/Button';
import Typography from '../../StorybookComponents/Typography';
import Logo from '../../StorybookComponents/hylyLogo';
import HylyField, {create_validation} from '../../StorybookComponents/HylyField';


import {
  forgotPassword,
  changePassword
} from '../../utils/auth_functions';


import { Formik } from 'formik'

const Yup = require('yup');


const check_if_has_values = obj => {
  for (let i in obj) {
    if (obj[i]) { return true; }
  }
  return false;
}

const styles = theme => ({
  container: {
    position:'absolute',
    top:0,
    left:0,
    bottom:0,
    flex:1,
    background:'white'
  },
  skip: {
    position:"absolute",
    top: theme.margins.xs,
    right: theme.margins.xs,
 },
 spacing: {
   marginTop: `${theme.margins.xl}px !important`
 },
 small_spacing: {
   marginTop: theme.margins.md
 },
 form: {
   width: '90%',
   maxWidth:'350px',
   minHeight:'400px',
   textAlign: 'center'
 },
 link: {
   cursor:'pointer'
 }
});

let notification_id = 0;

const fail_notification = id => ({
  id: `fail-email-reset-notification${id}`,
  type: "fail",
  body: `The password reset link has expired since it has been used or is too old. Please Resend Verification Email.`
})

class ChangeKnownPassword extends React.PureComponent {

  async submitPassword(
    { oldPassword, password },
    { setErrors, setSubmitting, setStatus }
  ) {
    try {
      console.log(oldPassword, password, "???");
      await changePassword(oldPassword, password);
      this.props.forceUpdateAfterLogin();

    } catch(err) {
      const {code} = err;
      console.log('change-password error: ', err);
      let errors = {};
      switch(code) {
        case "InvalidParameterException":
          errors.password__password = "Your password should be at least 12 characters long. It should contain at least one alphabetic character and one numeric character.";
          break;
        default: errors = {}
      }
      if (Object.keys(errors).length) {
        setErrors(errors);
      }
      else {
        this.props.set_notifications(fail_notification(notification_id++));
      }
    } finally {
        setSubmitting(false);
    }
  }

  render() {

    const {
      classes,
      globalError,
      email
    } = this.props;

    return ( <Formik
                initialValues = {{
                  password__old_password: '',
                  password__password: '',
                  password__ps_password: ''
                }}
                validateOnBlur = {false}
                validateOnChange = {false}
                validationSchema = {
                  Yup.object().shape({
                    password__old_password: Yup.string().required("Current Password is required"),
                    password__password: Yup.string().min(12, "Your password should be at least 12 characters long. It should contain at least one alphabetic character and one numeric character.").required("Password is required"),
                    password__ps_password: Yup.string().oneOf([Yup.ref("password")], "Passwords do not match").required("Password confirmation is required")
                  })
                }
                onSubmit = { this.submitPassword }
           >
          {
            ({
                values,
                isSubmitting,
                handleSubmit,
                errors,
                status,
                setFieldValue,
                setSubmitting,
                setStatus
            }) => {

            if (isSubmitting) { return <Loader /> }

            return (
              <form onSubmit = {handleSubmit}>
                <Grid
                  container
                  direction = "column"
                  justify = "center"
                  alignItems = "center"
                  className = {classes.container}
                >
                  <Grid
                    container
                    direction = "column"
                    className = {classes.form}
                    alignItems = "center"
                  >
                      <Logo />

                      <Typography gutterBottom type='h3' font_weight = {500} className = {classes.spacing}>
                        NEW PASSWORD
                      </Typography>
                      <HylyField
                          className = {classes.spacing}
                          type = "password"
                          name = "old_password"
                          label = "Current Password"
                      />
                      <HylyField
                          type = "password"
                          name = "password"
                          label = "New Password"
                      />
                      <HylyField
                          type = "password"
                          name = "ps_confirm"
                          label = "Confirm Password"
                      />
                      <Button
                          size="md"
                          type = 'submit'
                          variant="primary"
                          className={classes.spacing}
                          onClick = {handleSubmit}
                          state = {check_if_has_values(errors) ? "inactive" : undefined}
                      >
                          Change your password
                      </Button>
                   </Grid>
               </Grid>
            </form>
          )
        }
       }
    </Formik>
    );
  }
}

const {object, bool} = PropTypes;

ChangeKnownPassword.propTypes = {
  classes: object.isRequired,
  successEmailReset: bool,
  errors: object
};


export default withStyles(styles)(ChangeKnownPassword);
/*

*/
