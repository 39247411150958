import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "./Typography";
// import EmailCard from "./EmailCard";
import Grid from "@material-ui/core/Grid";
import Tooltip from "./Tooltip";
import Icon from "./Icon";

const values_complex = [{ name: "Create eBlast" }, { name: "Preview" }];

const styles = theme => ({
  root: {
    width: "100%",
    padding: "16px 16px 0px 16px",
    [theme.breakpoints.up("md")]: {
      padding: "24px 24px 0px 24px"
    }
  },
  disabled: {
    background: "#FFF !important"
  },
  panelSumRoot: {
    padding: "0 0 0 24px",
    minHeight: "16px",
    [theme.breakpoints.up("md")]: {
      minHeight: "24px"
    }
  },
  backgroundPanelSumRoot: {
    background: "#f8f8f8 !important",
    "&:focus": {
      background: "#f8f8f8 !important"
    },
    padding: "0 0 0 24px",
    minHeight: "16px",
    [theme.breakpoints.up("md")]: {
      minHeight: "24px"
    }
  },
  backgroundDetRoot: {
    // margin: "12px -8px -8px -8px",
    background: "#FFF",
    padding: "16px 0px 0px 16px",
    [theme.breakpoints.up("md")]: {
      padding: "24px 0px 0px 24px"
    }
  },
  panelDetRoot: {
    padding: "16px 0px 0px 16px",
    [theme.breakpoints.up("md")]: {
      padding: "24px 0px 0px 24px"
    }
  },
  content: {
    margin: "0px"
  },
  backgroundExpandIcon: {
    position: "absolute",
    top: "20px",
    right: "unset",
    color: "black",
    left: "0px",
    transform: "translateY(-50%) rotate(-90deg)",
    "&$expanded": {
      transform: "translateY(-50%) rotate(0deg)"
    }
  },
  expandIcon: {
    position: "absolute",
    top: "12px",
    right: "unset",
    color: "black",
    left: "-10px",
    transform: "translateY(-50%) rotate(-90deg)",
    "&$expanded": {
      transform: "translateY(-50%) rotate(0deg)"
    }
  },
  expanded: {
    margin: "0px !important",
    minHeight: "16px !important"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    padding: "0px"
  },
  icon: {
    fontSize: "12px"
  },
  paperBackground: {
    boxShadow: "none",
    background: "#f8f8f8",
    borderBottom: "1px solid #eee"
    // padding: 8
  },
  paper: {
    background: "white",
    boxShadow: "none"
  },
  image: {
    width: "100px",
    height: "100px",
    margin: "8px",
    [theme.breakpoints.up("md")]: {
      margin: "12px"
    }
  },
  backgroundSection: {
    display: "flex",
    width: "100%",
    padding: 8
  },
  section: {
    display: "flex",
    width: "100%"
  },
  icons: {
    position: "absolute",
    top: 12,
    right: 0,
    zIndex: 1000,
    cursor: "pointer"
  },
  greyIcon: {
    marginRight: 32,
    color: "#999"
  },
  blackIcon: {
    marginRight: 32,
    color: "#000"
  },
  count: {
    marginLeft: "5px"
  },
  subtitle: {
    width: "124px !important",
    textAlign: "center !important",
    overflow: "hidden !important",
    textOverflow: "ellipsis !important",
    WebkitLineClamp: "1 !important",
    display: "-webkit-box !important",
    WebkitBoxOrient: "vertical !important",
    lineHeight: "3.5em !important",
    maxHeight: "3.5em !important"
  },
  imageGrid: {
    cursor: "pointer"
  },
  floorPlanDetail: {
    margin: "12px"
  },
  wrapText: {
    width: "100px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden"
  },
  editField: {
    width: "100%",
    height: "24px",
    border: "none",
    paddingLeft: "4px",
    fontSize: "16px",
    fontFamily: "Open Sans, sans-serif",
    color: "#000",
    "&:focus": {
      outline: "1px solid #F5F5F5"
    },
    "&:active": {
      outline: "1px solid #F5F5F5"
    }
  },
  checkIcon: {
    marginLeft: 8
  }
});

class ImageComponent extends React.PureComponent {
  handleClick = () => {
    const { categoryId, img } = this.props;
    this.props.handleGalleryImage("others", categoryId, img);
  };

  render() {
    const { img, classes } = this.props;
    return (
      <div className={classes.imageGrid} onClick={this.handleClick}>
        <div
          className={classes.image}
          style={{
            background: `url(${img.image})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain"
          }}
        />
        <div className={classes.subtitle}>
          <Typography
            type="subtitle"
            color="black"
            className={classes.wrapText}
          >
            {img.subtitle}
          </Typography>
        </div>
      </div>
    );
  }
}

class FloorComponent extends React.PureComponent {
  handleClick = () => {
    const { categoryId, img } = this.props;
    this.props.handleGalleryImage("floorPlan", categoryId, img);
  };

  render() {
    const { img, classes } = this.props;
    return (
      <>
        <div className={classes.imageGrid} onClick={this.handleClick}>
          <div className={classes.floorPlanDetail}>
            <Tooltip tooltip_text={img.name} placement="top">
              <Typography
                type="body2"
                color="black"
                className={classes.wrapText}
              >
                {img.name}
              </Typography>
            </Tooltip>
            <Typography type="subtitle" color="deepGrey">
              {img.areaMin + " sq.ft"}
            </Typography>
          </div>
          <div
            className={classes.image}
            style={{
              background: `url(${img.thumbnailUrl})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "contain"
            }}
          />
          <div className={classes.subtitle}>
            <Typography
              type="subtitle"
              color="deepGrey"
              className={classes.wrapText}
            >
              {img.imageName}
            </Typography>
          </div>
        </div>
      </>
    );
  }
}

class Expansion extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editId: "",
      edit: false,
      displayIcons: false,
      editIcon: false,
      duplicateIcon: false,
      deleteIcon: false
    };
  }

  renderImages = (img, index) => {
    return (
      <ImageComponent
        handleGalleryImage={this.props.handleGalleryImage}
        classes={this.props.classes}
        categoryId={this.props.categoryId}
        img={img}
        key={"image:" + index}
      />
    );
  };

  renderFloorPlans = (img, index) => {
    return (
      <FloorComponent
        handleGalleryImage={this.props.handleGalleryImage}
        classes={this.props.classes}
        categoryId={this.props.categoryId}
        img={img}
        key={"image:" + index}
      />
    );
  };

  handleEdit = (e, editId) => {
    if (editId) {
      this.setState({
        editId,
        edit: true,
        displayIcons: false
      });
    } else {
      this.setState({
        editId: "",
        edit: false,
        displayIcons: true,
        editIcon: false,
        duplicateIcon: false,
        deleteIcon: false
      });
    }
  };

  handleSliceName = e => {
    this.props.handleFields(e, this.props.field, "updateSlices");
  };

  handleEnter = e => {
    var code = e.keyCode || e.which;
    if (code === 13) {
      this.setState({
        editId: "",
        edit: false,
        displayIcons: false,
        editIcon: false,
        duplicateIcon: false,
        deleteIcon: false
      });
    }
  };

  handleDuplicate = () => {
    this.props.handleIconAction("Duplicate", this.props.id, this.props.section);
  };

  handleDelete = () => {
    this.props.handleIconAction("Delete", this.props.id, this.props.section);
  };

  onEditEnter = () => {
    this.setState({
      editIcon: true
    });
  };

  onEditLeave = () => {
    this.setState({
      editIcon: false
    });
  };

  onDuplicateEnter = () => {
    this.setState({
      duplicateIcon: true
    });
  };

  onDuplicateLeave = () => {
    this.setState({
      duplicateIcon: false
    });
  };

  onDeleteEnter = () => {
    this.setState({
      deleteIcon: true
    });
  };

  onDeleteLeave = () => {
    this.setState({
      deleteIcon: false
    });
  };

  onMouseEnter = () => {
    if (!this.state.edit) {
      this.setState({
        displayIcons: true
      });
    }
  };

  onMouseLeave = () => {
    if (!this.state.edit) {
      this.setState({
        displayIcons: false
      });
    }
  };

  render() {
    const {
      classes,
      type,
      background,
      id,
      section,
      content,
      images,
      emails,
      children,
      icons,
      defaultPadding,
      elementId,
      expanded,
      handleChange,
      checkIcon,
      disabled,
      controlled,
      open
    } = this.props;

    const {
      displayIcons,
      editId,
      edit,
      editIcon,
      duplicateIcon,
      deleteIcon
    } = this.state;

    return (
      <div
        style={{ position: "relative" }}
        className={defaultPadding ? `${classes.root}` : null}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <ExpansionPanel
          classes={{
            root: background ? classes.paperBackground : classes.paper,
            disabled: classes.disabled
          }}
          expanded={controlled ? open : expanded ? expanded === id : null}
          onChange={
            handleChange ? () => handleChange(expanded, id, elementId) : null
          }
          disabled={disabled}
        >
          <ExpansionPanelSummary
            classes={{
              root: background
                ? classes.backgroundPanelSumRoot
                : classes.panelSumRoot,
              content: classes.content,
              expandIcon: background
                ? classes.backgroundExpandIcon
                : classes.expandIcon,
              expanded: classes.expanded
            }}
            expandIcon={
              <i className={"icon icon-triangle-down " + classes.icon} />
            }
          >
            <div
              className={
                background ? classes.backgroundSection : classes.section
              }
            >
              {edit && id === editId ? (
                <input
                  type="text"
                  name="editName"
                  value={section}
                  className={classes.editField}
                  autoFocus={true}
                  onChange={this.handleSliceName}
                  onBlur={this.handleEdit}
                  onKeyPress={this.handleEnter}
                />
              ) : (
                <>
                  <Typography type="h5" color="darkBlack">
                    {section}
                  </Typography>

                  {checkIcon && (
                    <Icon
                      type="icon-check-single"
                      size="md"
                      className={classes.checkIcon}
                    />
                  )}
                </>
              )}
              {type === "image" || type === "floorPlan" ? (
                <div className={classes.count}>
                  <Typography type="h5" color="deepGrey">
                    ({images.length})
                  </Typography>
                </div>
              ) : null}
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            classes={{
              root: background
                ? classes.backgroundDetRoot
                : classes.panelDetRoot
            }}
          >
            {type === "basic" ? (
              <Typography type="h5" color="darkBlack">
                {content}
              </Typography>
            ) : type === "children" ? (
              <>{children}</>
            ) : type === "image" ? (
              <Grid container>{images.map(this.renderImages)}</Grid>
            ) : type === "floorPlan" ? (
              <Grid container>{images.map(this.renderFloorPlans)}</Grid>
            ) : type === "email" ? (
              <Grid container>
                {emails.map((email, i) => (
                  <div>EmailCard</div>
                  /*  <EmailCard
                    key={i}
                    card={email.card}
                    title={email.title}
                    values={values_complex}
                    dropdownIcon="icon-dots-to-close-2"
                  /> */
                ))}
              </Grid>
            ) : null}
          </ExpansionPanelDetails>
        </ExpansionPanel>

        {icons && displayIcons && (
          <div className={classes.icons}>
            <Icon
              className={editIcon ? classes.blackIcon : classes.greyIcon}
              type="icon-pencil"
              size="sm"
              onClick={e => this.handleEdit(e, id)}
              onMouseEnter={this.onEditEnter}
              onMouseLeave={this.onEditLeave}
            />
            <Icon
              className={duplicateIcon ? classes.blackIcon : classes.greyIcon}
              type="icon-layers-2"
              size="sm"
              onClick={this.handleDuplicate}
              onMouseEnter={this.onDuplicateEnter}
              onMouseLeave={this.onDuplicateLeave}
            />
            {this.props.deleteIcon && (
              <Icon
                className={deleteIcon ? classes.blackIcon : classes.greyIcon}
                type="icon-bin"
                size="sm"
                onClick={this.handleDelete}
                onMouseEnter={this.onDeleteEnter}
                onMouseLeave={this.onDeleteLeave}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

Expansion.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Expansion);
