import React from "react";
import PropTypes from "prop-types";

const hylyBlue = "#26BBED";
const inactive = "#CCC";

function translate_state_to_color(state) {
  switch (state) {
    case "inactive":
      return inactive;
    case "active":
    default:
      return hylyBlue;
  }
}

const SAVE = React.memo(({ state }) => {
  const fill = translate_state_to_color(state);
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" fill={fill} />
      <g clipPath="url(#clip0)">
        <path
          d="M4.5 11.5L7.5 14.5L15.5 5.5"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
});

SAVE.propTypes = {
  state: PropTypes.oneOf(["active", "inactive"])
};

SAVE.defaultProps = {
  state: "active"
};

export default SAVE;
