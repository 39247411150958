//72
import React from 'react';

import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';

import Button from '../../StorybookComponents/Button';
import Typography from '../../StorybookComponents/Typography';
import Logo from '../../StorybookComponents/hylyLogo';
import HylyField, {create_validation} from '../../StorybookComponents/HylyField';

import Loader from '../../components/loading';
import logger from '../../components/Logger';

import { forgotPassword } from '../../utils/auth_functions';

import { Formik } from 'formik';

import confirmAccount from '../../mutations/confirmAccount';
import {userStatusGet} from '../../queries';

const Yup = require('yup');


const styles = theme => ({
  container: {
    position:'absolute',
    top:0,
    left:0,
    bottom:0,
    flex:1,
    background:'white'
  },
  skip: {
    position:"absolute",
    top: theme.margins.xs,
    right: theme.margins.xs,
    cursor:"pointer",
    zIndex: 9999
 },
 spacing: {
   marginTop: `${theme.margins.xl}px !important`
 },
 form: {
   width: '90%',
   maxWidth:'350px',
   minHeight:'400px',
   textAlign: 'center'
  }
});

let notification_id = 0;
let reset_password_alert_id = 0;

const success_notification = id => ({
  id: `success-email-reset-notification${id}`,
  type: "warning",
  body: "Please check your email to reset password: If your email is valid, we have sent you instructions to reset your password."
})

const alert_notification = id => ({
  id: `alert-email-reset-notification${id}`,
  type: "fail",
  body: "Something went wrong while setting your password. Please try again after some time."
})

const limit_exceeded_notification = id => ({
  id: `limit-exceeded-reset-notification${id}`,
  type: "warning",
  body: "Attempt limit exceeded, please try after some time."
})

const account_not_confirmed = id => ({
  id: `account-not-confirmed-${id}`,
  type: "warning",
  body: "Your account is not confirmed yet. We have sent you an email to set your password."
})

const ForgotPasswordPage = ({
  history,
  classes,
  set_notifications,
  ...props
}) => {

  async function sendResetEmail (
    {
      email__email__required: email
    }, {
      setFieldValue,
      setSubmitting,
      setStatus,
      setValues
    }) {
    try {
        await forgotPassword(email.toLowerCase());
        logger.info(email,"set-password","sendResetEmail");

        set_notifications(success_notification(notification_id++));
    } catch(err) {
      logger.error(email,"set-password",err,"sendResetEmail");

      if (err.message.indexOf('Bad user') > -1) {
        set_notifications(success_notification(notification_id++));
      } else {
        switch(err.code) {
          case "TooManyRequestsException":
          case "LimitExceededException":
            set_notifications(limit_exceeded_notification(notification_id++));
            break;
          case "UserNotConfirmedException":
          case "InvalidParameterException":
            history.push('/verify');
            break;
          case "NotAuthorizedException":
            if (process.env.REACT_APP_env === 'dev') {
             await confirmAccount(email);
              set_notifications(account_not_confirmed(notification_id++));
            }
            else {
              const userstatus = await userStatusGet(email);
              const cognitoUserStatus = userstatus && userstatus.cognitoUserStatus;
              if (cognitoUserStatus === 'FORCE_CHANGE_PASSWORD') {
                await confirmAccount(email)
                set_notifications(account_not_confirmed(notification_id++));
              }
            }
            break;
          case "UserNotFoundException":
          default:
            console.error("Error in sendResetEmail(): ",err);
            set_notifications(success_notification(notification_id++));
        }

      }
    } finally {
      setSubmitting(false);
    }
  }

  return (
      <Formik
        initialValues = {{
          email__email__required: '',
        }}
        validateOnBlur = {true}
        validateOnChange = {true}
        validationSchema = {
          Yup.object().shape({
            email__email__required: Yup.string()
              .email("Please enter a correct email.")
              .required("Email is required")
          })
        }
        onSubmit = { sendResetEmail }
      >
      { ({
        values,
        isSubmitting,
        handleSubmit,
        errors,
        status,
        isValid
      }) => {

        if (isSubmitting) { return (<Loader />); }

        return (
          <>
            <Typography
                color = "hylyBlue"
                className = { classes.skip }
                onClick = { () => history.push('/login')}
            >
                SIGN IN
            </Typography>
            <form onSubmit = {handleSubmit} >
            <Grid
              container
              direction = "column"
              justify = "center"
              alignItems = "center"
              className = {classes.container}
            >
              <Grid
                container
                direction = "column"
                className = {classes.form}
                alignItems = "center"
              >
                  <Logo />

                  <Typography gutterBottom type='h3' font_weight = {500} className = {classes.spacing}>
                      SET PASSWORD
                  </Typography>

                  <HylyField
                      className = {classes.spacing}
                      type = "email"
                      name = "email"
                      label = "Email Address"
                      required
                      handleChange = { async(e, {field: { onChange }}) => {
                        onChange(e);
                      }}
                  />

                  <Button
                    size = "md"
                    className = {classes.spacing}
                    type = 'submit'
                    variant="primary"
                    onClick = {handleSubmit}
                    state = {
                      (
                        !isValid ||
                        (status && Boolean(status.global))
                      )
                      ? "inactive"
                      : undefined
                    }
                    >
                    SEND VERIFICATION EMAIL
                  </Button>
              </Grid>
            </Grid>
          </form>
         </>
        );
      }}
    </Formik>
   );
}


ForgotPasswordPage.propTypes = {
  router: PropTypes.object,
  classes: PropTypes.object
};


export default withStyles(styles)(React.memo(ForgotPasswordPage));
