import React, { useState, useEffect,useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';


const createClasses = (obj, property, name_addition = '') => (
  Object.keys(obj).reduce((a, c) =>({...a, [`${name_addition}_${c}`]: { [property]: obj[c]} }),{})
);


const create_classes_with_func = (obj, func, theme, name_addition = 'arrow_popper') => (
  Object.keys(obj).reduce((a, c) => ({
    ...a,
    [`${name_addition}_${c}`]: { ...func(theme, obj[c]) }
  }),{})
);

function arrowGenerator(color) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${color} transparent`,
      }
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${color} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
        marginTop:'0.9em',
        marginLeft: '-0.9em',
        height: '3em',
        width: '1em',
        '&::before': {
          borderWidth: '1em 1em 1em 0',
          borderColor: `transparent ${color} transparent transparent`,
        },

    },
    '&[x-placement*="left"] $arrow': {
          right: 0,
          marginRight: '-0.9em',
          marginTop:'0.9em',
          height: '3em',
          width: '1em',
          '&::before': {
            borderWidth: '1em 0 1em 1em',
            borderColor: `transparent transparent transparent ${color}`,
          },
    }
  };
}

const styles = theme => ({
  popper: arrowGenerator(theme.palette.grey[700]),
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  tooltipPlacementLeft: {
    margin: '0 8px',
  },
  tooltipPlacementRight: {
    margin: '0 8px',
  },
  tooltipPlacementTop: {
    margin: '8px 0',
  },
  tooltipPlacementBottom: {
    margin: '8px 0',
  },
  tooltip: {
    fontSize: 12,
    background: theme.palette.grey[700],
    padding: '8px 10px',

  }
});

const is_arr_included = (arr, values) => {
  for (let v of arr) {
    if (arr.includes(v)) { continue; }
    else { return false }
  }
  return true;
}

const TooltipTitle = ({ tooltip_text, handleArrowRef, arrow }) => (
  <React.Fragment>
    { tooltip_text }
    <span className={ arrow } ref={handleArrowRef} />
  </React.Fragment>
);

const TooltipElement = withStyles(styles)((
  {
    classes: {
       tooltip,
       popper,
       arrow,
       tooltipPlacementTop,
       tooltipPlacementBottom,
       tooltipPlacementLeft,
       tooltipPlacementRight,

       ...other
     },
     placement,
     element,
     children,
     show_tooltip,
     ...other_props
  }
) => {

  const [s_placement, set] = useState(null);
  let timeout = false, timer;

  useEffect(() => {
    if (timeout) {return; }
    timeout = true;
    setTimeout(() => { if (timeout) { set(placement); timeout = false; } },100);
    return () => { timeout = false; clearTimeout(timer); }
  },[placement]);

  return (
    <Tooltip
        open = { Boolean(show_tooltip) || undefined }
        placement = { placement }
        disableFocusListener
        title={ <TooltipTitle {...other_props} arrow = {arrow} /> }
        classes={{
          tooltip,
          popper,
          tooltipPlacementTop,
          tooltipPlacementBottom,
          tooltipPlacementLeft,
          tooltipPlacementRight,
        }}
        PopperProps={{
          popperOptions: {
            modifiers: {
              arrow: {
                enabled: show_tooltip || Boolean(element),
                element
              },
            },
          },
        }}
      >
      { children }
    </Tooltip>
  )
})

function ArrowTooltip ({
  placement,
  width,
  ...other
}) {
  const [arrowRef = null, set] = useState();

  const get_placement = useMemo(() => {
    if (!arrowRef) { return placement; }
    if (["top","bottom"].includes(placement) || (["md","xl","lg"].includes(width))) { return placement; }
    const ref_width = arrowRef.scrollWidth;
    const approximate_message_width = other.tooltip_text.length < 45 ? Math.round(other.tooltip_text.length * 10) : 320;
    if (((window.innerWidth / 2) - (ref_width / 2)) < approximate_message_width) {
      return "bottom";
    }
    return placement;
  },[width, placement, other.tooltip_text, arrowRef]);

  return (
    <TooltipElement
      handleArrowRef = {arrowRef => set(arrowRef)}
      placement = {get_placement}
      element = {arrowRef}
      {...other}
    />
  );
}

ArrowTooltip.propTypes = {
  classes: PropTypes.shape({
    arrow: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
    tooltipPlacementLeft: PropTypes.string.isRequired,
    tooltipPlacementRight: PropTypes.string.isRequired,
    tooltipPlacementTop: PropTypes.string.isRequired,
    tooltipPlacementBottom: PropTypes.string.isRequired
  }).isRequired,
  tooltip_text: PropTypes.string.isRequired,
  placement: PropTypes.oneOf(["top","left","bottom","right"]).isRequired,
  show_tooltip: PropTypes.bool
}

ArrowTooltip.defaultProps = {
  placement: "left",
}

export default withWidth()(withStyles(styles)(ArrowTooltip));
