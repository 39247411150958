import React from 'react';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import CssBaseline from '@material-ui/core/CssBaseline';
// A theme with custom primary and secondary color.
// It's optional.

const marginBigUnit = 24;
const marginSmallUnit = 16;

const white = {
  main: 'rgba(255,255,255,1)',
  mid: 'rgba(255,255,255,0.9)',
  dark: '#ddd'
}

const blue = {
  light: 'rgba(245,250,255,0.9)',
  main: '#26bbed',
  pastel: '#7addff',
  deep: '#00A2D8',
  deepDark:'#0090D0',
  medium:'#1FB7EA',
  mediumLight: '#37CCFF'
};

const orange = {
  main: '#f5a623',
  pastel: '#ffc669',
  dark: '#f5a623'
};

const purple = {
  main: '#894ebd',
  pastel: '#ab84d0'
};

const grey = {
  main: '#9a9a9a',
  dark: '#333333',
  light: '#9e9e9e'
};

const pink = {
  main: '#e75f75',
  pastel: '#ff9cac'
};

const teal = {
  main: '#2ad08e',
  pastel: '#35f0aa'
};

const red = {
  main: '#ff0000',
};

const black = {
  main: '#212121',
  light: 'rgba(0,0,0,.12)'
};

const openSans = 'Open Sans , arial, sans-serif';
const raleway = 'Raleway , arial, sans-serif';

const fontWeight = {
  light: 400,
  main: 500,
  bold: 600,
  intense: 900
}

const cellView = window.innerWidth < 450;

const lineHeight = {
  main: 1.4,
  big: 1.6
}

const letterSpacing = {
  main: '.44px',
  big: '.75px'
}

export const introductionTheme = createMuiTheme({
    overrides: {
      MuiButton: {
          contained: {
            boxShadow:'none',

          }
      }
    },
    palette: {
      white,
      black,
      blue,
      orange,
      purple,
      grey,
      pink,
      teal,
      red,
      background:{
        paper: white.main,
        default: white.main
      },
      primary: {
        main: blue.main,
        contrastText: white.main,
      },
      secondary: {
        main: white.main,
        contrastText: blue.main,
        dark: blue.light
      },
      action: {
        active: blue.main,
        selected: 'transparent',//white.medium,
      },
      text: {
        primary: grey.dark,
        secondary: grey.main,
      },
      hint: {
        main: blue.main
      }
  },
  marginBigUnit,
  marginSmallUnit,
  fontFamily: {
    openSans,
    raleway
  },
  letterSpacing,
  fontWeight,
  shape: { borderRadius:0 },
  lineHeight,
  container: {
	    justifyContent:'center',
	    alignItems: 'center',
      height:'100vh'
  },
  notification :{
    subheader: {
        color:'white',
        fontFamily: openSans,
        fontSize:14,
        fontWeight: fontWeight.bold,
        margin: '0 4px',
        lineHeight:1.2
    },
    close: {
        pointerEvent:'all',
        padding:0,
        margin:'1px 2px',
        cursor:'pointer',
        color:'white',
        fontWeight:600
    },
    root: {
        minWidth: 350,
        maxWidth:450,
        textAlign:'center',
        boxShadow: "0px 4px 4px rgba(204, 204, 204, 0.25)",
    },
    success: {
      backgroundColor: "#66D184",
    },
    alert: {
      backgroundColor: "#FF5151",
    }

  },
  formControl: {
      width:'100%',
      height:'100%',
      display:'flex',
      alignItems:'center',
      flexDirection:'column',

  },
  form: {
      alignItems:'center',
      justifyContent:'flex-start',
      fontFamily: openSans,
      height:'100%',
      width:  '100%',
      minHeight: 500,
    //  maxWidth: 500,
  },
  form_big_screen: {
    maxWidth: 500,
    width:  '50%',
  },
  logo: {
      width:'15%',
      minWidth: 70,
    	alignSelf:'center',
    //  marginTop:"10%"
  },
  error: {
      fontFamily: openSans,
    	color: red.main,
    	lineHeight: lineHeight.main,
    	textAlign:'center'
  },
  typography: {
  	  fontFamily: openSans,
      h5: {
        fontFamily: raleway,
        textTransform: 'uppercase',
        fontWeight: fontWeight.intense,
        color: black.main,
        textAlign:'center'
      },
      subtitle1: {
        fontFamily: openSans,
        lineHeight: lineHeight.main,
      	textAlign:'center'
      },
      useNextVariants: true,
      h1: {
        color: white,
        fontFamily: raleway,
        lineHeight: lineHeight.big,
        fontWeight: fontWeight.light,
      }
    },
    icon: {
        color: blue.main
    },

    button: {
      padding: 10,
      boxShadow:'none',//'0px 4px rgba(176, 190, 197, 0.24)',
      color: white,
      lineHeight: lineHeight.big,
      letterSpacing: letterSpacing.big,
      fontWeight: fontWeight.bold,
      fontSize:16,
      width: '100%',
      '&:hover': {
        backgroundColor: blue.deepDark,
      },
      cursor:'pointer'
    },
    link: {
      textDecoration:'none',
      cursor:'pointer',
      border:'none',
      fontFamily: openSans,
      backgroundColor:'transparent',
      fontSize: 16,
    }
});

function withRoot(Component) {
  function WithRoot(props) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <MuiThemeProvider theme={introductionTheme}>
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
