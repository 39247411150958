/**
 * @flow
 * @relayHash b8035fd334ddfcba745ef879142faa4e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type writeLogInput = {
  clientMutationId?: ?string,
  signal?: ?InputSignal,
  app?: ?Product,
  actionExtensions?: ?ActionExtension,
  statisticsExtensions?: ?StatisticsExtensions,
  severity?: ?string,
};
export type InputSignal = {
  type: string
};
export type Product = {
  appName: string,
  appSection?: ?string,
  appSubsection?: ?string,
  appUrl?: ?string,
  appEnvironment?: ?string,
};
export type ActionExtension = {
  action?: ?string,
  activity?: ?string,
  email?: ?any,
  parameter?: ?Parameter,
};
export type Parameter = {
  other?: ?string,
  email?: ?string,
};
export type StatisticsExtensions = {
  alertStatus?: ?Status
};
export type Status = {
  statusCode?: ?number,
  statusMessage?: ?string,
  backtrace?: ?string,
  devNote?: ?string,
};
export type LoggerMutationVariables = {|
  input: writeLogInput
|};
export type LoggerMutationResponse = {|
  +writeLog: ?{|
    +signal: ?{|
      +errorDetails: ?string,
      +status: string,
    |}
  |}
|};
export type LoggerMutation = {|
  variables: LoggerMutationVariables,
  response: LoggerMutationResponse,
|};
*/


/*
mutation LoggerMutation(
  $input: writeLogInput!
) {
  writeLog(input: $input) {
    signal {
      errorDetails
      status
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "writeLogInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "writeLogInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "errorDetails",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "LoggerMutation",
  "id": null,
  "text": "mutation LoggerMutation(\n  $input: writeLogInput!\n) {\n  writeLog(input: $input) {\n    signal {\n      errorDetails\n      status\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "LoggerMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "writeLog",
        "storageKey": null,
        "args": v1,
        "concreteType": "writeLogPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "signal",
            "storageKey": null,
            "args": null,
            "concreteType": "Signal",
            "plural": false,
            "selections": [
              v2,
              v3
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "LoggerMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "writeLog",
        "storageKey": null,
        "args": v1,
        "concreteType": "writeLogPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "signal",
            "storageKey": null,
            "args": null,
            "concreteType": "Signal",
            "plural": false,
            "selections": [
              v2,
              v3,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '658e1245772e8b1edb4a86661b8f96fb';
module.exports = node;
