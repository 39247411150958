import React from 'react';
import PropTypes from 'prop-types';
import graphql from 'babel-plugin-relay/macro';
import {createFragmentContainer} from 'react-relay';

import withWidth from '@material-ui/core/withWidth';
import withStyles from '@material-ui/core/styles/withStyles';

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Icon from '../Icon';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';

import NavListWithCollapse from './NavListWithCollapse';

import Typography from '../Typography';
import SearchField from '../SearchField';

import Header from './NavHeader';

//import MyhylyImg from '../../images/2019-Artie-Leigh-black.png';
//import MyhylyImg from '../../images/2019-Artie-Leigh-black';
import {Link} from 'react-router-dom';
import MaterialLink from '@material-ui/core/Link';
//import SvgIcon from '@material-ui/core/SvgIcon';
import {ReactComponent as MyhylyImg} from '../../images/2019-Artie-Leigh-black.svg';


const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

const drawer_width = 299;
const closedDrawerWidth = 80;

/*///////////////////////////////////////////////////////////////////////////////////
                                    BOTTOM SECTION
///////////////////////////////////////////////////////////////////////////////////*/

const bottom_section_styles = theme => ({
  root: {
    position:'absolute',
    bottom:0,
    left:0,
    width:'100%',
  },
  zero_margin_right: {
    marginRight:0,
  },
  list_item: {
    padding: theme.margins.sm,
  },
  overflow: {
    textOverflow: 'ellipsis',
    overflow:'hidden',
    whiteSpace: 'nowrap'
  },
  rotate_icon: {
    webkitTransform: "rotate(180deg)",
    transform: "rotate(180deg)",
  },
  default_icon: {
    webkitTransform: "rotate(0deg)",
    transform: "rotate(0deg)",
  }
})

const BottomSection = React.memo(withStyles(bottom_section_styles)(({
  classes,
  property,
  onClick,
  token,
}) => {
  const [pressed, set_pressed] = React.useState(false);

  function handle_click(e) {
      set_pressed(!pressed);
      onClick(e);
  }

  return (
      <div className = {classes.root}>
        <Divider />
        <ListItem
            classes = {{ root: classes.list_item}}
            component = {MaterialLink}
            href = {"https://"+ process.env.REACT_APP_MyhylyHost +"/from_cognito" + "?token=" + token}
        >
          <ListItemIcon className = {classes.zero_margin_right}>
            <MyhylyImg  width="25" height="25"/>
          </ListItemIcon>
          <ListItemText
              primary = "my.hy.ly"
              classes = {{
                primary: classes.overflow
              }}
          />
       </ListItem>
      </div>
    )
  }
));


/*///////////////////////////////////////////////////////////////////////////////////
                                MIDDLE SECTION
///////////////////////////////////////////////////////////////////////////////////*/

const middle_section_styles = theme => ({
  app_changer_class: {
    marginTop: 'auto',
    cursor: 'pointer'
  },
  list_item: {
    padding:theme.margins.sm
  },
  zero_margin_right: {
    marginRight:0
  },
  overflow: {
    textOverflow: 'ellipsis',
    overflow:'hidden',
    whiteSpace: 'nowrap'
  }
});

const MiddleSection = React.memo(withStyles(middle_section_styles)(({
  classes,
  app_changer,
  menu_open,
  ...props
}) => {
  return (
    <>
    <NavListWithCollapse {...props} menu_open={menu_open} />
    {
      Boolean(app_changer && app_changer.length) ?
      (
        <List className = {classes.app_changer_class}>
        {
          app_changer.map(({ icon_type, name, link },index) => (
            <React.Fragment key = {`app-changer-${name}-${index}`}>
            <Divider />
            <ListItem
              classes = {{ root: classes.list_item}}
              component = { props.link_component }
              href = {link}
            >
              <ListItemIcon className = {classes.zero_margin_right}>
                <Icon size = "sm" type = {icon_type} />
              </ListItemIcon>
              <ListItemText
                  primary = {name}
                  classes = {{
                    primary: classes.overflow
                  }}
              />
              </ListItem>
            </React.Fragment>
          ))
        }
        </List>
      ) : null
    }
  </>
  )
} 
))

/*///////////////////////////////////////////////////////////////////////////////////
                                  MIDDLE HEADER SECTION
///////////////////////////////////////////////////////////////////////////////////*/

const middle_header_section_styles = theme => ({
  root: {
    flex: 1,
    flexDirection: 'column'
  },
  list_item: {
    padding: theme.margins.sm,
    '&:hover': {
      color: "#26BBED"
    },
  },
  selected: {
    fontWeight: 600
  },
  list_item_text: {
    color: theme.palette.darkBlack,
    fontWeight: theme.fontWeight.main,
    cursor:'pointer',
    textOverflow: 'ellipsis',
    overflow:'hidden',
    whiteSpace: 'nowrap',
    '&:hover': {
      color: "#26BBED"
    },
  }
})


const MiddleHeaderSection = React.memo(withStyles(middle_header_section_styles)(({
  classes,
  logout_function,
  header_props,
  pathname,
  ...props
}) => {

  const check_if_selected = (item, path) => new RegExp(path, "g").test(item);
  const create_path = (path = '/') =>  Boolean(path) && /^\//.test(path) ? path : Boolean(path) ? '/' + path : '/';


  return (
    <List className = {classes.root}>
       {
         Boolean(header_props && header_props.length)
         ? header_props.map(({
           to, // ** where does this item link to **
           path, // ** when to show this item as selected (bold) **
           name,
           disabled,
         }, index) => {

           const data_selected = check_if_selected(path, pathname);

           return (
             <ListItem
               key= {name}
               selected = { data_selected }
               component = { props.link_component }
               to = { create_path(to) }
               disabled = {disabled}
               onClick = { props.menu_open ? null : props.onClose }
               classes = {{ root: classes.list_item }}
              >
               <ListItemText
                 primary= {name}
                 classes = {{
                   primary: `${classes.list_item_text} ${data_selected ? classes.selected : ''}`,
                 }}
               />
             </ListItem>
           );
         }
       ): null
       }
       <ListItem
         key= 'signout'
         id = "signout"
         onClick = { logout_function }
         classes = {{ root: classes.list_item }}
        >
         <ListItemText
           primary= {'Sign Out'}
           classes = {{
             primary: classes.list_item_text,
           }}
         />
       </ListItem>
   </List>
  )
}))



/*///////////////////////////////////////////////////////////////////////////////////
                                  NAV CONTENT
///////////////////////////////////////////////////////////////////////////////////*/

const styles = theme => ({
  root: {
      width: drawer_width,
      background: "#FFFFFF",
      flexWrap:'nowrap',
      flex: 1,
  },
  container: {
    flex: 1,
    marginBottom:'56px',
    overflowY:'auto'
  },
  rotate_icon: {
    webkitTransform: "rotate(180deg)",
    transform: "rotate(180deg)",
  },
  default_icon: {
    webkitTransform: "rotate(0deg)",
    transform: "rotate(0deg)",
  }
})

const NavContent = ({
  classes: {
    root,
    container,
  },
  user: {
    name,
    property,
  //  token
  },
  orgs,
  app_changer,
  header_props,
  logout_function,
  pathname,
  menu_open,
  ...props
}) => {

    const [page, set_page] = React.useState("default");

    function header_changer(e) { set_page(page === "head" ? "default" : "head"); }

    function bottom_section_changer(e) { set_page(page === "default" ? "properties" : "default") }

    return (
      <Grid
          container
          direction = "column"
          className = {root}
          style={{width: menu_open? drawer_width: closedDrawerWidth}}
        >
          <Grid
            direction = "column"
            container
            wrap="nowrap"
            className = {container}
          >
          {
            /default|head/.test(page)
            ? <Header
                menu_open={menu_open}
                name = { name }
                onClick = {header_changer}
                pressed = {page === "head"}
              />
            : null
          }
          { page === "head" ? <MiddleHeaderSection logout_function = {logout_function} pathname = {pathname} header_props = {header_props} /> : null }
          { page === "default" ? <MiddleSection app_changer = {app_changer} menu_open={menu_open} pathname = {pathname} {...props} /> : null }
        </Grid>
      </Grid>
   );
}
//<BottomSection token = {token}/>

const isValidComponent = (props, propName, name) => {
    if (props[propName] && !React.isValidElement(props[propName]) && !(typeof props[propName] === "string")) {
      return new Error(
        //`Invalid prop 'component' supplied to '${name}': the prop is not a valid React component`
        `Expect prop '${name}' to be either a string or a valid react component. Got ${typeof props[propName]}.`
      );
    }
}

NavContent.propTypes = {

  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired
  }),

  user: PropTypes.shape({
     name: PropTypes.string,
     property:PropTypes.string,
  //   token:PropTypes.string,
     org: PropTypes.shape({
       id: PropTypes.string,
     })
  }),

  orgs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),

  bottom_section: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),

  onClose: PropTypes.func.isRequired,

  logout_function: PropTypes.func,

  app_changer: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon_type: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
  })),

  header_props: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    disabled: PropTypes.bool
  })),

  pathname: PropTypes.string.isRequired,

  onSelect: PropTypes.func

}

NavContent.defaultProps = {

  link_component: 'div',

  is_online: true,

  menu_open: false,

  pathname: '/',

}

export default React.memo(withStyles(styles)(NavContent));
