import {Auth} from 'aws-amplify';

import fetchWithRetries from '../../../utils/fetch_with_retries';
import dashboardDammyData from '../../../dammy_data/dashboard_query';

import {
  Environment,
  Network,
  RecordSource,
  Store,
} from 'relay-runtime';
import RelayQueryResponseCache from 'relay-runtime/lib/RelayQueryResponseCache';

const oneMinute = 60 * 1000;
const cache = new RelayQueryResponseCache({ size: 250, ttl: oneMinute });


const get_dummy_respone = async(operation_name) => {
  const user = await Auth.currentAuthenticatedUser();
  switch(operation_name) {
    case "DashboardQuery":
    default:
      return dashboardDammyData;
  }
}

const fetch_data = async(operation, variables) => {
  try {
    console.log("fetching data from hyly server...");
    const user = await Auth.currentAuthenticatedUser();
    const response = await fetch(
      `https://${process.env.REACT_APP_MyhylyHost}/graphql`,{
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${user.signInUserSession.idToken.jwtToken}`,
        },
        body: JSON.stringify({
          query: operation.text,
          variables,
        }),
    });
    const data = await response.json();
    return data;
  }
  catch(err) {
    console.error('Error in fetch_data(): ',err);
  }

}

//`https://${process.env.REACT_APP_MyhylyHost}/graphql`, {
async function fetchQuery(
  operation,
  variables,
  cacheConfig,
) {
  try {

    return process.env.NODE_ENV === "development"
                  ? await get_dummy_respone(operation.name)
                  : await fetch_data(operation, variables);
  }
  catch(err) {
    console.error('Error in fetchQuery(): ',err)
  }
}

const environment = new Environment({
  network: Network.create(fetchQuery),
  store: new Store(new RecordSource()),
});

export default environment;
/*
{
  name: 'hySurvey',
  url: `http${process.env.NODE_ENV === "production" ?'s' : ''}://${process.env.REACT_APP_HysurveyHost}`,
  authorized: true
},
*/
