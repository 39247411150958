import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Loader from "../../components/loading";
import localStorage from "../../components/localStorage";

import WelcomeBoard from "./components/WelcomeBoard";
import LoginForm from "./LoginForm";
import styles from "./styles";

const NewAuthForm = ({ classes, appName = "hycxp", ...rest }) => {
  const [userEmail, setUserEmail] = React.useState("");
  const [checkFromCookies, setCheckFromCookies] = React.useState(false);

  React.useEffect(() => {
    setUserEmail(localStorage.getItem("userEmail"));
    setCheckFromCookies(true);
  }, []);

  // const rentOrgCheck=true;//Check to make dynamic login screen
  return (
    <div className={classes.root}>
      <WelcomeBoard
        appKey={rest.redirectAppAfterLoginAlias.toLowerCase() || "accounts"}
      />
      <div className={classes.formBox}>
        {/* TODO: render forms by cases */}
        {userEmail || checkFromCookies ? (
          <LoginForm userEmail={userEmail} {...rest} />
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(NewAuthForm);
