import React from 'react';

import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';

import Grid from '@material-ui/core/Grid';
import Button from '../../StorybookComponents/Button';
import Typography from '../../StorybookComponents/Typography';
import Logo from '../../StorybookComponents/hylyLogo';

import HylyField, {create_validation} from '../../StorybookComponents/HylyField';

import Loader from '../../components/loading';
import logger from '../../components/Logger';

import { resendSignUp } from '../../utils/auth_functions';
import ConfirmSignup from './verify_confirmation_page';

import { Formik } from 'formik';


const Yup = require('yup');

const styles = theme => ({
  container: {
    position:'absolute',
    top:0,
    left:0,
    bottom:0,
    flex:1
  },
  skip: {
    position:"absolute",
    top: theme.margins.xs,
    right: theme.margins.xs,
    cursor:"pointer",
    zIndex: 9999
 },
 spacing: {
   marginTop: `${theme.margins.xl}px !important`
 },
 small_spacing: {
   marginTop: theme.margins.md
 },
 form: {
   width: '90%',
   maxWidth:'350px',
   minHeight:'400px',
   textAlign: 'center'
 },
 link: {
   color: theme.palette.hylyBlue,
   cursor:'pointer'
 }
})

const limit_exceeded_notification = id => ({
  id: `limit-exceeded-reset-notification${id}`,
  type: "warning",
  body: "Attempt limit exceeded, please try after some time."
})

let notification_id = 0;

let do_once;

const VerificationResend = ({ classes, history, set_notifications,location }) => {

  async function resendCode(
    { email__email__required: email },
    { setSubmitting, setFieldValue, setErrors }) {
    try {
      setSubmitting(true);
      await resendSignUp(email);
      logger.info(email,"verify","resendCode");

      setFieldValue("displayPage","confirmSignup");
      setSubmitting(false);
    }
    catch(err) {
      logger.error(email,"verify",err,"resendCode");

      switch(err.code) {
        case "LimitExceededException":
        case "TooManyRequestsException":
          set_notifications(limit_exceeded_notification(notification_id++));
          break;
        case "PasswordResetRequiredException":
          history.push('/set-password');
          break;
        default:
          console.error('Error in resendCode(): ',err);
          setFieldValue("displayPage","confirmSignup");
          setSubmitting(false);
      }
    }

  }

  const from_outside = location.search.indexOf('confirmSignup') > -1;

  React.useEffect(() => {
    if (!do_once && !from_outside) {
      do_once = true;
      set_notifications({
        id: "verify-password",
        type:'warning',
        body:"Your account is not confirmed yet. Please enter your email address below so that we can resend you a verification email."
      })
   }
 },[do_once])

  return (
      <Formik
        initialValues = {{
            email__email__required: '',
            displayPage: location.search.indexOf('confirmSignup') > -1 ? 'confirmSignup' : 'resend_code'
        }}
        validateOnBlur = {true}
        validateOnChange = {false}
        validationSchema = {
            Yup.object().shape({
              email__email__required: Yup.string()
                .email("Please enter a correct email.")
                .required("Email is required")
            })
        }
        onSubmit = { resendCode }
      >
      {
        props => {

        const {
          values,
          isSubmitting,
          handleSubmit,
          errors,
          status,
          isValid
        } = props;

        if (isSubmitting) { return (<Loader />); }

        if (values.displayPage === 'resend_code') {
          return (
              <form onSubmit = {handleSubmit}>
                <Grid
                  container
                  direction = "column"
                  justify = "center"
                  alignItems = "center"
                  className = {classes.container}
                >
                  <Grid
                    container
                    direction = "column"
                    className = {classes.form}
                    alignItems = "center"
                  >
                    <Logo />

                    <Typography type='h3' font_weight = {500} className = {classes.spacing}>
                      VERIFICATION EMAIL
                    </Typography>

                    <HylyField
                        className = {classes.spacing}
                        type = "email"
                        name = "email"
                        label = "Email Address"
                        required
                    />

                    <Button
                      size = "md"
                      className = {classes.spacing}
                      type = 'submit'
                      variant="primary"
                      onClick = {handleSubmit}
                      state = {
                        !isValid
                        ? 'inactive'
                        : undefined
                      }
                      >
                      RE-SEND VERIFICATION
                    </Button>
                  </Grid>
                </Grid>
              </form>
         )
       }

       return (
         <ConfirmSignup
            {...props}
            resendCode = {resendCode}
            from_outside = {from_outside}

        />
       )
      }
    }
    </Formik>
    );
  }


const {object, string} = PropTypes;

VerificationResend.propTypes = {
  classes: object.isRequired
};


export default withStyles(styles)(VerificationResend);
