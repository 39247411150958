import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '../Tooltip';
import SvgIcon from '@material-ui/core/SvgIcon';

import Icon from '../Icon';

import { ReactComponent as SaveButton } from '../../images/SAVE-xs.svg';


const size_obj = { xs: 12, sm: 16, md: 20, lg: 24, xl: 32 };


export function create_classes_based_on_settings(name, settings) {
  const { active, hover, inactive } = settings[name];
  return {
      ...active,
      cursor:'pointer',
      '&:hover:not($disabled), &.active:hover:not($disabled)': hover,
      '&$disabled': inactive,
  };
}

export function create_override_classes_based_on_settings(name, settings) {
  const { active, hover, inactive } = settings[name];
  return {
      [`${name}-active`]: active,
      [`${name}-hover`]: hover,
      [`${name}-inactive`]: inactive,
  };
}

const colors_settings = theme => ({
    "primary-hylyBlue": {
      active: {
        background: theme.palette.hylyBlue,
        color: theme.palette.white,
      },
      hover: {
        background: theme.palette.deepBlue,
        color: theme.palette.white
      },
      inactive: {
        background: theme.palette.mediumGrey,
        color: theme.palette.white
      },
    },
    "primary-white": {
      active: {
        background: theme.palette.white,
        color: theme.palette.hylyBlue,
      },
      hover: {
        background: theme.palette.white,
        color: theme.palette.deepBlue
      },
      inactive: {
        background: theme.palette.mediumGrey,
        color: theme.palette.white
      },
    },
    "secondary-darkBlack": {
      active: {
        background: "transparent",
        color: theme.palette.darkBlack
      },
      hover: {
        background: theme.palette.hoverColor,
        color: theme.palette.black
      },
      inactive: {
        background: "transparent",
        color: theme.palette.mediumGrey,
      },
    },
    "secondary-white": {
      active: {
        background: "transparent",
        color: theme.palette.white
      },
      hover: {
        background: 'rgba(255, 255, 255, 0.2)',
        color: theme.palette.white
      },
      inactive: {
        background: "transparent",
        color: theme.palette.mediumGrey,
      },
    },
    "outlined-darkBlack": {
      active: {
        color: theme.palette.darkBlack,
        borderColor: theme.palette.darkBlack,
      },
      hover: {
        background: theme.palette.hoverColor,
        color: theme.palette.black,
        borderColor: theme.palette.black,
      },
      inactive: {
        background: "transparent",
        color: theme.palette.mediumGrey,
      },
    },
    "outlined-white": {
      active: {
        color: theme.palette.white,
        borderColor: theme.palette.white,
      },
      hover: {
        background: 'rgba(255, 255, 255, 0.2)',
        color: theme.palette.white,
        borderColor: theme.palette.white,
      },
      inactive: {
        color: theme.palette.mediumGrey,
        borderColor: theme.palette.mediumGrey,
      },
    },
    floating: {
      active: {
        background: theme.palette.hylyBlue,
        color: theme.palette.white
      },
      hover: {
        background: theme.palette.deepBlue,
        color: theme.palette.white
      },
      inactive: {
        background: theme.palette.mediumGrey,
        color: theme.palette.white
      },
    },
    icon: {
      active: {
        background: "transparent",
        color: theme.palette.darkBlack,
      },
      hover: {
        background: theme.palette.hoverColor,
        color: theme.palette.black
      },
      inactive: {
        background: "transparent",
        color: theme.palette.mediumGrey,
      },
    }
});


const styles = theme => {
  const settings = colors_settings(theme);
  return {
    "primary-hylyBlue": {
      ...create_classes_based_on_settings('primary-hylyBlue', settings),
      fontWeight: 500,
      color:theme.palette.white,
    },
    "primary-white": {
      ...create_classes_based_on_settings('primary-white', settings),
      fontWeight: 500,
      color:theme.palette.white,
    },
    "secondary-darkBlack": {
      ...create_classes_based_on_settings('secondary-darkBlack', settings),
      minWidth: 40,
      fontWeight: 400,
      fontSize:14,
      padding: '2px 8px',
      maxWidth:300,
      overflow:'hidden',
      textOverflow: 'ellipsis',
      flexWrap:'nowrap'
    },
    "secondary-white": {
      ...create_classes_based_on_settings('secondary-white', settings),
      minWidth: 40,
      fontWeight: 400,
      fontSize:14,
      padding: '2px 8px',
      maxWidth:300,
      overflow:'hidden',
      textOverflow: 'ellipsis',
      flexWrap:'nowrap'
    },
    floating: {
      ...create_classes_based_on_settings('floating', settings),
      boxShadow:'none',
      color: theme.palette.white,
      fontWeight:500,
      fontSize:16,
    },
    "outlined-darkBlack": {
      border: `0.5px solid ${theme.palette.darkBlack}`,
      ...create_classes_based_on_settings('outlined-darkBlack', settings),
      fontSize:14,
      padding: '2px 8px',
      maxWidth:300,
      overflow:'hidden',
      textOverflow: 'ellipsis',
      flexWrap:'nowrap'
    },
    "outlined-white": {
      border: `0.5px solid ${theme.palette.darkBlack}`,
      ...create_classes_based_on_settings('outlined-white', settings),
      fontSize:14,
      padding: '2px 8px',
      maxWidth:300,
      overflow:'hidden',
      textOverflow: 'ellipsis',
      flexWrap:'nowrap'
    },
    icon: create_classes_based_on_settings('icon', settings),

    ...create_override_classes_based_on_settings('primary-hylyBlue', settings),
    ...create_override_classes_based_on_settings('primary-white', settings),

    ...create_override_classes_based_on_settings('secondary-darkBlack', settings),
    ...create_override_classes_based_on_settings('secondary-white', settings),

    ...create_override_classes_based_on_settings('outlined-darkBlack', settings),
    ...create_override_classes_based_on_settings('outlined-white', settings),

    ...create_override_classes_based_on_settings('floating', settings),

    ...create_override_classes_based_on_settings('icon', settings),

    sm: {
        fontSize:14,
        padding: '2px 8px',
        maxWidth:300,
        overflow:'hidden',
        textOverflow: 'ellipsis',
        flexWrap:'nowrap'
    },
    md: {
      fontSize:16,
      padding: '6px 16px',
    },
    margin_right: {
      marginRight: 8
    },
    disabled: {},
    label: {
     minWidth:0,
      maxWidth: 250,
      overflow:'hidden',
      whiteSpace:'nowrap',
      textOverflow:'ellipsis'
    }

  }
}


const IconButtonComponent = ({
  classes: { center, icon_class, icon_class_inactive },
  icon_path,
  disabled,
  className,
  icon_props,
  IconComponent,
  ...rest
}) => (
  <IconButton
      aria-label="Menu"
      classes = {{ root: `${center} ${disabled ? "" : className}`}}
      disabled = {disabled}
      {...rest}
    >
      <IconComponent
        type = {icon_path}
        className = {disabled ? icon_class_inactive : icon_class}

        {...icon_props}
      />
    </IconButton>
)

const ButtonIconComponentWithTooltip = ({
  children,
  show_tooltip,
  ...rest
}) => (
  <Tooltip
    placement = {"bottom"}
    tooltip_text = {children}
    show_tooltip = {show_tooltip}
  >
    <IconButtonComponent {...rest} />
  </Tooltip>
)

const ButtonIconHover = React.memo(props => (<ButtonIconComponentWithTooltip {...props} show_tooltip/>));
const ButtonIconDefault = React.memo(props => (<ButtonIconComponentWithTooltip {...props} /> ));

const floating_button_styles = theme => ({

});

const FloatingButtonWithTooltipComponent = React.memo(withStyles(floating_button_styles)(({
  show_tooltip,
  classname,
  disabled,
  icon_path,
  ...props
}) => (
  <Tooltip
    placement = {"bottom"}
    tooltip_text = {props.children || ""}
    show_tooltip = { show_tooltip }
    disabled = {disabled}
  >
    <Fab
      variant = { "round" }
      {...props}
      className = {classname}
      size = { "small" }
      disabled = {disabled}
    >
    <Icon
      type = {icon_path}
      size = {"lg" }
     />
    </Fab>
  </Tooltip>
)));

const FloatingButtonWithTooltipHover = React.memo(props => <FloatingButtonWithTooltipComponent {...props} show_tooltip/>)
const FloatingButtonWithTooltip = React.memo(props => <FloatingButtonWithTooltipComponent {...props}/>)

const ButtonIcon = withStyles({
  center: {
    alignSelf:'center',
    minHeight:0,
    cursor:'default',
    '&:hover': {
      background:'transparent',
    }
  },
  icon_class: {
    alignSelf: "center",
  },
  icon_class_inactive: {
    alignSelf: "center",
    cursor:'default',
  }
})(({
  children,
  IconComponent = Icon,
  disabled,
  state,
  ...rest
}) => {
  return Boolean(children && state === "hover")
  ? <ButtonIconHover
      children = {children}
      IconComponent = {IconComponent}
      {...rest}
   />
  : Boolean(children && !disabled)
  ? <ButtonIconDefault
        children = {children}
        IconComponent = {IconComponent}
        {...rest}
    />
: <IconButtonComponent {...rest} disabled = {disabled} IconComponent = {IconComponent}/>
});


const ButtonComponent = ({
  classes,
  className,
  variant,
  size,
  color: button_color,
  icon_path,
  state,
  outlined,
  extended,
  ...props
}) => {

  //const color_class = classes[`${type}-${color}`]);

  const get_default_color = type => {
    switch(type) {
      case "secondary":
      case "outlined": return "darkBlack";
      case "primary":
      case "floating":
      default: return "hylyBlue";
    }
  }

  const color = button_color || get_default_color(variant);

  const type_to_use = (size === "xs" && variant === "primary")
                      ? "icon"
                      : /primary|secondary|outlined/.test(variant)
                      ? `${variant}-${color}`
                      : variant;

  const classname_base = `${(classes[type_to_use])||""} ${(classes[`${type_to_use}-${state}`])||""}`;

  const button_classname  = `${classname_base} ${(classes[size])||""} ${(className||"")}`;
  const classname         = `${classname_base} ${(className||"")}`;

  if (size === "xs" && variant === "primary") {
    const { children, icon_path, ...rest } = props;
    return <ButtonIcon
              className = {classname}
              disabled = {state === "inactive"}
              IconComponent = {SaveButton}
              state = {state}
              {...rest}> save </ButtonIcon>;
  }

  switch(variant) {
    case "floating":
      return extended
          ? <Fab
              variant = { "extended"  }
              {...props}
              className = {classname}
              classes = {{ label: classes.label }}
              size = { "medium" }
              disabled = {state === "inactive"}
            >
            <Icon
              type = {icon_path}
              size = { "md" }
              span_class = { classes.margin_right }
             />
              { props.children || "" }
            </Fab>
          : state === "hover"
          ? <FloatingButtonWithTooltipHover
                icon_path = {icon_path}
                disabled = {state === "inactive"}
                classname = {button_classname}
                {...props}
            />
          : <FloatingButtonWithTooltip
                icon_path = {icon_path}
                disabled = {state === "inactive"}
                classname = {button_classname}
                {...props}
            />
    case "icon":
        return (
          <ButtonIcon
              disabled = {state === "inactive"}
              className = {classname}
              icon_path = {icon_path}
              state = {state}
              {...props}
         />
        )
    default:
      const children = (typeof props.children === "string" && props.children.length > 25 ? props.children.slice(0, 25) : props.children)||"";
      return (
        <Button
           {...props}
           variant = {
             variant === "secondary"
             ? 'text'
             : variant === "outlined"
             ? 'outlined'
             : 'contained'
           }
           className = {button_classname}
           classes = {{
             contained: classes.primary,
             outlined: classes.outlined,
             text: classes.secondary,
             disabled: classes.disabled,
             label: classes.label
           }}
           disabled = {state === "inactive"}
       >{children}</Button>
    )
  }
}


function valid_button_children (props, propName, componentName) {
  if (!props[propName]) { return null; }
  if (typeof props[propName] !== "string" && !(React.isValidElement(props[propName]))) {
    return new Error(
      `Invalid prop '${propName}' supplied to '${componentName}'. Expected a react component or a string but got ${typeof props[propName]}.`
    );
  }
  else if (typeof props[propName] === "string" && props[propName].length > 25) {
    return new Error(
      `${componentName} - prop '${propName}' should have a length of no more than 25 characters. Received a string with length of ${props[propName].length} chars.`
    );
  }
  return null;
}

ButtonComponent.propTypes = {
  variant: PropTypes.oneOf(['primary','outlined','secondary','floating','icon']),
  color: PropTypes.oneOf(['white','hylyBlue','darkBlack']),
  size: PropTypes.oneOf(['xs','sm','md']),
  icon_path: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  state: PropTypes.oneOf(["active", "hover", "inactive"]),
  children: valid_button_children
}

ButtonComponent.defaultProps = {
  variant: 'primary',
  size: 'sm',
};

export default withStyles(styles)(ButtonComponent);
