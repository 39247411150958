//global app to hymail module
export const globalTohyMail = {
  hyBlast: {
    name: "Blasts",
    url: "blast",
    orgLevelUrl: "blast/approvals/my-approval",
  },
  hyALM: {
    name: "Drips",
    url: "drips",
    orgLevelUrl: "drips/reports/by-property",
  },
  hyAlert: {
    name: "Alerts",
    url: "alerts",
    orgLevelUrl: "alerts/reports/by-property",
  },
  hyRaf: {
    name: "Raf",
    url: "refer-a-friend",
    orgLevelUrl: "refer-a-friend",
  },
  hyEvents: {
    name: "Events",
    url: "events",
    orgLevelUrl: "events/approvals/my-approval",
  },
  hyTours: {
    name: "Tours",
    url: "tours",
    orgLevelUrl: "tours",
  },
};
