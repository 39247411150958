import React from "react";
import PropTypes from "prop-types";
import graphql from "babel-plugin-relay/macro";
import { createFragmentContainer } from "react-relay";

import withStyles from "@material-ui/core/styles/withStyles";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Icon from "../Icon";
import HylyLogo from "../../images/hylylogo.svg";

import Typography from "../Typography";

const header_styles = (theme) => ({
  root: {
    cursor: "pointer",
    padding: `${theme.margins.sm}px`,
    height: 49,
  },
  list_item: {
    ...theme.typography.h3,
    color: theme.palette.darkBlack,
    fontWeight: 600,
    textOverflow: "ellipsis",
    overflow: "hidden",
    lineHeight: 1.5,
    whiteSpace: "nowrap",
  },
  zero_margin_right: {
    marginRight: 0,
  },
  rotate_icon: {
    webkitTransform: "rotate(180deg)",
    transform: "rotate(180deg)",
  },
  default_icon: {
    webkitTransform: "rotate(0deg)",
    transform: "rotate(0deg)",
  },
});

export default React.memo(
  withStyles(header_styles)(
    ({ classes, name, onClick, pressed, menu_open }) => {
      return (
        <>
          {menu_open ? (
            <ListItem classes={{ root: classes.root }} onClick={onClick}>
              <>
                <ListItemText
                  primary={name}
                  classes={{
                    primary: classes.list_item,
                  }}
                />
                <ListItemIcon className={classes.zero_margin_right}>
                  <Icon
                    type="icon-triangle-down"
                    size="xs"
                    className={
                      pressed ? classes.rotate_icon : classes.default_icon
                    }
                  />
                </ListItemIcon>
              </>
            </ListItem>
          ) : (
            <ListItem classes={{ root: classes.root }}>
              <img
                src={HylyLogo}
                alt="hyly logo"
                style={{ width: 40, height: 40 }}
              />
            </ListItem>
          )}
          <Divider />
        </>
      );
    }
  )
);
