import {commitMutation} from 'react-relay';
//import {ConnectionHandler} from 'relay-runtime';
import graphql from 'babel-plugin-relay/macro';

const mutation = "";

function commit(environment, input, context) {

  return new Promise((res, rej) => (
    commitMutation(environment, {
    mutation,
    variables: { input },
    onCompleted: response => res(response),
    onError: error => rej(error)
    })
  )
);

}

export default commit;
