import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const EyeIcon = (prop) => (
  <SvgIcon {...prop}>
    <path
      d="M1.875 10.2084C1.875 10.2084 5.16667 5.20839 10.2083 5.20839C15.2083 5.20839 18.5417 10.2084 18.5417 10.2084C18.5417 10.2084 15.1667 15.2084 10.2083 15.2084C5.20833 15.2084 1.875 10.2084 1.875 10.2084Z"
      stroke="#666666"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
    <path
      d="M10.2083 11.8751C12.0493 11.8751 13.5417 10.3827 13.5417 8.54173C13.5417 6.70078 12.0493 5.20839 10.2083 5.20839C8.36738 5.20839 6.875 6.70078 6.875 8.54173C6.875 10.3827 8.36738 11.8751 10.2083 11.8751Z"
      stroke="#666666"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
  </SvgIcon>
);

export const EyeClosedIcon = (prop) => (
  <SvgIcon {...prop}>
    <path
      d="M15.5001 7.732C16.7301 8.9 17.5001 10 17.5001 10C17.5001 10 14.0001 15 10.0001 15C9.48809 15 8.98509 14.918 8.49609 14.775"
      stroke="#666666"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.275 13.725C4.048 12.243 2.5 10 2.5 10C2.5 10 6 5 10 5C11.326 5 12.605 5.553 13.712 6.288"
      stroke="#666666"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3 17L17 3"
      stroke="#666666"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </SvgIcon>
);
