import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import Typography from '@material-ui/core/Typography';

const createClasses = (obj, property, name_addition = '') => (
  Object.keys(obj).reduce((a, c) =>({...a, [`${name_addition}${c}`]: { [property]: obj[c]} }),{})
);

const TypographyComponent = ({
  className,
  classes,
  color,
  font_weight,
  type,
  ...props
}) => (
  <Typography
      className = {`
        ${classes[`color_${color}`]}${' '}
        ${classes[`fontWeight_${font_weight}`]}
        ${className}
      `}
      variant = { type === "subtitle" ? "subtitle1" : type }
      {...props}
  />
)


TypographyComponent.propTypes = {
  color: PropTypes.oneOf([
    'white',
    'black',
    'darkBlack',
    'deepGrey',
    'mediumGrey',
    'hylyBlue',
    'red'
  ]),
  type: PropTypes.oneOf(['h1','h2','h3','h4','h5','body1','body2','subtitle']),
  font_weight: function(props, propName, componentName) {
    if (!/\d+/.test(props[propName]) || +props[propName] > 600 || +props[propName] < 200) {
      return new Error(
        'Invalid prop `' + propName + '` supplied to' +
        ' `' + componentName + '`. Expected a number in range between 200 - 600.'
      );
    }
  }
}

TypographyComponent.defaultProps = {
  color: "darkBlack",
  font_weight: "400"
}

export default withStyles(theme => ({
  ...createClasses({
    white: '#fff',
    black: '#000',
    darkBlack: '#333',
    deepGrey: '#999',
    mediumGrey: '#CCC',
    hylyBlue: '#26bbed',
    red: '#FF5151',
  },'color','color_'),
  ...createClasses({
    "200": 200,
    "300":300,
    "400": 400,
    "500":500,
    "600": 600,
  }, "fontWeight", 'fontWeight_')
}))(TypographyComponent);
