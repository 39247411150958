import React, { useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import List from "@material-ui/core/List";
import Icon from "../Icon";

const styles = (theme) => ({
  darkColor: {
    color: "#333",
  },
  blueColor: {
    color: "#26BBED !important",
  },
});

const NavListItem = (props) => {
  let [color, setColor] = useState(props.classes.darkColor);
  let [blueColor, setBlueColor] = useState(props.classes.blueColor);

  let is_parent = props.is_parent;
  let create_path = props.create_path;
  let children = props.children;
  let menu_open = props.menu_open;
  let link_component = props.link_component;
  let is_online = props.is_online;
  let disabled_when_offline = props.disabled_when_offline;
  let parent_to = props.parent_to;
  let title = props.title;
  let open_data = props.open_data;
  let check_if_selected = props.check_if_selected;
  let onClose = props.onClose;
  let itemSelected = props.itemSelected;
  let drop_down_icon = props.drop_down_icon;
  let data_open = props.data_open;
  let index = props.index;
  let drop_down_icon_open = props.drop_down_icon_open;
  let drop_down_icon_close = props.drop_down_icon_close;
  let listItem = props.listItem;
  let nested = props.nested;
  let textItem = props.textItem;
  let nestedText = props.nestedText;
  let selected = props.selected;
  let icon_class_container = props.icon_class_container;
  let icon_type = props.icon_type;
  let pathname = props.pathname;
  let remove_padding = props.remove_padding;
  let setSelectedItem = props.setSelectedItem;

  return (
    <React.Fragment key={title + index}>
      <ListItem
        onMouseEnter={() => setColor(props.classes.blueColor)}
        onMouseLeave={() => setColor(props.classes.darkColor)}
        component={is_parent(children) ? "div" : link_component}
        to={create_path(parent_to)}
        onClick={(e) => {
          setSelectedItem(title);
          return is_parent(children) &&
            Boolean(is_online || !disabled_when_offline)
            ? open_data(title)
            : is_parent(children) || menu_open
            ? null
            : onClose(e);
        }}
        classes={{
          root: listItem,
          selected,
        }}
      >
        <ListItemIcon className={icon_class_container}>
          <Icon type={icon_type} className={ title === itemSelected ? blueColor:color} />
        </ListItemIcon>
        {menu_open ? (
          <ListItemText
            primary={title}
            classes={{
              primary: `${
                title === itemSelected ? selected : textItem
              } ${color}`,
            }}
          />
        ) : null}
        {is_parent(children) && (
          <Icon
            size="sm"
            type={drop_down_icon}
            className={
              data_open[title] ? drop_down_icon_open : drop_down_icon_close
            }
          />
        )}
      </ListItem>
      {is_parent(children) && (
        <Collapse in={data_open[title]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map(({ to, path, title, disabled, children }, index) => {
              const data_selected = check_if_selected(children, path, pathname);
              return (
                <ListItem
                  key={title}
                  selected={data_selected}
                  component={link_component}
                  to={create_path(to || parent_to)}
                  onClick={menu_open ? null : onClose}
                  classes={{
                    root: data_selected ? nested + " " + selected : nested,
                  }}
                >
                  <ListItemText
                    inset
                    primary={title}
                    classes={{
                      root: remove_padding,
                      primary:
                        nestedText + (data_selected ? " " + selected : ""),
                    }}
                  />
                </ListItem>
              );
            })}
          </List>
        </Collapse>
      )}
    </React.Fragment>
  );
};

export default withStyles(styles)(NavListItem);
