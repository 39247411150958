import React from 'react';
import ReactDOM from 'react-dom';
import { Authenticator } from 'aws-amplify-react';
import createFragment from 'react-addons-create-fragment';
import * as serviceWorker from './service_worker';
import Amplify, { Auth } from 'aws-amplify';
import AccountsStorage from './app/components/amplify_storage';

import Loader from './app/components/loading';

import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import CssBaseline from '@material-ui/core/CssBaseline';

import LazyComp from './app/components/lazy_comp';

import withTheme from './app/introduction_theme';
import withAppTheme, { theme } from './app/pages/profile/themes';
import CacheBuster from "./CacheBuster"
import App from './routes';
import AIApp from './app_ai';

import {
  logout,
  getAuthState,
  gotoMyhyly,
  findGotoPath,
  redirectAfterAuth,
  gotoApp,
  aiOrApp,
  constructPathFromName
} from './app/utils/auth_functions';


import { BrowserRouter as Router, Route, Link, Redirect, Switch } from "react-router-dom";


import './index.css';

// Amplify.Logger.LOG_LEVEL = 'DEBUG';
const config = {
  Auth: {
    region: process.env.REACT_APP_Region,
    userPoolId: process.env.REACT_APP_UserPool,
    userPoolWebClientId: process.env.REACT_APP_ClientId,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    cookieStorage: {
      domain: process.env[`REACT_APP_domain_${aiOrApp}`],
      path: '/',
      expires: 7,
      secure: process.env.NODE_ENV === "production"
    },
    storage: AccountsStorage,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_AUTH_DOMAIN,
      redirectSignIn: process.env[`REACT_APP_SAML_REDIRECT_SIGN_IN_${aiOrApp}`],
      redirectSignOut: process.env[`REACT_APP_SAML_REDIRECT_SIGN_OUT_${aiOrApp}`],
      responseType: 'code'
    }
  }
}

Amplify.configure(config);

const NotificationList = require('./app/StorybookComponents/Notification').default;

let { goto, alias } = findGotoPath(aiOrApp);
class Application extends React.Component {

  constructor() {
    super();
    this.state = {
      notifications: [],
      notification_callback: null,
      loading: true,
      loggedIn: false
    }
    this.set_notifications = this.set_notifications.bind(this);
  }

  set_notifications(noti, notification_callback) {
    this.setState({
      notifications: [...this.state.notifications, noti],
      notification_callback
    })
  }


  render() {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }
          return aiOrApp == "ai"
                ? (<Router><AIApp goto={goto} alias = {alias} /></Router>)
                : (
                  <Router>
                      <NotificationList
                        notifications={this.state.notifications}
                        removeNotification={this.state.notification_callback}
                      />
                      <App
                        set_notifications={this.set_notifications}
                        redirectAppAfterLogin={goto}
                        redirectAppAfterLoginAlias = {alias}
                      />
                  </Router>
                )            
        }}
      </CacheBuster >
    )
  }
}
//


ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <Authenticator hideDefault={true}>
      <Application />
    </Authenticator>
  </MuiThemeProvider>
  ,
  document.getElementById('root')
);


serviceWorker.unregister();
//serviceWorker.register();
