import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

const createClasses = (obj, property, name_addition = '') => {
  if (typeof property === "string") {
    return Object.keys(obj).reduce((a, c) =>({
        ...a,
        [`${name_addition}${c}`]: { [property]: obj[c]}
      }),{});
  }
  else if (Array.isArray(property)) {
    return Object.keys(obj).reduce((a, c) =>({
        ...a,
        [`${name_addition}${c}`]: {
            ...property.reduce((a_p,p) => ({
                ...a_p,
                [p]: obj[c]
            }),{})
         }
      }),{})
  }
  else {
    throw new Error(`expect property to be either a string or an array but received ${property}`);
  }
}


const size_obj = { xs: 12, sm: 16, md: 20, lg: 24, xl: 32 };

//console.log("getFontSize(sm): ",getFontSize("sm"));
const styles = theme => ({
  defaultClass: {
    color: theme.palette.black.dark,
    fontWeight:theme.fontWeight.light,
    padding:0,
    margin:0,
    transition: 'all .2s ease-in-out',
    alignSelf: 'center'
  },
  span_class: {
    display:"inline-flex"
  },
  ...createClasses(size_obj,['fontSize','width','height'],'size_'),
})


const IconComponent = ({
  classes: {
    defaultClass,
    disabled_class,
    span_class,
    ...other
  },
  className,
  type,
  size,
  ...props
}) => {
  return (
      <span
        className = {`${span_class} ${(props.span_class||"")}`}
        {...props}
      >
        <i
           className = {`
             icon ${type}
             ${` ${defaultClass} `}
             ${`${other[`size_${size}`]} `}
             ${className}
            `}
        >
        </i>
      </span>
  )
}

export const icon_options_array = [
'icon-left-arrow',
'icon-right-arrow',
'icon-calendar-date-2',
'icon-c-remove',
'icon-time-clock',
'icon-floors',
'icon-feedback',
'icon-check-list',
'icon-apps-1',
'icon-analytics-1',
'icon-comment-add',
'icon-camera-20',
'icon-key-25',
'icon-award-48',
'icon-world',
'icon-multiple-11',
'icon-apartment',
'icon-new-construction',
'icon-check-single',
'icon-users-mm',
'icon-bicep',
'icon-event-confirm',
'icon-recipe',
'icon-shape-arrow',
'icon-shape-star',
'icon-refresh-01',
'icon-widget',
'icon-triangle-right',
'icon-zoom',
'icon-todo',
'icon-arrow-right',
'icon-arrow-down',
'icon-arrow-left',
'icon-arrow-up',
'icon-tag',
'icon-system-update',
'icon-stretch',
'icon-data-download',
'icon-frame-s-top-right',
'icon-smile',
'icon-e-remove',
'icon-e-add',
'icon-e-delete',
'icon-sim-card',
'icon-shuffle-98',
'icon-gear',
'icon-settings',
'icon-enlarge',
'icon-sad',
'icon-reply',
'icon-reload',
'icon-refresh',
'icon-pin-3',
'icon-pencil',
'icon-pen-23',
'icon-up-arrow',
'icon-down-arrow',
'icon-menu-2',
'icon-margin-left',
'icon-margin-right',
'icon-chain',
'icon-thumb-up',
'icon-thumb-down',
'icon-layers-2',
'icon-home-2',
'icon-sort-tool',
'icon-filter-tool',
'icon-heart',
'icon-eye',
'icon-preview',
'icon-b-preview',
'icon-edit-2',
'icon-drop',
'icon-delete-key',
'icon-respond-arrow',
'icon-frame-c-arrow-up',
'icon-frame-c-arrow-right',
'icon-frame-c-arrow-down',
'icon-c-delete',
'icon-c-add',
'icon-s-check',
'icon-i-check',
'icon-comment',
'icon-f-comment',
'icon-f-chat',
'icon-b-chat',
'icon-b-comment',
'icon-a-chat',
'icon-chart-bar-32',
'icon-chart-pie-36',
'icon-d-chart',
'icon-caps-all',
'icon-calendar-event-2',
'icon-calendar-date-2-2',
'icon-calendar-2',
'icon-calendar-event-create',
'icon-alarm',
'icon-attachment',
'icon-align-justify',
'icon-clock',
'icon-network-communication',
'icon-document',
'icon-grid-interface',
'icon-bin',
'icon-menu-5',
'icon-single-02',
'icon-multiple',
'icon-disabled',
'icon-mobile-phone',
'icon-headset',
'icon-logo-facebook',
'icon-logo-linkedin',
'icon-logo-youtube',
'icon-send-message',
'icon-mail',
'icon-picture',
'icon-single-folded',
'icon-single-content-03',
'icon-network-communication-2',
'icon-folder-18',
'icon-cloud-upload-94',
'icon-cloud-download-93',
'icon-edit-file',
'icon-single-copy-04',
'icon-menu-2-2',
'icon-menu-8',
'icon-background',
'icon-geometry',
'icon-sidebar',
'icon-logo-messenger',
'icon-reply-all',
'icon-clock-2',
'icon-ic_more_vert_24px',
'icon-phone',
'icon-star-rate',
'icon-timeline',
'icon-b-comment-2',
'icon-database',
'icon-folder-edit',
'icon-folder-money',
'icon-round-dollar',
'icon-currency-dollar',
'icon-webpage',
'icon-triangle-up',
'icon-triangle-down',
'icon-s-delete',
'icon-stretch-2',
'icon-analytics',
'icon-list-bullet',
'icon-ic_sort_by_alpha_24px',
'icon-pin',
'icon-frame-c-arrow-left',
'icon-enlarge-2',
'icon-increase',
'icon-corner-top-right',
'icon-block-right',
'icon-block-left',
'icon-key',
'icon-access-key',
'icon-s-remove',
'icon-c-check',
'icon-n-check',
'icon-file-download',
'icon-alarm-2',
'icon-Artie-icon-small',
'icon-drop-15',
'icon-chart',
'icon-button',
'icon-dots-to-close-2',
'icon-palette',
'icon-notification',
'icon-button-power',
'icon-time-alarm',
'icon-floppy-disk',
'icon-i-check-2'
];

IconComponent.propTypes = {
  type: PropTypes.oneOf(icon_options_array).isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(['xs','sm','md','lg','xl']),
}

IconComponent.defaultProps = {
  type: 'icon-home-2',
  className: '',
  size: 'md',
};

export default withStyles(styles)(IconComponent);
