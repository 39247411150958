import moment from "moment";

export const getBanner = () => {
    if (localStorage.getItem("banner")) {
        var c = moment().format("M/D/YYYY");
        var d = moment(localStorage.getItem("banner"));
        var diffDays = d.diff(c, "days");
        if (diffDays <= 0) return false;
        return true;
    } else {
        return false;
    }
};

export const checkVersionIsLatest = (versionA, versionB) => {
    if (versionA && versionB) {
        const versionsA = versionA.split(/\./g);
        const versionsB = versionB.split(/\./g);
        while (versionsA.length || versionsB.length) {
            const a = Number(versionsA.shift());
            const b = Number(versionsB.shift());
            if (a === b) continue;
            return a > b || isNaN(b);
        }
        return false;
    }
};


export const setCurrentDate = () => {
    var c = moment().format("M/D/YYYY");
    localStorage.setItem("banner", c);
};


export const clearAllStorage = () => {
    var banner = localStorage.getItem("banner");
    var version = localStorage.getItem("version");
    var hylybannerExpTime = localStorage.getItem("#hylybanner-exp-time");
    var hylybannerActive = localStorage.getItem("#hylybanner-active");
    // localStorage.clear();
    localStorage.setItem("banner", banner);
    localStorage.setItem("version", version);
    localStorage.setItem("drawer", true);
    if (hylybannerExpTime)
        localStorage.setItem("#hylybanner-exp-time", hylybannerExpTime);
    if (hylybannerActive)
        localStorage.setItem("#hylybanner-active", hylybannerActive);
};

export const setNotificationId = id => {
    localStorage.setItem("notificationId", id);
};

export const getDrawer = () => {
    if (localStorage.getItem("drawer") === "true") return true;
    return false;
};

export const setDrawer = value => {
    localStorage.setItem("drawer", value);
};


export const setBanner = () => {
    if (
        localStorage.getItem("banner") &&
        localStorage.getItem("banner") !== "null"
    )
        return;
    var d = moment()
        .add(1, "M")
        .format("M/D/YYYY");
    localStorage.setItem("banner", d);
};

export const setVersion = (latest, current) => {
    var version = {
        new: latest,
        old: current
    };
    localStorage.setItem("version", JSON.stringify(version));
};

export const getVersion = () => {
    var versions = JSON.parse(localStorage.getItem("version"));
    if (versions) return versions;
};


export const isLatest = () => {
    var versions = JSON.parse(localStorage.getItem("version"));
    if (versions) {
        const versionsA = versions.new.split(/\./g);
        const versionsB = versions.old.split(/\./g);
        while (versionsA.length || versionsB.length) {
            const a = Number(versionsA.shift());
            const b = Number(versionsB.shift());
            if (a === b) continue;
            return a > b || isNaN(b);
        }
        return false;
    }
    return false;
};