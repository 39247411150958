
import React from 'react';
import {fetchQuery} from 'relay-runtime';
import graphql from 'babel-plugin-relay/macro';
import myhyly_environment from './myhyly_environment';

export async function userStatusGet(email) {
  try {
    const data = await fetchQuery(
      myhyly_environment,
      graphql`
        query queriesQuery($email: String!) {
          auth(email: $email) {
            name
            url
            cognitoUserStatus
          }
        }
      `,
      {
        email: email
      }
    );
    return data && data.auth;
  }
  catch(err) {
    console.error('Error in userStatusGet! ',err);
  }
}
