import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "../ButtonNew";

const styles = theme => ({
  root: {
    position: "fixed",
    bottom: 20,
    // left: "-100px",
    zIndex: 2000
  },
  box: {
    width: 350,
    height: 170,
    background: "#FFFFFF",
    border: "1px solid #EEEEEE",
    boxSizing: "border-box",
    boxShadow: "0px 4px 10px rgba(151, 151, 151, 0.25)",
    borderRadius: 5
  },
  strip: {
    height: 10,
    background: "#E75F75"
  },
  content: {
    padding: 16
  },
  actions: {
    display: "flex",
    marginTop: 16
  },
  leftArrow: {
    width: 0,
    height: 0,
    borderTop: "10px solid transparent",
    borderRight: "20px solid #EEE",
    borderBottom: "10px solid transparent",
    position: "absolute",
    bottom: 8,
    left: "-18px"
  },
  leftArrow2: {
    width: 0,
    height: 0,
    borderTop: "8px solid transparent",
    borderRight: "18px solid #fff",
    borderBottom: "8px solid transparent",
    position: "absolute",
    bottom: 12,
    left: "-12px"
  },
  message: {
    fontFamily: "Open Sans, sans-serif",
    fontSize: 14
  },
  hide: {
    display: "none"
  }
});

class Version extends React.Component {
  render() {
    const {
      classes,
      message,
      actionButton,
      laterButton,
      handleAction,
      handleLater,
      arrow,
      orgLevel
    } = this.props;

    return (
      <div
        className={classes.root}
        style={{ marginLeft: 20, bottom: orgLevel ? 40 : 30 }}
      >
        <div className={classes.box}>
          <div className={classes.strip}></div>
          <div
            className={arrow ? `${classes.leftArrow}` : `${classes.hide}`}
          ></div>
          <div
            className={arrow ? `${classes.leftArrow2}` : `${classes.hide}`}
          ></div>
          <div className={classes.content}>
            <div className={classes.message}>{message}</div>
            <div className={classes.actions}>
              <Button
                variant="secondary"
                size="sm"
                state="active"
                color="darkBlack"
                onClick={handleAction}
              >
                {actionButton}
              </Button>
              <Button
                variant="tertiary"
                size="sm"
                state="active"
                color="darkBlack"
                onClick={handleLater}
              >
                {laterButton}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Version);
