import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import Form from './form';
import {DateTime} from 'luxon';

import {Auth} from 'aws-amplify';

import {debounce} from 'lodash';

import {create_validation} from './password_validation';

import localStorage from '../../components/localStorage';
import Typography from '../../StorybookComponents/Typography';

import {logout} from '../../utils/auth_functions';
import { Redirect } from "react-router-dom";
import logger from '../../components/Logger';


const create_errors = errors => ({
  ...errors,
  required: field => {
    switch(true) {
      case /current/g.test(field): return 'Current Password is required';
      case /ps_confirm/.test(field): return 'Confirm New Password is required';
      case /password/.test(field): return 'Password is required';
      default: return "";
    }
  }
})

let pwd_change_id = 0;
let notification_id = 0;

const handle_errors = err => {
  switch(err) {
    default: return 'Something went wrong while trying to change your password. Please try again later.'
  }
}

const limit_exceeded_notification = id => ({
  id: `limit-exceeded-reset-notification${id}`,
  type: "warning",
  body: "Attempt limit exceeded, please try after some time."
})


const error_item_styles = theme => ({
  root: {
    display:'inline-flex'
  },
  link: {
      cursor: 'pointer'
  }
})

const ForgotPasswordComponent = React.memo(withStyles(error_item_styles)(({
  classes,
  logout_to_verify
}) => (<>
      <Typography
        className = {classes.root}
        color = "red"
        component = "span"
      >
         Have you forgotten your password?&nbsp;
      </Typography>
      <Typography
        component = "span"
        className = {classes.root + ' ' + classes.link}
        color = "hylyBlue"
        onClick = {logout_to_verify}
      >
         Please reverify your email to set a new password.
      </Typography>
    </>))
);

const AttemptLimitExceededComponent = React.memo(withStyles(error_item_styles)(({
  classes,
  logout_to_verify
}) => (<>
      <Typography
        className = {classes.root}
        color = "red"
        component = "span"
      >
         Attept limit exceeded. Please try again after a while or &nbsp;
      </Typography>
      <Typography
        component = "span"
        className = {classes.root + ' ' + classes.link}
        color = "hylyBlue"
        onClick = {logout_to_verify}
      >
         logout and reverify your email to set a new password.
      </Typography>
    </>))
);


const do_once = true;

const wait_and_do = func => new Promise(res => setTimeout(() => res(func()),50));

const ChangePassword = React.memo(({
  email,
  id: user_id,
  set_user,
  set_notifications,
  redirectToAuthRoute,
  stop_authentication
}) => {
  const attempt_limit = `${user_id}-attempt-limit`;
  const limit_exceeded = +localStorage.getItem(attempt_limit)>2;

  const [authenticated_user, set_authenticated_user] = React.useState(null);
  const [current_disabled, set_current_disabled] = React.useState(limit_exceeded);

  async function logout_to_verify() {
    await redirectToAuthRoute('set-password');
  }

  const default_current_error     = <ForgotPasswordComponent logout_to_verify = {logout_to_verify}/>;

  const current_password_errors = [
    "Current password is incorrect. Please try again.",
    "Current password is incorrect. You have one more try.",
     default_current_error
  ];

  const [initialStatus, set_initialValues] = React.useState(
    limit_exceeded
    ? { password__current_password__required: default_current_error }
    : undefined
  )

  React.useEffect(() => {
    async function set_user() {
      const authenticated_user = await Auth.currentAuthenticatedUser();
      set_authenticated_user(authenticated_user);
    }
    set_user();
  },[do_once])

//console.log("authenticated_user: ",authenticated_user);

  async function handleCurrentPasswordErrors(err, attempt_limit, setStatus) {
    if (err.code === "LimitExceededException") {
      setStatus({ password__current_password__required: <AttemptLimitExceededComponent logout_to_verify = {logout_to_verify}/> });
      set_current_disabled(true);
      return;
    }
    const number_of_attempts = +localStorage.getItem(attempt_limit);
    localStorage.setItem(attempt_limit, number_of_attempts + 1);
    setStatus({
      password__current_password__required:
        current_password_errors[number_of_attempts] || default_current_error
    });
    if (number_of_attempts > 1) {
      set_current_disabled(true);
    }
  }

  async function checkCurrentPassword(e,
    {
      form: {
        values: { password__current_password__required: current_password },
        errors,
        setStatus
      }
    }
  ) {
    try {
      if (!current_disabled && Boolean(current_password)) {
        await Auth.changePassword(authenticated_user, current_password, current_password);
        set_current_disabled(true);
        localStorage.removeItem(attempt_limit);
        logger.info(
          authenticated_user&& authenticated_user.attributes.email,
          "profile__password_change","check_current_password", {user: authenticated_user}
        );

      }
    }
    catch(err) {
      logger.error(
        authenticated_user&& authenticated_user.attributes.email,
        "profile__password_change",err, "check_current_password"
      );
      console.error("Error in checkCurrentPassword(): ",err);
      handleCurrentPasswordErrors(err, attempt_limit, setStatus);
    }
  }

  async function reset_password_form(
    e,
    handle_reset_func,
    setValues,
    setErrors,
    setStatus,
    values
  ) {
    if (current_disabled) {
      const { password__current_password__required, ...other_values } = values;

      setValues({
        password__current_password__required,
        ...Object.keys(other_values).reduce((a,c) => ({ ...a, [c]: "" }), {})
      })
      setErrors({});
      setStatus(null);
    }
    else {
      handle_reset_func(e);
    }
  }

  async function handleSubmit(
    {
      password__current_password__required: current_password,
      password__password__required: password
    },
    { setSubmitting, setStatus, setValues, setErrors, setFieldValue }
  ) {
    try {
      await Auth.changePassword(authenticated_user, current_password, password);

      setSubmitting(false);
      setFieldValue("password__current_password__required",password);
      set_notifications({
            id: `pwd-change-${pwd_change_id++}`,
            type: 'success',
            body: 'Your password has been successfully changed.'
      });
      logger.info(
        authenticated_user&& authenticated_user.attributes.email,
        "profile__password_change","handleSubmit", {user: authenticated_user}
      );

      await Auth.signOut({ global: true });
      await redirectToAuthRoute('login');

    }
    catch(err) {
      let errors = {};
      logger.error(
        authenticated_user&& authenticated_user.attributes.email,
        "profile__password_change",err,"handleSubmit"
      );

      switch(err.code) {
        case 'InvalidPasswordException':
        case "InvalidParameterException":
            errors.password__password__required = "Your password should be at least 12 characters long. It should contain at least one alphabetic character and one numeric character.";
            break;
        case "LimitExceededException":
        case "TooManyRequestsException":
            set_notifications(limit_exceeded_notification(notification_id++));
            break;
        case "PasswordResetRequiredException":
          await redirectToAuthRoute('set-password');
          break;
        case 'UserNotConfirmedException':
          await redirectToAuthRoute('verify');
          break;
        default:
          console.error("Error in handleSubmit(): ",err);
          errors = {}
     }
     if (Object.keys(errors).length) {
       setErrors(errors);
     }
     setSubmitting(false);
   }
 }

 const fields = [
   {
     name: 'current_password',
     type: 'password',
     label:'Current Password ',
     required: true
   },
   {
     name: 'password',
     type: 'password',
     label:'New Password',
     required: true

   },
   {
     name: 'ps_confirm',
     type: 'password_confirm',
     label:'Confirm New Password',
     required: true
   },
 ];

return (
    <Form
      create_validation = { () => create_validation(fields, create_errors) }
      fields = {fields}
      handleSubmit = {handleSubmit}
      checkCurrentPassword = {checkCurrentPassword}
      email = {email}
      current_disabled = {current_disabled}
      reset_password_form = {reset_password_form}
      initialStatus = {initialStatus}
    />
  )

})

export default ChangePassword;
