const styles = (theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    backgroundColor: "#FFF",
    display: "flex",
  },
  // Welcomeboard
  welcomeBoard: {
    position: "relative",
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      flexFlow: "column",
      maxWidth: "36rem",
      width: "36%",
      height: "100%",
      background:
        "radial-gradient(100% 100% at 27.26% 0%, #FFFFFF 0%, #F4FCFF 100%)",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logoText: {
    marginLeft: "1rem",
    color: "#26BBED",
    fontSize: "2.5rem",
    lineHeight: "3.375rem",
    textAlign: "center",
    "& span": {
      fontWeight: 600,
    },
  },
  descText1: {
    marginTop: "2.5rem",
    color: "#333",
    fontSize: "1.25rem",
    lineHeight: "1.875rem",
    textAlign: "center",
  },
  descText2: {
    marginTop: "0.25rem",
    color: "#333",
    fontSize: "1.5rem",
    lineHeight: "2.25rem",
    fontWeight: 600,
    textAlign: "center",
  },
  shyArtie: {
    position: "absolute",
    right: 0,
    bottom: 0,
    width: "12.5rem",
    height: "12.5rem",
    overflow: "hidden",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },

  // form box
  formBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFF",
    height: "100%",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "64%",
    },
  },
  formRoot: {
    width: "22.5rem",
  },
  formTitle: {
    fontSize: "2.5rem",
    lineHeight: "2.75rem",
    color: "#333",
  },
  formDescText1: {
    fontSize: "1.25rem",
    lineHeight: "1.875rem",
    color: "#333",
  },
  extraFields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  checkbox: {
    color: "#999",
    padding: 0,
  },
  checkedbox: {
    color: "#26BBED !important",
  },
  forgotPassword: {
    textTransform: "none",
    padding: 0,
    color: "#4F6FAE",
    fontSize: "0.75rem",
    lineHeight: "1.125rem",
    "&:hover": {
      backgroundColor: "#FFF",
    },
  },
  rememberMeGroup: {
    margin: 0,
  },
  rememberMeLabel: {
    color: "#333",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
  signin: {
    backgroundColor: "#26BBED",
    color: "#FFF",
    fontWeight: 600,
    textTransform: "capitalize",
    padding: "12px 16px",
    "&:hover": {
      backgroundColor: "#199AC5",
    },
  },
  showPwdIcon: {
    width: 20,
    height: 20,
    fill: "none",
    cursor: "pointer",
  },
  underline: {
    "&:hover:before": {
      borderBottom: "1px solid #26BBED !important",
    },
    "&:beore": {
      borderBottom: "1px solid #DADADA",
    },
    "&:after": {
      borderBottom: "1px solid #333",
    },
  },
});

export default styles;
