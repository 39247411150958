export const analyzeTemplate = (template, num, type) => {
  if (type === "icon") { return; }
  return template.replace(/selected/g,num).replace(/([A-Za-z0-9_]+)([+]+[s]+\b)/g, (m, z1, z2) => `${z1}${num>1?'s':''}`);
}

export const select_class_for_menu = ({ direction, fullWidth, classes, no_adornment }) => {

  if (Boolean(fullWidth)) {
    return direction === "right" ?
        classes.paper_class_with_full_size_on_small_screens_right :
        classes.paper_class_with_full_size_on_small_screens;
  }

  return direction === "right" && no_adornment ? classes.paper_class_no_adornment_right :
         direction === "left" && no_adornment ? classes.paper_class_no_adornment :
         direction === "right" ? classes.paper_class_right :
         classes.paper_class;
}

const paper_class = {
  minWidth:'112px',
  maxWidth:'300px',
  marginLeft: -45,
}
const paper_class_right = {
  display:'inline-block',
  marginLeft:36,
  minWidth:'112px',
  maxWidth:'300px',
}
const paper_class_no_adornment = {
  ...paper_class,
  marginLeft: 0
}

const paper_class_no_adornment_right = {
  ...paper_class_right,
  marginLeft:0,
}


export default (theme) => ({
  formControl: {
  //  width:'100%',
  //  margin: '24px 0'
  },

  formControl_with_border: {
    border:`1px solid ${theme.palette.lightGrey}`
  },
  box_root: {
    fontSize: '5px !important',
    margin:0,
    padding:'0'
  },
  colorSecondary: {
    '&$checked': {
      color:theme.palette.hylyBlue
    },
    '&$disabled': {
      color: theme.palette.action.disabled,
    },
  },
  root: {
    width:'100%',
    display:'flex',
    alignItems:'center',
    cursor:'pointer',
  },
  root_field: {
    dispay:'inline-flex',
    flex:1,
    minWidth:'100px',
  },
  rootOpen: {
      background: theme.palette.hoverColor,
      color: theme.darkBlack,
      fontWeight: 600,
    /*  '&:hover:not($disabled):not($selected):not($checked)':{
        background: theme.palette.hoverColor
      }*/
  },
  select: {
    padding:`${theme.margins.xs}px ${theme.margins.xs}px ${theme.margins.xs}px 0`,
  //  display:'inline-block',
    width:'100%',
    '&:focus': {
      background: 'transparent'
    }
  },
  select_field: {
    padding:0
  },
  disable_padding_right: {
    padding: `${theme.spacing.sm}px 0`
  },
  setBold: {
    fontWeight:theme.fontWeight.bold,
  },
  afterFocus: {
    background: theme.palette.hoverColor//'rgba(0, 0, 0, 0.05)'//theme.palette.primary.contrustText
  },
  paper_class_with_full_size_on_small_screens: {
    width:'100%',
    maxWidth: '100%',
    transform:'translateX(16px) !important',
    [theme.breakpoints.up('sm')]: {
      transform:'translateX(-46px) !important',
      maxWidth:'300px'
    }
  },
  paper_class_with_full_size_on_small_screens_right: {
    width: 'calc(100% - 48px)',
    marginLeft:-16,
    [theme.breakpoints.up('sm')]: {
      ...paper_class_right
    }
  },
  paper_class,
  paper_class_right,
  paper_class_no_adornment,
  paper_class_no_adornment_right,
  listItemText: {
    padding:`0 ${theme.margins.xs}px`,
    [theme.breakpoints.up('sm')]: {
      padding:`0 ${theme.margins.sm}px`,
    }
  },
  set_icon_pos: {
    transform:'translateY(3px)'
  },
  menuItem: {
    padding:`${theme.spacing.md/2}px ${theme.margins.xs}px`,
    '&:hover:not($disabled):not($selected)':{
      background: theme.palette.hoverColor
    }
  },
  single_text_class: {
    justifySelf:'flex-end',

  },
  disable_margins: {
    margin:`0 ${theme.margins.xs}px 0 0`
  },
  disable_padding: {
    padding:0
  },
  selected: {},
  checked: {},
  disabled:{},
  focused: {},

  ////////////////////////

  input_field: {
    ...theme.typography.body1,
    color: theme.palette.black,
    padding:`4px 0`
  },
  underline: {
    '&:before':{
        borderBottom: `1px solid ${theme.palette.lightGrey}`
     },
    '&:hover:not($disabled):not($focused):not($error):before':{
        borderBottomColor: theme.palette.mediumGrey,
     },
    '&:after': {
        borderBottom: `1px solid ${theme.palette.deepGrey}`
     }
  },
  error: {}

});
