export const isObjectEmpty = obj => {
  return (
    !Object.getOwnPropertyNames(obj).length ||
    Boolean((
      Object.keys(obj).filter(
        (acc, cur) => {
          return (
            typeof obj[cur] === undefined ||
            obj[cur] === "undefined"
          );
        }).length
    ))
  )
}

export default (props) => {

  const { invite } = props;

  if (invite ) {
    const { property, name, email } = props;
    if (property && property !== "undefined" &&
        name && name !== "undefined" &&
        email && email !== "undefined"
    ) {
      return `?property=${property}&email=${email}&name=${name}`;
    }
    else { return ''; }
  }

  if (invite && !(isObjectEmpty(props.match.location.query))) {
    const {email, property, name} = props.match.location.query;
    if (invite && property && name && email) {
        return `?property=${property}&email=${email}&name=${name}`;
    }
  }
  else if (props.location.search){
      return props.location.search;
  }

  else if (props.location.params && (props.location.param[0] || props.location.param.token)) {
    return props.location.param[0] || props.location.param.token;
  }

  else return '';
}

export const convert_uri = (search) => {
  try {
    if (!search) { return {}; }

    //let searchURI = encodeURI(search);
    let search_array = decodeURI(search).slice(1).split(/\&(?=code)|\&(?=email)|\&(?=goto)/g), obj = {}
    if (!search_array.length) { return {}; }

    search_array.forEach(search => {
      const eq = search.indexOf('=');
      obj[search.slice(0,eq)] = search.slice(eq + 1);
    });

    return obj;
  }
  catch(e) {
    console.warn("Error in decodeURI (convert_uri function): ",e);
    return {}
  }

}
