import React from 'react';
import PropTypes from 'prop-types';

import withWidth from '@material-ui/core/withWidth';
import withStyles from '@material-ui/core/styles/withStyles';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';

import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import FixedTabContainer from './fixedTab';
import Icon from './Icon';
import Button from './Button';

const styles = theme => {
  return {

   option_class: {
     ...theme.typography.body1,
     fontSize:16,
     color: theme.palette.black,
     fontWeight:400,
     lineHeight:1,
     height:'100%',
     margin:0,
     cursor:'pointer',
     padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
     '&:hover, &.active :hover': {
       textDecoration:"none",
       color: theme.palette.black,
     },
     [theme.breakpoints.up('md')]: {
       padding: `${theme.margins.xl}px ${theme.margins.xs}px ${theme.margins.xl}px ${theme.margins.md}px`,
     }
   },
   option_class_links: {
     color: theme.palette.deepGrey
   },
   fit_container: {
     transform:`translateX(-14px)`// material-ui has 12px padding on icon buttons
   },
   streighten_menu_icon: {
     transform:`translateX(-12px)`,
   },
   set_icon_width: {
     textDecoration:'none'
   },
   breadcrumb_class: {
     transform:'translateX(-26px)',
     marginRight:-26
   },
   single_breadscrum: {
     marginLeft: theme.margins.sm
   },
   right_section_class: {
     marginLeft:'auto'
   }
  }
}



const TabComponent = withStyles(styles)(({
  toggleDrawer,
  pathnames,
  link_component,
  width,
  right_section,
  classes: {
    fit_container,
    streighten_menu_icon,
    set_icon_width,
    breadcrumb_class,
    option_class,
    option_class_links,
    single_breadscrum,
    right_section_class
  },
}) => {
  const is_breadcrumbs =  Boolean(pathnames && pathnames.length) && typeof pathnames === "object";
  const breadcrumbs_xs = Boolean(is_breadcrumbs) && width === "xs";
  const is_string      = Boolean(pathnames) && typeof pathnames === "string";
  const last_path_index = pathnames.length - 1;
  const find_path = index => breadcrumbs_xs && pathnames[index];

  return (
      <React.Fragment>
          <Button
            variant = "icon"
            className = {fit_container}
            component = { breadcrumbs_xs ? link_component : undefined }
            to = { breadcrumbs_xs ? find_path(last_path_index - 1).to : undefined}
            onClick = { breadcrumbs_xs ? undefined : e => toggleDrawer(true) }
            icon_path = {breadcrumbs_xs ? "icon-left-arrow" : "icon-menu-8"}
            size = "md"
          />
        <Grid container alignItems = "center" className = {breadcrumb_class}>
        {
          breadcrumbs_xs ?  <Typography
                              variant = "body1"
                              key = {'top_nav_last_phrase'}
                              className = {option_class}
                            >
                            { find_path(last_path_index).label }
                          </Typography> : null
        }
        {/*className = {set_icon_width + (breadcrumbs_xs ? '' : ` ${fix_menu_pos}`)}*/}
        {
          is_string ?
           <Typography variant = "body1" className = {option_class}>
             {pathnames}
          </Typography> : null
        }
        {
          (is_breadcrumbs && !breadcrumbs_xs) ?
          pathnames.map(({ to, label }, index) => {
            return index === pathnames.length - 1 ? (
              <Typography
                variant = "body1"
                key = {label+index}
                className = {option_class}
                key={to}
              >
                {label}
              </Typography>
            ) : (
              <React.Fragment key = {label+index}>
                <Link
                  underline = "none"
                  variant = "body1"
                  className = {option_class + ' ' + option_class_links}
                  component={link_component}
                  to={to}
                  key={to}
                >
                  {label}
                </Link>
                <Icon  type = "icon-right-arrow" size = "xs"  />
              </React.Fragment>
            )
          }): null
        }
        {
          Boolean(right_section)
          ? <Grid item className = {right_section_class}>
              {right_section}
            </Grid>
          : null
        }
        </Grid>
    </React.Fragment>
);
});


const nav_styles = theme => ({
  bar: {
   marginTop:0,
   height:50,
   flexDirection:'row',
   alignItems:'center',
   top:0,
   background:theme.palette.primary.contrastText,
   padding: `0 ${theme.margins.xs}px 0 ${theme.margins.xs}px`, // material-ui paddings are 12px + 2px difference from rem to px
   [theme.breakpoints.up('md')]: {
     padding: `0 ${theme.margins.xs}px 0 ${theme.margins.md}px`,
   },
   color: theme.palette.secondary.main,
 },
})


class TopNav extends React.Component {

    render() {
      const {
        classes,
        no_shadow,
        no_border,
        ...other_props
      } = this.props;
      const { link_component, ...fixed_tab_props} = this.props;
      return (
        <FixedTabContainer
          tab_component = {<TabComponent link_component= {link_component} { ...other_props }/>}
          {...fixed_tab_props}
        />
      );
    }
}


TopNav.propTypes = {
  container_class: PropTypes.string,
  pathnames:PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired
    }))
  ]),
  classes: PropTypes.shape({
    bar: PropTypes.string.isRequired
  }),
  no_shadow: PropTypes.bool,
  no_border: PropTypes.bool,
  TabComponent: PropTypes.func,
  children: React.isValidElement,
  width: PropTypes.string.isRequired
}

export default withWidth()(withStyles(nav_styles)(TopNav));

/*
<IconButton
  className = {fit_container}
  aria-label="Menu"
  component = { breadcrumbs_xs ? link_component : undefined }
  to = { breadcrumbs_xs ? last_path.to : undefined}
  onClick = { breadcrumbs_xs ? undefined : e => toggleDrawer(true) }
>
<Icon type = { breadcrumbs_xs ? "icon-left-arrow" : "icon-menu-8"}  size = "sm" className = {set_icon_width}/>
</IconButton>
*/
