// ** the icon adronment has 8px margin right **
export const ICON_ADDORNMENT_MARGINS = 8;
export const MENU_INPUT_PADDINGS = 16;

export const ITEM_HEIGHT = 40;
export const ITEM_PADDING_TOP = 40;

export const GREY_FOCUSED_INPUT = 'rgba(0, 0, 0, 0.05)';
export const ICON_PADDING = 32;

export const analyze_icon_size = size => ({ xs: 12, sm: 16, md: 20, lg: 24, xl: 32 }[size]);

export default ({
  paper_class,
  direction = "left",
  ...other_props
}) => {

  return {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        minWidth: direction === "right" ? 112 : undefined,
        background: "white",
        borderTop:'2px solid #f8f8f8',
        boxShadow: "0px 4px 5px #eee",
        border: `1px solid #EEE`,
        ...other_props.style
      }
    },
    classes: {
      paper: paper_class
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: direction,
      ...other_props.anchorOrigin
    },
    transformOrigin: {
      vertical: "top",
      horizontal: direction,
      ...other_props.transformOrigin
    },
    disableAutoFocusItem:true
  };
}
