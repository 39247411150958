import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import List from '@material-ui/core/List';
import NavListItem from "./NavListItem";

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

const drawer_width = 300;

const styles = theme => ({
  listItem: {
    display:"flex", 
    justifyContent:"center",
    padding:"1rem",
    lineHeight:1,
    cursor:'pointer'
  },
  nested: {
    paddingLeft:0,
    '&:hover': {
      // background: theme.palette.lightGrey,
    },
  },
  textItem: {
    color: "#333",
    fontWeight: theme.fontWeight.main,
    textOverflow: 'ellipsis',
    overflow:'hidden',
    whiteSpace: 'nowrap',
    cursor:'pointer',
  },
  nestedText: {
    color: theme.palette.black,
    fontWeight: theme.fontWeight.main,
    cursor:'pointer',
    textOverflow: 'ellipsis',
    overflow:'hidden',
    whiteSpace: 'nowrap',
  },
  remove_padding: {
    paddingLeft:'52px !important'
  },
  selected: {
    fontWeight: theme.fontWeight.bold,
  },
  list: {
    paddingBottom:0,
  },
  icon_class_container: {
    margin: `0 0 -2px 0`,
  },
  drop_down_icon_open: {
    webkitTransform: "rotate(90deg)",
    transform: "rotate(90deg)",
  },
  drop_down_icon_close: {
    webkitTransform: "rotate(0deg)",
    transform: "rotate(0deg)",
  }
})


const NavListWithCollapse = ({
        classes: {
          icon_class_container,
          listItem,
          nested,
          textItem,
          nestedText,
          selected,
          list,
          drop_down_icon_open,
          drop_down_icon_close,
          remove_padding
        },
        link_component,
        is_online,
        drop_down_icon,
        menu_open,
        menu,
        pathname,
        onClose,
        ...other
}) => {
    const [data_open, set_data] = React.useState({})
    const [iconColor, setIconColor] = React.useState("#333")
    const [itemSelected, setItemSelected] = React.useState("Apps")

    const setSelectedItem = (title) =>{
      setItemSelected(title)
    }

    const is_parent = children => Boolean(children) && Boolean(children.length);
    const create_path = (path = '/') =>  Boolean(path) && /^\//.test(path) ? path : Boolean(path) ? '/' + path : '/';

    const open_data = property => set_data({ ...data_open, [property]: !data_open[property] });


    const check_if_selected = (children, path, pathname) => {

      if (!Boolean(children && children.length)) {
        switch(typeof path) {
          case "string":
            return path === "/" ? pathname === path : new RegExp(`^/?${path}$`).test(pathname);
          case "object":
            if (Array.isArray(path)) {
              return pathname === "/" ? path.includes(pathname) : Boolean(path.find(o => new RegExp(`^/?${o}$`).test(pathname)));
            }
            console.error(`Expected path prop to be either a string, an array or function but received ${typeof path}`);
            return false;
          case "function":
            return path(pathname, children);
          default:
            console.error(`Expected path prop to be either a string, an array or function but received ${typeof path}`);
            return false;
        }
      }
    };


    return (
            <List className = {list}>
            {
             Boolean(menu && menu.length) ?
               menu.map(({
                 to: parent_to,
                 path: parent_path,
                 icon_type,
                 title,
                 disabled,
                 disabled_when_offline,
                 children
               }, index
             ) =>  {
               const parent_selected = check_if_selected(children, parent_path, pathname);

               return (
                 <NavListItem 
                    is_parent={is_parent}
                    create_path={create_path}
                    open_data={open_data}
                    children={children}
                    disabled_when_offline={disabled_when_offline}
                    disabled={disabled}
                    title={title}
                    parent_to={parent_to}
                    remove_padding={remove_padding}
                    drop_down_icon_open={drop_down_icon_open}
                    drop_down_icon_close={drop_down_icon_close}
                    icon_class_container = {icon_class_container}
                    listItem = {listItem}
                    nested={nested}
                    textItem = {textItem}
                    nestedText = {nestedText}
                    selected = {selected}
                    icon_type = {icon_type}
                    pathname = {pathname}
                    menu_open = {menu_open}
                    itemSelected = {itemSelected}
                    check_if_selected = {check_if_selected}
                    setSelectedItem = {setSelectedItem}
                    onClose={onClose}
                    is_online={is_online}
                    link_component={link_component}
                />
        
               )
             }
          ) : null
        }
      </List>
    );
  }



const isValidComponent = (props, propName, name) => {
    if (props[propName] && !React.isValidElement(props[propName]) && !(typeof props[propName] === "string")) {
      return new Error(
        //`Invalid prop 'component' supplied to '${name}': the prop is not a valid React component`
        `Expect prop '${name}' to be either a string or a valid react component. Got ${typeof props[propName]}.`
      );
    }
}

NavListWithCollapse.propTypes = {
  classes: PropTypes.shape({
    icon_class_container: PropTypes.string.isRequired,
    listItem:PropTypes.string.isRequired,
    nested:PropTypes.string.isRequired,
    textItem:PropTypes.string.isRequired,
    nestedText:PropTypes.string.isRequired,
    selected:PropTypes.string.isRequired,
    list:PropTypes.string.isRequired,
  }),

  link_component: PropTypes.oneOfType([PropTypes.string, PropTypes.func,PropTypes.object]),//(props, propName) => isValidComponent(props, propName, 'link_component'),//PropTypes.oneOfType([PropTypes.string, isValidReactEl(props, propName, 'link_component')]),//),

  is_online: PropTypes.bool,

  drop_down_icon: PropTypes.string,//(props, propName) => isValidComponent(props, propName, 'drop_down_icon'),//PropTypes.oneOfType([PropTypes.string, isValidReactEl(props, propName, 'drop_down_icon')]),

  menu_open: PropTypes.bool,

  onClose: PropTypes.func,

  menu: PropTypes.arrayOf(PropTypes.shape({
    to: PropTypes.string,
    path: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.array ]),
    icon_type: PropTypes.string,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    disabled_when_offline: PropTypes.bool,
    children: PropTypes.arrayOf(PropTypes.shape({
      to: PropTypes.string,
      path: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.array ]),
      title: PropTypes.string,
      disabled: PropTypes.bool,
      disabled_when_offline: PropTypes.bool,
    }))
  })),


  onClose: PropTypes.func.isRequired,

  pathname: PropTypes.string.isRequired

}

NavListWithCollapse.defaultProps = {

  link_component: 'div',

  is_online: true,

  drop_down_icon: "icon-right-arrow",

  menu_open: false,

}

export default withStyles(styles)(NavListWithCollapse);
