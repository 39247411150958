import {findGotoPath} from '../../utils/auth_functions';

function getCookies() {
  return document.cookie.split(';').reduce(
    (cookies, cookie) => {
        const [name, val] = cookie.split('=')
                              .map(c => c.trim());
        cookies[name] = val;
        return cookies;
    }, {});
}

function handleError(error) {
  const code = (error.code && error.code + ": ") || "";
  const msg = error.message || error;
  const name = error.name || "Frontend error";

  return {
    statusMessage: code + msg + " (" + name + ")",
    statusCode: 500,
  }
}

const common = (appSection, action, user_email, other) => ({
  signal: {
      type: "system_alert"
  },
  app: {
    appName: "Accounts",
    appSection,
    appUrl: window.location.href,
    appEnvironment: process.env.REACT_APP_env
  },
  actionExtensions: {
      activity: action,
      parameter: {
        email: user_email,
        other: JSON.stringify({
          goto: findGotoPath('app').goto,
          ...other
        }, null, 2)
      }
  }
});

export function error(user_email, appSection, error, action) {

  return {
      clientMutationId: `${appSection}-errorLog`,
      severity: 500,
      statisticsExtensions: {
        alertStatus: {
          ...handleError(error),
          devNote: JSON.stringify({
            email: user_email,
            cookies: getCookies()
          }, null, 2)
        }
      },
      ...common(
        appSection,
        action,
        user_email
      )
  }
}

export function info(user_email, appSection, action, other) {

  return {
      clientMutationId: `${appSection}-infoLog`,
      severity: 200,
      statisticsExtensions: {
        alertStatus: {
          statusMessage: "success",
          statusCode: 200,
          devNote: JSON.stringify({
            email: user_email,
            goto: findGotoPath('app').goto,
          }, null, 2)
        }
      },
      ...common(
        appSection,
        action,
        user_email,
        other
      )
  }
}

export default {
  error,
  info
}
