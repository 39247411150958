import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const NewLogo = () => (
  <SvgIcon viewBox="0 0 64 64" style={{ width: 64, height: 64, fill: "none" }}>
    <rect width="64" height="64" fill="#26BBED" />
    <path
      d="M17.5075 36.7245H17.0969C16.5103 36.7245 16.217 36.3978 16.217 35.7444V24.3291C16.217 22.5611 15.4545 21.677 13.9294 21.677H11.8177C10.1361 21.677 9.29536 22.5226 9.29536 24.2138V35.7444C9.29536 36.3978 9.00206 36.7245 8.41548 36.7245H8.00487C7.69203 36.7245 7.4574 36.6092 7.30097 36.3786C7.18366 36.148 7.125 35.9366 7.125 35.7444V14.3551C7.125 14.1245 7.18366 13.9131 7.30097 13.7209C7.4574 13.4903 7.69203 13.375 8.00487 13.375H8.41548C9.00206 13.375 9.29536 13.7017 9.29536 14.3551V19.9474C10.1948 19.5631 11.0356 19.3709 11.8177 19.3709H13.9294C15.298 19.3709 16.393 19.8129 17.2142 20.6969C18.0354 21.5425 18.446 22.7532 18.446 24.3291V35.7444C18.446 36.3978 18.1332 36.7245 17.5075 36.7245Z"
      fill="white"
    />
    <path
      d="M29.4 35.4561C29.0872 36.3786 28.8134 37.1473 28.5788 37.7622C28.3442 38.3772 28.1291 38.8384 27.9336 39.1459C26.9168 40.7986 25.5481 41.625 23.8275 41.625H23.0649C22.8303 41.625 22.5957 41.5481 22.361 41.3944C22.1264 41.2791 22.0091 41.0293 22.0091 40.6449V40.299C22.0091 39.9915 22.1068 39.7417 22.3024 39.5495C22.4979 39.3957 22.7521 39.3189 23.0649 39.3189H23.8275C24.7269 39.3189 25.3917 38.973 25.8219 38.2811C26.252 37.5893 26.604 36.8398 26.8777 36.0327C26.0956 33.8418 25.2353 31.4204 24.2967 28.7684C23.3582 26.1163 22.4588 23.5219 21.5985 20.9852C21.5203 20.793 21.5203 20.5432 21.5985 20.2357C21.7158 19.9282 21.97 19.7745 22.361 19.7745H22.8303C23.1822 19.7745 23.456 19.9475 23.6515 20.2934C23.7297 20.4471 23.7688 20.524 23.7688 20.524L27.9336 32.7464L30.6318 24.848L32.0983 20.524C32.2938 20.0243 32.6262 19.7745 33.0955 19.7745H33.5061C33.9362 19.7745 34.1904 19.9475 34.2686 20.2934C34.3469 20.6009 34.3664 20.8507 34.3273 21.0429L29.4 35.4561Z"
      fill="white"
    />
    <path
      d="M38.7184 36.7245H38.3078C37.995 36.7245 37.7603 36.6092 37.6039 36.3786C37.4866 36.148 37.4279 35.9366 37.4279 35.7444V14.3551C37.4279 14.1245 37.4866 13.9131 37.6039 13.7209C37.7603 13.4903 37.995 13.375 38.3078 13.375H38.7184C39.3441 13.375 39.657 13.7017 39.657 14.3551V35.7444C39.657 36.3978 39.3441 36.7245 38.7184 36.7245Z"
      fill="white"
    />
    <path
      d="M51.0532 35.4561C50.7403 36.3786 50.4666 37.1473 50.2319 37.7622C49.9973 38.3772 49.7822 38.8384 49.5867 39.1459C48.57 40.7986 47.2013 41.625 45.4806 41.625H44.7181C44.4834 41.625 44.2488 41.5481 44.0142 41.3944C43.7795 41.2791 43.6622 41.0293 43.6622 40.6449V40.299C43.6622 39.9915 43.76 39.7417 43.9555 39.5495C44.151 39.3957 44.4052 39.3189 44.7181 39.3189H45.4806C46.38 39.3189 47.0448 38.973 47.475 38.2811C47.9052 37.5893 48.2571 36.8398 48.5308 36.0327C47.7487 33.8418 46.8884 31.4204 45.9499 28.7684C45.0114 26.1163 44.1119 23.5219 43.2516 20.9852C43.1734 20.793 43.1734 20.5432 43.2516 20.2357C43.3689 19.9282 43.6231 19.7745 44.0142 19.7745H44.4834C44.8354 19.7745 45.1091 19.9475 45.3046 20.2934C45.3829 20.4471 45.422 20.524 45.422 20.524L49.5867 32.7464L52.285 24.848L53.7514 20.524C53.947 20.0243 54.2794 19.7745 54.7486 19.7745H55.1592C55.5894 19.7745 55.8436 19.9475 55.9218 20.2934C56 20.6009 56.0196 20.8507 55.9804 21.0429L51.0532 35.4561Z"
      fill="white"
    />
    <path
      d="M44.5149 53.1253C44.3999 53.1253 44.3137 53.0678 44.2562 52.9528C44.1987 52.8186 44.1699 52.694 44.1699 52.579V50.9978C44.1699 50.8828 44.1987 50.7678 44.2562 50.6528C44.3137 50.5186 44.3999 50.4515 44.5149 50.4515H45.0899C45.2049 50.4515 45.2912 50.5186 45.3487 50.6528C45.4062 50.7678 45.4349 50.8828 45.4349 50.9978V52.579C45.4349 52.694 45.4062 52.8186 45.3487 52.9528C45.2912 53.0678 45.2049 53.1253 45.0899 53.1253H44.5149Z"
      fill="white"
    />
    <path
      d="M52.9467 53.1253C52.8134 53.1253 52.7134 53.0786 52.6467 52.9853C52.5801 52.8919 52.5334 52.8119 52.5067 52.7453L51.9467 51.0453H49.3067L48.7067 52.7853C48.6801 52.8786 48.6267 52.9586 48.5467 53.0253C48.4667 53.0919 48.3734 53.1253 48.2667 53.1253H48.1267C47.9534 53.1253 47.8334 53.0586 47.7667 52.9253C47.7001 52.7919 47.6867 52.6719 47.7267 52.5653L50.0467 45.4053C50.0734 45.352 50.1134 45.2786 50.1667 45.1853C50.2334 45.092 50.3401 45.0453 50.4867 45.0453H50.7467C50.9734 45.0453 51.1267 45.1586 51.2067 45.3853L53.5267 52.5653C53.5667 52.6719 53.5534 52.7919 53.4867 52.9253C53.4201 53.0586 53.3001 53.1253 53.1267 53.1253H52.9467ZM50.6067 46.8653C50.5401 47.1053 50.4601 47.372 50.3667 47.6653C50.2867 47.9586 50.2001 48.2453 50.1067 48.5253C50.0267 48.8053 49.9467 49.072 49.8667 49.3253C49.7867 49.5786 49.7201 49.7919 49.6667 49.9653H51.5867L50.6067 46.8653Z"
      fill="white"
    />
    <path
      d="M55.253 53.1253C55.1063 53.1253 54.9997 53.0719 54.933 52.9653C54.8797 52.8586 54.853 52.7586 54.853 52.6653V45.5053C54.853 45.3986 54.8797 45.2986 54.933 45.2053C54.9997 45.0986 55.1063 45.0453 55.253 45.0453H55.413C55.693 45.0453 55.833 45.1986 55.833 45.5053V52.6653C55.833 52.9719 55.693 53.1253 55.413 53.1253H55.253Z"
      fill="white"
    />
  </SvgIcon>
);

export default NewLogo;
