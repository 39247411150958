import React from 'react';
import PropTypes from 'prop-types';
import {createFragmentContainer} from 'react-relay';

import graphql from 'babel-plugin-relay/macro';

import withWidth from '@material-ui/core/withWidth';
import withStyles from '@material-ui/core/styles/withStyles';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';

import NavContent from './NavContent';

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

const drawer_width = 300;
const drawerClosedWidth = 80;

const styles = theme => ({
  drawer: {
    width: drawer_width,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawer_width,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: drawerClosedWidth,
    border:'none',
    borderRight:"1px solid #DADADA"
  },

  container: {
    width:`calc(100% - ${drawerClosedWidth}px)`,
    margin:0,
    marginLeft: 80,
    background:theme.palette.white,
    transition: theme.transitions.create(['width','margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  },
  container_open: {
    marginLeft: drawer_width,
    width: `calc(100% - ${drawer_width}px)`,
    transition: theme.transitions.create(['width','margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

})

const add_slash_to_path = path => Boolean(path) && /^\//.test(path) ? path : Boolean(path) ? '/' + path : '/';


const handleResize = width => ({
    small_menu_open: false,
    big_menu_open: ['lg','xl'].includes(width),
    width
});


const NavDrawer = ({
  children,
  classes: {
    drawer,
    drawerClose,
    drawerOpen,
    container,
    container_open,
  },
  ...props
}) => {

  const [state, set] = React.useState(handleResize(props.width));

  React.useEffect(() => { if (props.width !== width) { set(handleResize(props.width)) }}, [props.width])

  function toggleDrawer () {
    const drawer = (props.width === 'lg' || props.width === 'xl')
          ? 'big_menu_open'
          : 'small_menu_open'

    set({ ...state, [drawer]: !state[drawer] });
  }

  const {
    big_menu_open,
    small_menu_open,
    width
  } = state;

  const drawer_class = drawer + ' ' + ( big_menu_open ? drawerOpen : drawerClose );
  const container_class = container + ' ' + (big_menu_open ? container_open : '');

  return (
    <>
      <div className = {`${container} ${container_class}`}>
        {
          React.cloneElement(
            children,
            {
              container_class,
              toggleDrawer
            }
          )
        }
      </div>
      <Hidden lgUp implementation="css">
         <SwipeableDrawer
           disableBackdropTransition={!iOS}
           disableDiscovery={iOS}
           variant="temporary"
           open={small_menu_open}
           onClose={e => toggleDrawer(false)  }
           onOpen={e => toggleDrawer(true)  }
        >
        <NavContent
          {...props}
          menu_open = {big_menu_open}
          onClose = { e => toggleDrawer(false)}
        />
        </SwipeableDrawer>
      </Hidden>
      <Drawer
        variant="permanent"
        open
        className = { drawer_class }
        classes = {{ paper: drawer_class }}
      >
      <NavContent
        {...props}
        menu_open = {big_menu_open}
        onClose = { e => toggleDrawer(false)}
      />
    </Drawer>
  </>
);
}

NavDrawer.propTypes = {
  classes: PropTypes.shape({
    drawer: PropTypes.string.isRequired,
    drawerClose: PropTypes.string.isRequired,
    drawerOpen: PropTypes.string.isRequired,
  }),
  children: React.isValidElement,
  pathname: PropTypes.string.isRequired
}

NavDrawer.defaultProps = {
  children: (<div></div>),
  pathname: '/'
}

export default React.memo(withWidth()(withStyles(styles)(NavDrawer)));
