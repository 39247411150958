import React from "react";
import environment from "./environment";
import { createFragmentContainer, QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Dashboard from "./dashboard";
import ErrorPage from "../../ErrorPage";
import Loader from "../../../components/loading";

const DashboardContainer = ({ token, cognito_apps }) => {
  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query DashboardQuery($orgId: ID, $propertyId: ID) {
          apps(orgId: $orgId, propertyId: $propertyId) {
            id
            name
            url
            authorized
            appOrHub
          }
        }
      `}
      variables={{
        orgId: "",
        propertyId: ""
      }}
      render={({ error, props }) => {
        if (error) {
          console.error("Error in Dashboard: ", error);
        }
        return error ? (
          <ErrorPage />
        ) : Boolean(props) ? (
          <Dashboard
            apps={props.apps}
            cognito_apps={cognito_apps}
            token={token}
          />
        ) : (
          <Loader />
        );
      }}
    />
  );
};

export default React.memo(DashboardContainer);
