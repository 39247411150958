import Loadable from 'react-loadable';
import Loading from '../../components/loading';

export function find_current_page_path (path) {
  switch(path) {
    case 'Dashboard':
        return () => import('./Dashboard');
    case 'password_change':
        return () => import('./password_change');
    default:
        return () => import('../ErrorPage');
  }
}

export default (path) => (
  Loadable({
    loader: find_current_page_path(path),
    loading: Loading
  })
);

export const LazyComp = (loader, delay) => (
  Loadable({
    loader,
    loading: Loading,
     delay: delay || 200
  })
)
