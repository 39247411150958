import React from 'react';
import ReactDOM from 'react-dom';
import { Auth } from 'aws-amplify';

import Loader from './app/components/loading';
import localStorage from "./app/components/localStorage";
import { Route, Switch } from "react-router-dom";

import {
    logout,
    getQueryStringValue
  } from './app/utils/auth_functions';

// the flow is
// hymail -> accounts.hyly.app?goto=hymail-ai (authenticate) ->
// accounts.hyly.ai?goto=hymail -> saml -> hymail.hyly.app

import {
    handleGoToCache,
    getCurrentUser,
    shortExpiration,
    isDev
} from './routes';

async function handleSAML(accounts_login_loop = 0) {
    localStorage.setItem('saml-redirect', 1, shortExpiration());
    localStorage.setItem('accounts-login-loop', accounts_login_loop + 1, shortExpiration());
    // ** comment out this line to test idp
    if (isDev) { window.location.reload(); return; }
    Auth.federatedSignIn({ provider: process.env.REACT_APP_SAML_PROVIDER });
}

function gotoAuthApp(goto = "accounts", route) {
    window.localStorage.removeItem('redirect-app-after-auth');
    let str = process.env.REACT_APP_ACCOUNTS_LINK;
    if (route) { str += ('/' + route); }
    if (goto) { str+= ('?goto=' + goto); }
    window.location.href =  str; 
}

const authRoutes = [
    'accounts', 
    'set-password', 
    'login', 
    'verify'
];

function accountsLoginLoop() {
    const accounts_login_loop = localStorage.getItem('accounts-login-loop');
  
    if (accounts_login_loop > 1) {
      localStorage.removeItem('saml-redirect');
      localStorage.removeItem('accounts-login-loop');
      return 0;
    }
  
    return accounts_login_loop;
  }

const AIComponent = ({ goto, alias, user }) => {

    React.useEffect(() => {
    
        async function checkSaml() {
            const accounts_login_loop   = accountsLoginLoop(),
                saml                    = localStorage.getItem('saml-redirect');
            if (!user && !saml) {
                if (alias && !["accounts", "myhyly"].includes(alias)) {
                    window.localStorage.setItem(
                        'redirect-app-after-auth', 
                        alias
                    )
                }
                handleSAML(accounts_login_loop);
                return;
            }       
            else {
                
                if (saml) {
                    localStorage.removeItem('saml-redirect');
                    localStorage.removeItem('accounts-login-loop');
                }

                if (user && alias && !authRoutes.includes(alias) ) {
                    window.localStorage.removeItem('redirect-app-after-auth');
                    window.location.href = goto;
                }

                else {
                    gotoAuthApp(alias);
                }       
            }
        }
        checkSaml();
      }, []);

      return null;
}

const AILogoutComponent = ({ user, alias }) => {
    const [loading]     = React.useState(true);
    const queryFrom     = getQueryStringValue('from');
        
    React.useEffect(
        () => {
            
            const logoutAI = async() =>{
                handleGoToCache();
                localStorage.setItem('query_from', queryFrom);
                await logout();
                window.location.reload();
            }

            if (user) {
                logoutAI();
            }
            else {
                window.localStorage.clear();
                const qf = queryFrom || localStorage.getItem('query_from'),
                 authRoute      = (qf == "myhyly" || alias == "myhyly")
                                    ? 'cognito_logout'
                                    : 'logout';
                gotoAuthApp(alias, authRoute);
            }
        }
    , [user, queryFrom]);

    if (loading) { return <Loader />; }
    return null;
}

// const AILogoutComponent = ({ goto, alias, user }) => {
//     React.useEffect(() => {
//         const logoutAI = async function () {
//             await logout();
//             return gotoAuthApp(alias, 'logout');       
//         }
//         logoutAI();
//     },[]);

//     return null;
// }

export default ({ goto, alias }) =>{
    const [loading, setLoading] = React.useState(true);
    const [user, setUser]       = React.useState(null);
    React.useEffect(() => {
        const setCurrentUser = async() => {
            const user = await getCurrentUser();
            setUser(user);
            setLoading(false);
        }
        setCurrentUser();
    },[]);

    if (loading) { return null; }

    return  (
        <>
        <Switch>
            <Route path="/logout">
                <AILogoutComponent user = {user} goto = {goto} alias = {alias}/>
            </Route>
            <Route path = "*">
                <AIComponent user = {user} goto = {goto} alias = {alias} />
            </Route>
        </Switch>    
        </>
    );
}