import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import { Field, getIn } from 'formik';

const FormikField = ({
      name:field,
      type: validation_type,
      Component,
      handleChange,
      handleBlur,
      required,
      variant,
      multiple,
      show_password,
      ...other
   }) => {

    const type = validation_type === "email"
                  ? "email"
                  : (/password/.test(validation_type) && !show_password)
                  ? "password"
                  : "text";

    const name_from_schema = useMemo(() => (type, field, required, variant) => {

      if (type === "address") { return `address__${field}`;}

      if (!(/^top$|^left$|^outlined$/.test(variant))) { return field; }

      const validation_type = Boolean(type) ? type : "text",
            required_suffix = Boolean(required) ? '__required' : '';

      return `${validation_type}__${field}${required_suffix}`;

    },[validation_type, field, required, variant])


    return (
      <Field type = {type} name = { name_from_schema(validation_type, field, required, variant) }>
        { props => {
            const {
                field: { name, value, onChange, onBlur },
                form: {
                  touched,
                  errors,
                  setErrors,
                  setStatus,
                  setFieldValue,
                  status
                }
            } = props;

            return (
              <Component
                  type = {type}
                  name = { name }
                  value = { value }
                  required = {required}
                  variant = {  variant }
                  multiple = {multiple}
                  onChange = {e => {
                      if (typeof handleChange === 'function') {
                        handleChange(e, props);
                      }
                      if (status) {
                        if (status.global) { delete status.global}
                        if (status[name]) { delete status[name]; }
                        if (status.show_errors && status.show_errors[name]) {
                          delete status.show_errors[name];
                        }
                        setStatus(status);
                      }
                       if (errors[name]){
                         delete errors[name];
                       setErrors(errors);
                      }
                      onChange(e);
                  }}
                  onBlur = {e => {
                    let s = status || {};
                    s.show_errors = s.show_errors || {};
                    s.show_errors[name] = true;

                    setStatus(s);
                    if (typeof handleBlur === "function") {
                      handleBlur(e, props);
                    }
                    onBlur(e);
                  }}
                  error = {
                    status && status[name]
                    ? status[name]
                    : (
                        name !== "password__current_password__required"
                        && status && status.password__current_password__required
                        && typeof status.password__current_password__required != "string"
                      )
                    ? ''
                    : touched[name] //&& status && status.show_errors && status.show_errors[name]
                    ? errors[name]
                    : ''
                    // validation_type === "address" && getIn(touched, name)
                    //   ? getIn(errors, name)
                    //   : status && status[name]
                    //   ? status[name]
                    //   : touched[name] && status && status.show_errors && status.show_errors[name]
                    //   ? errors[name]
                    //   : ''
                  }
                  {...props}
                  field = {field}
                  {...other}
                />
              )
          }
        }
    </Field>
  );
}

FormikField.propTypes = {
  field: PropTypes.string.isRequired,
  Component: React.isValidElement,
  handleChange: PropTypes.func
}

export default FormikField;
