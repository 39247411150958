import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '../../StorybookComponents/Typography';

import add_to_url from '../../../app/utils/handle_information_transfer';

import emailCheck from '../../images/email-check.png';

import {withRouter} from 'react-router-dom';

const styles = theme => ({
  container: {
    position:'absolute',
    top:0,
    left:0,
    bottom:0,
    flex:1
  },
  form: {
    width: '90%',
    maxWidth:'500px',
    minHeight:'400px',
    textAlign: 'center'
  },
  image: {
    width:'30vw',
    minWidth: 250
  },
  link: {
    cursor:'pointer',
  },
  coloured_link: {
    color: theme.palette.hylyBlue
  }
});

const linkStyle = {
  color:'#26BBED',
  cursor:'pointer'
}

const VerifyConfirmationPage = ({
  classes,
  resendCode,
  setSubmitting,
  setFieldValue,
  setErrors,
  values,
  from_outside,
  ...props
}) => {

   function resend_code (e) {
     return resendCode (
        values,
        {
          setSubmitting,
          setFieldValue,
          setErrors
        }
      );
   }

   function redirect_to_verify(e) {
     props.history.push('/verify');
     window.location.reload();
   }

   return (
      <Grid
        container
        direction = "column"
        justify="center"
        alignItems="center"
        className={classes.container}
      >
      <Grid
        container
        direction = "column"
        alignItems="center"
        className={classes.form}
      >
        <Typography type = 'body1'>
          Instructions to verify your email have been emailed to you.<br/> Please check your email.
        </Typography>

        <img
          src = {emailCheck}
          alt = 'verification email was sent'
          className = {classes.image}
        />
        <Typography
          color = "deepGrey"
          type = 'body1'
          className = {classes.link}
          onClick = { from_outside ? redirect_to_verify : resend_code }
        >
        Didn&#39;t receive an email?&nbsp;
        <span className = {classes.coloured_link}>
          {
            from_outside
            ? 'Click here to request another verification email'
            : 'Click here to resend the verification email'
          }
        </span>
      </Typography>
  </Grid>
</Grid>
);
}

const {object, bool,string, func} = PropTypes;

VerifyConfirmationPage.propTypes = {
  classes: object.isRequired,
  errors: object,
  isLoading: bool,
  resendCode: func.isRequired
};


export default withRouter(withStyles(styles)(VerifyConfirmationPage));
