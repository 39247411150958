import moment from "moment";

export const switcherNotify_link =
  "https://support.hy.ly/portal/kb/articles/navigating-the-new-hy-ly-app-switcher";

export const apps = [
  {
    icon: "icon-calendar-event-2",
    title: "hyTours",
    url: "https://hy.ly/tour-scheduler/"
  },
  {
    icon: "icon-edit-file",
    title: "hyForms",
    url: "https://my.hyly.us/mktg/tenants/1600476755109367157/landing_pages"
  },
  {
    icon: "icon-single-02",
    title: "hyCRM",
    url: "https://hy.ly/tour-scheduler/customer-relationship-management/"
  },
  {
    icon: "icon-chart-bar-32",
    title: "hyIQ",
    url: "https://hy.ly/multifamily-analytics/"
  },
  {
    icon: "icon-single-folded",
    title: "hySurvey",
    url: "https://hy.ly/property-comp-survey/"
  },
  {
    icon: "icon-event-confirm",
    title: "hyBlast",
    url: "https://hy.ly/agent-communications/"
  }
];

export const hubs = [
  {
    icon: "icon-home-2",
    title: "hyBeacon",
    url: "https://my.hyly.us/mktg/tenants/1600476755109367157/beacon/sources"
  },
  {
    icon: "icon-single-content-03",
    title: "Contacts",
    url: ""
  },
  {
    icon: "icon-apartment",
    title: "Sub-Orgs",
    url: ""
  },
  {
    icon: "icon-widget",
    title: "Automation",
    url: ""
  },
  {
    icon: "icon-folder-money",
    title: "Billing",
    url: ""
  },
  {
    icon: "icon-multiple",
    title: "Accounts",
    url: ""
  },
  {
    icon: "icon-phone",
    title: "Telephony",
    url: ""
  }
];

export const notAuthorized = [
  {
    icon: "icon-mail",
    title: "hyMail",
    subtitle: "Automated Multifamily Email",
    url: "https://hy.ly/multifamily-email/"
  },
  {
    icon: "icon-event-confirm",
    title: "hyBlast",
    subtitle: "Communications Made Easy",
    url: "https://hy.ly/agent-communications/"
  },
  {
    icon: "icon-sidebar",
    title: "hySite",
    subtitle: "Multifamily Website",
    url: "https://hy.ly/multifamily-website/"
  },
  {
    icon: "icon-layers-2",
    title: "hyALM",
    subtitle: "Autonomous Lifecycle Marketing",
    url: "https://hy.ly/multifamily-lifecycle-marketing/"
  },
  {
    icon: "icon-b-comment-2",
    title: "hyChat",
    subtitle: "Autonomous Facebook Chatterbot",
    url: "https://hy.ly/multifamily-facebook-messenger-bot/"
  }
];

export const appsAndHubs = [
  {
    id: "UHJvZHVjdC0xNTE5OTk0NTU0NDkxNTk1Njg5",
    name: "hyMail",
    url: "https://my.hyly.us/mktg/tenants/1510679501769740702/newsletters",
    authorized: true,
    appOrHub: "App",
    icon: "icon-mail"
  },
  {
    id: "UHJvZHVjdC0xNTE5OTk0NTQyMzgwOTc1NDQ5",
    name: "hyTours",
    url: "https://my.hyly.us/tours/1510679501769740702/setting/calendars",
    authorized: true,
    appOrHub: "App",
    icon: "icon-calendar-event-2"
  },
  {
    id: "UHJvZHVjdC0xNTMyMzM1MDU0NDE4Nzc0MjA4",
    name: "hyForms",
    url: "https://my.hyly.us/mktg/tenants/1510679501769740702/landing_pages",
    authorized: true,
    appOrHub: "App",
    icon: "icon-edit-file"
  },
  {
    id: "UHJvZHVjdC0xNTMzNDAwNzg0MDExMDY2Nzkw",
    name: "hyCRM",
    url: "https://my.hyly.us/crm/1510679501769740702/entries",
    authorized: true,
    appOrHub: "App",
    icon: "icon-single-02"
  },
  {
    id: "UHJvZHVjdC0xNTMyMzM1MDcxODczNjI1NTc5",
    name: "hySite",
    url: "https://my.hyly.us/sites/1515103520797991461/amenities",
    authorized: true,
    appOrHub: "App",
    icon: "icon-sidebar"
  },
  {
    id: "UHJvZHVjdC0xNTE5OTk0NTY0MTgyNjYyNDAy",
    name: "hyIQ",
    url: "https://my.hyly.us/iq/1510679501769740702/system_hymail_analytics",
    authorized: true,
    appOrHub: "App",
    icon: "icon-chart-bar-32"
  },
  {
    id: "UHJvZHVjdC0xNTk4NTI3NTU4NzMxMDU2NDYw",
    name: "hyBeacon",
    url: "https://my.hyly.us/mktg/tenants/1510679501769740702/beacon/sources",
    authorized: true,
    appOrHub: "App",
    icon: "icon-home-2"
  },
  {
    id: "UHJvZHVjdC0xNTIwMTUyMTI5MTMyMDczOTA2",
    name: "Contacts",
    url: "#",
    authorized: false,
    appOrHub: "Hub",
    icon: "icon-single-content-03"
  },
  {
    id: "UHJvZHVjdC0xNTMyMzM0NzUwNzAyOTgxMTU5",
    name: "Sub-Orgs",
    url: "https://my.hyly.us/sites/1510679501769740702/properties",
    authorized: true,
    appOrHub: "Hub",
    icon: "icon-apartment"
  },
  {
    id: "UHJvZHVjdC0xNTUzNjkzMTE4MDIxNjQwODYy",
    name: "Automation",
    url: "#",
    authorized: false,
    appOrHub: "Hub",
    icon: "icon-widget"
  },
  {
    id: "UHJvZHVjdC0xNTk4NTI3NTU5MzQ2NzEzODMw",
    name: "Billing",
    url: "#",
    authorized: false,
    appOrHub: "Hub",
    icon: "icon-folder-money"
  },
  {
    id: "UHJvZHVjdC0xNTIwMTUyMTQ4NjU4ODYwOTk0",
    name: "Accounts",
    url: process.env.REACT_APP_ACCOUNTS_LINK,
    authorized: true,
    appOrHub: "App",
    icon: "icon-multiple"
  },
  {
    id: "UHJvZHVjdC0xNTIwMTUyMTQ4NjU4ODYwOTk1",
    name: "hySurvey",
    url: process.env.REACT_APP_HYSURVEY_LINK,
    authorized: true,
    appOrHub: "App",
    icon: "icon-multiple"
  },
  {
    id: "UHJvZHVjdC0xNjExNTYyMDM0OTEzMTM1MDIw",
    name: "hyChat",
    url: "https://my.hyly.us/chat/1510679501769740702/integrations/facebook",
    authorized: true,
    appOrHub: "App",
    icon: "icon-b-comment-2"
  },
  {
    id: "UHJvZHVjdC0xNjM5MzI0NTIwMDY0MDkwOTc0",
    name: "hyBlast",
    url: "https://hyblast-stg.hyly.app",
    authorized: true,
    appOrHub: "App",
    icon: "icon-event-confirm"
  },
  {
    id: "UHJvZHVjdC0xNjUwNjk0NTE0MjUwMjE0MzIx",
    name: "hyALM",
    url: "https://hyalm-stg.hyly.app",
    authorized: true,
    appOrHub: "App",
    icon: "icon-layers-2"
  },
  {
    id: "UHJvZHVjdC0xNjQ5NDY4MjUyNjYwOTczNzYz",
    name: "Telephony",
    url: "https://my.hyly.us/crm/1510679501769740702/setting/sms_prompts",
    authorized: true,
    appOrHub: "Hub",
    icon: "icon-phone"
  },
  {
    appOrHub: "App",
    authorized: true,
    id: "UHJvZHVjdC0xNjg3NDQxNjgzMTkzNzU2MDM5",
    name: "hyEvents",
    url: "https://hyevents-stg.hyly.app",
    icon: "icon-calendar-date-2"
  }
];

export const setBanner = () => {
  if (
    localStorage.getItem("banner") &&
    localStorage.getItem("banner") !== "null"
  )
    return;
  var d = moment()
    .add(1, "M")
    .format("M/D/YYYY");
  localStorage.setItem("banner", d);
};

export const getBanner = () => {
  if (localStorage.getItem("banner")) {
    var c = moment().format("M/D/YYYY");
    var d = moment(localStorage.getItem("banner"));
    var diffDays = d.diff(c, "days");
    if (diffDays <= 0) return false;
    return true;
  } else {
    return false;
  }
};

export const setCurrentDate = () => {
  var c = moment().format("M/D/YYYY");
  localStorage.setItem("banner", c);
};
