import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Auth } from "aws-amplify";

import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
//import Button from '@material-ui/core/Button';

import Typography from "../../StorybookComponents/Typography";
import Button from "../../StorybookComponents/Button";
import HylyField, {
  create_validation
} from "../../StorybookComponents/HylyField";

import Logo from "../../StorybookComponents/hylyLogo";
import Loader from "../../components/loading";
import NotificationList from "../../StorybookComponents/Notification";
import { Formik } from "formik";
import userConfirmationMutation from "../../mutations/user_confirmation";

import AccountsStorage from "../../components/amplify_storage";
import localStorage from "../../components/localStorage";
import logger from '../../components/Logger';

import {
  login,
  confirmSignup,
  getAuthState,
  redirectAfterAuth,
  logout
} from "../../utils/auth_functions";

import { convert_uri } from "../../utils/handle_information_transfer";
import Icon from "../../StorybookComponents/Icon";


const Yup = require("yup");

const rEmail = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

const styles = theme => {
  return {
    container: {
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      flex: 1,
      background: "white"
    },
    form: {
      width: "90%",
      maxWidth: "350px",
      minHeight: "400px",
      textAlign: "center"
    },
    big_spacing: {
      marginTop: `${theme.margins.xl}px !important`
    },
    spacing: {
      marginTop: theme.margins.xs,
      [theme.breakpoints.up("md")]: {
        marginTop: theme.margins.md
      }
    },
    link: { cursor: "pointer" },
    eyeIcon: {
      cursor:"pointer"
    }
  };
};

let confirmation_state = true;
let notification_id = 0;
//let login_attempts = localStorage.getItem('login-attempts') || 1;

const limit_exceeded_notification = id => ({
  id: `limit-exceeded-reset-notification${id}`,
  type: "warning",
  body: "Attempt limit exceeded, please try after some time."
});

const Login = React.memo(
  withStyles(styles)(
    ({
      location,
      history,
      setSubmitting,
      setFieldValue,
      setStatus,
      group_id,
      invite_id,
      handleSubmit,
      classes,
      errors,
      status,
      set_notifications,
      isValid,
      dirty,
      ...props
    }) => {

      const [showPassword, setShowPassword] = useState(false);

      const togglePassword = () => {
        setShowPassword(!showPassword)
      }

      React.useEffect(() => {
        const confirm_account = async () => {
          let query = convert_uri(location.search) || {};
          if (query.code && query.email) {
            await confirmSignupCode(query.code, query.email.toLowerCase());
          }
        };
        confirm_account();
      }, [confirmation_state]);

      const confirmSignupCode = async (code, email) => {
        try {
          if (!/^[a-z0-9A-Z]+$/.test(code) || !rEmail.test(email)) {
            console.log(`wrong parameters were present in the url`);
            return history.replace("/login");
          }

          const user = await confirmSignup(email, code);
          if (user === "SUCCESS") {
            logger.info(email,"login","confirmSignupCode");

            set_notifications({
              id: "account-confirmed",
              type: "success",
              body: "Congratulations! You have confirmed your email"
            });
            history.replace("/login");
          }
        } catch (error) {
          logger.error(email,"login",error,"confirmSignupCode");

          if (error === "Code cannot be empty") {
            history.push("/verify");
            return;
          }
          const { code, message } = error;

          switch (code) {
            case "CodeMismatchException":
            case "InvalidParameterException":
            case "ExpiredCodeException":
              history.push("/verify");
              break;
            case "LimitExceededException":
            case "TooManyFailedAttemptsException":
            case "TooManyRequestsException":
              set_notifications(limit_exceeded_notification(notification_id++));
              break;
            case "UserNotFoundException":
            case "NotAuthorizedException":
            default:
              console.error(`Error in confirmSignup(): ${error}`);
              history.replace("/login");
          }
        }
      };

      return (
        <form onSubmit={handleSubmit}>
          <Grid
            container
            direction="column"
            className={classes.container}
            justify="center"
            alignItems="center"
          >
            <Grid
              container
              direction="column"
              className={classes.form}
              alignItems="center"
              wrap="nowrap"
            >
              <Logo />

              <Typography
                font_weight={500}
                className={classes.big_spacing}
                type="h3"
              >
                SIGN IN
              </Typography>

              <HylyField
                id="email"
                name="email"
                type="email"
                label="Email"
                required
                className={classes.big_spacing}
              />
              <HylyField
                id="password"
                name="password"
                type="password"
                label="Password"
                required
                show_password={showPassword}
                endAdornment={
                  <div onClick={togglePassword} className={classes.passwordToggle}>
                    <Icon type={`${!showPassword ? 'icon-preview' : 'icon-b-preview'}`} color="hylyBlue" className={classes.eyeIcon} />
                  </div>
                }
              />
              <Grid
                className={classes.big_spacing}
                container
                alignItems="center"
                justify="space-between"
              >
                <Typography
                  color="hylyBlue"
                  onClick={() => history.push("/set-password")}
                  className={classes.link}
                >
                  Forgot Password?
                </Typography>
                <Button
                  type="submit"
                  size="md"
                  variant="primary"
                  onClick={handleSubmit}
                  state={
                    !isValid || Boolean(status && status.global)
                      ? "inactive"
                      : undefined
                  }
                >
                  SIGN IN
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      );
    }
  )
);

Login.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  setSubmitting: PropTypes.func,
  setFieldValue: PropTypes.func,
  setStatus: PropTypes.func,
  group_id: PropTypes.string,
  invite_id: PropTypes.string,
  handleSubmit: PropTypes.func,
  classes: PropTypes.object,
  errors: PropTypes.object,
  status: PropTypes.object,
  values: PropTypes.object
};

const maxFailedAllowed  = 3;
let failedAttemps       = 0;


const FormikLoginComponent = ({
  location,
  isSubmitting,
  history,
  redirectAppAfterLogin,
  redirectAppAfterLoginAlias,
  userEmail,
  ...other
}) => {

  async function checkIfUserIsLoggedIn(email) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      return true;
    }
    catch(e) {
      return false
    }
  }

  async function unexpectedError() {
    other.set_notifications({
      id: `refresh-token-error-${notification_id++}`,
      type: "fail",
      body: "An unexpected error occured. Please try again."
    });
    // window.setTimeout(() => window.location.reload(), 1200);
  }



  async function handleSubmit(
    { email__email__required: email, password__password__required: password },
    { setFieldValue, setStatus, setFieldError, setSubmitting }
  ) {
    try {

      if (await checkIfUserIsLoggedIn()) {
        await redirectAfterAuth(redirectAppAfterLogin, redirectAppAfterLoginAlias);
        return;
      }

      if (+localStorage.getItem(`${email}-login-limit`) > 2) {
        history.push("/set-password");
        other.set_notifications({
          id: `login-attempts-error-${notification_id++}`,
          type: "warning",
          body:
            "Have you forgotten your username/password? Please reverify your email to set a new password."
        });
        return;
      }

      const user = await login(email.toLowerCase(), password);
      let redirect_path;

      localStorage.removeItem(`${email}-login-limit`);
      localStorage.removeItem("userEmail");

      if (localStorage.getItem("join-redirect")) {
        redirect_path = localStorage.getItem("join-redirect");
        localStorage.removeItem("join-redirect");
      } else {
        redirect_path = redirectAppAfterLogin;
      }
      await logger.infoPromise(email,"login","login");

      await redirectAfterAuth(redirectAppAfterLogin, redirectAppAfterLoginAlias);

    } catch (err) {

      logger.error(email,"login",err,"handleSubmit");
      localStorage.setItem(
        `${email}-login-limit`,
        +localStorage.getItem(`${email}-login-limit`) + 1
      );
      setSubmitting(false);
      let global_error;

      switch (err.code) {
        case "UserNotConfirmedException":
          history.push("/verify");
          break;
        case "PasswordResetRequiredException":
          history.push("/set-password");
          break;
        case "NotAuthorizedException":
          if (err.message == "Refresh Token has expired") {
            localStorage.removeItem(`${email}-login-limit`);
            await logout();
            if (failedAttemps < maxFailedAllowed) {
              failedAttemps++;
              // setSubmitting(true);
              await handleSubmit(
                { email__email__required: email, password__password__required: password },
                { setFieldValue, setStatus, setFieldError, setSubmitting }
              );
            }
            else {
              await unexpectedError();
            }

          }
          else {
            global_error =
              `There is no valid account with that email and password`;
          }

          break;
        default:
          global_error =
            `There is no valid account with that email and password`;
      }

      if (global_error) {
        setStatus({ global: global_error });

        other.set_notifications({
          id: `account-confirmed-error-${notification_id++}`,
          type: "fail",
          body: global_error
        });
      }
    }
  }

  if (isSubmitting) {
    return <Loader />;
  }

  const other_props = {
    location,
    isSubmitting,
    history,
    redirectAppAfterLogin,
    redirectAppAfterLoginAlias,
    ...other
  };

  return (
    <Formik
      initialValues={{
        email__email__required: userEmail,
        password__password__required: ""
      }}
      validationSchema={Yup.object().shape({
        email__email__required: Yup.string()
          .email("Please enter a correct email.")
          .required("Email is required"),
        password__password__required: Yup.string().required(
          "Password is required"
        )
      })}
      onSubmit={handleSubmit.bind(this)}
      validateOnBlur={true}
      validateOnChange={true}
      render={props => {
        return props.isSubmitting ? (
          <Loader />
        ) : (
            <Login
              redirectAppAfterLogin={redirectAppAfterLogin}
              redirectAppAfterLoginAlias = {redirectAppAfterLoginAlias}
              {...other_props}
              {...props}
            />
          );
      }}
    />
  );
};

const MyForm = withStyles(styles)(FormikLoginComponent);

export default class extends React.Component {
  state = { userEmail: "", checkFromCookies: false };

  componentDidMount() {
    this.setState({
      userEmail: localStorage.getItem("userEmail"),
      checkFromCookies: true
    });
  }

  render() {
    const { userEmail, checkFromCookies } = this.state;

    return userEmail || checkFromCookies ? (
      <MyForm {...this.props} userEmail={userEmail} />
    ) : (
        <Loader />
      );
  }
}
