import React, { Component, useRef, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import Icon from '../Icon';

import { useTransition, useChain, animated, config } from 'react-spring'

const styles = theme => ({
  root: {
    position:'fixed',
    left:0,
    top:0,
    backgroundColor:'transparent',
    zIndex:20000,
    pointerEvents:'none',
    transform:`translateY(${(theme.header_height-4)}px)`,
    [theme.breakpoints.up('md')]: {
      transform:`translateY(${theme.header_height - 4 + theme.margins.xs}px)`,
    }
  },
  subheader: {
    color:theme.palette.white,
    fontFamily: theme.openSans,
    fontSize:14,
    fontWeight: theme.fontWeight.bold,
    //lineHeight:1.5,
    padding:`auto ${theme.margins.xs}px`,

  },
  action: {
    color:theme.palette.white,
    fontWeight:600,
    cursor:'pointer',
    pointerEvents:'all',
    '&:hover': {
      background:'transparent'
    }
  },
  card_root: {
    boxSizing: 'border-box',
    position:'relative',
    width: `100vw`,
    maxWidth:500,
    boxShadow: "0px 4px 4px rgba(204, 204, 204, 0.25)",
    textAlign:'center',
    pointerEvents:'all',
  },
  ref_container_class: {
    padding:'4px 0'
  },
  animated_class: {
    boxSizing: 'border-box',
    position: 'relative',
    backgroundColor: "transparent",//theme.palette.white,
    overflow: 'hidden',
  //  padding:`8px 0`,
  },
  success: { backgroundColor: theme.palette.greenTeal},//"#66D184" },
  fail: { backgroundColor: theme.palette.red }, //"#FF5151" },
  warning: { backgroundColor: theme.palette.orange }//"#FF9900" },
});

// ** config ** //

const wait_before_delete = 3000;
let size = 0;

// *********** //

const NotificationItems = withStyles(styles)(({
  removeNotification,
  children,
  classes: {
    ref_container_class,
    not_first,
    subheader,
    root,
    card_root,
    action,
    animated_class,
    ...other
  },
}) => {
  const [refMap] = useState(() => new WeakMap())
  const [cancelMap] = useState(() => new WeakMap())
  const [items, setItems] = useState([]);


  const transitions = useTransition(items, item => item.key, {
    unique:true,
    from: { opacity: 0, height: 0, life: '100%' },
    enter: item => async (next) => {
      size += 1;
      await next({ opacity: 1, height: refMap.get(item).offsetHeight });
    },
    leave: item => async (next, cancel) => {
      cancelMap.set(item, cancel);
      await next({ life: '0%' });
      await next({ opacity: 0 });
      await next({ height: 0 });
    },
    onRest: async(item) => setItems(state => state.filter(i => i.key !== item.key)),
    onDestroyed: async(item, state) => {
      size = await Math.max(0, size - 1);
      if (Boolean(removeNotification)) {
        await removeNotification(item.id);
      }
    },
    config: (item, state) => {
      const n = Math.floor(size / items.length);
      return state === "leave" ? [{ duration: (wait_before_delete * (isFinite(n) ? n : size + 1))}, config.default, config.default] :config.gentle
    },
  })

  useEffect(() => void children( notification => (
    setItems(state => [...state, { key: notification.id, ...notification }])), [])
  )

  return (
        <Grid
          container
          direction = "column"
          className = {root}
          alignItems = "center"
       >
        {
          transitions.map(({
            key,
            item,
            props: {
              life,
              ...style
            }
          }, i) => {
            return (
             <animated.div
               key = {key}
               className = {animated_class}
               style = {style}
            >
               <div
                 ref = { ref => ref && refMap.set(item, ref )}
                 className = {ref_container_class}
                >
                 <Card className = {`${card_root} ${other[item.type]}`}>
                    <CardHeader
                        subheader= {
                          <React.Fragment>
                            <Typography varinat = "body2" className = {subheader}>
                              { item.body }
                            </Typography>
                          </React.Fragment>
                        }
                        action = {
                          <Icon
                            type = 'icon-e-remove'
                            className = {action}
                            onClick = { async(e) => {
                              e.stopPropagation()
                              await cancelMap.has(item) && cancelMap.get(item)()
                            }}
                          />
                        }
                        classes= {{
                          root: `${card_root} ${other[item.type]}`,
                          subheader
                        }}
                    />
                </Card>
                </div>
            </animated.div>
            )
          })

        }
    </Grid>
 )
});

NotificationItems.propTypes = {
	classes: PropTypes.shape({
    root:PropTypes.string.isRequired,
    subheader:PropTypes.string.isRequired,
    action:PropTypes.string.isRequired,
    card_root:PropTypes.string.isRequired,
    animated_class: PropTypes.string.isRequired,
    ref_container_class: PropTypes.string.isRequired,
    success:PropTypes.string.isRequired,
    fail:PropTypes.string.isRequired,
    warning:PropTypes.string.isRequired,
  }),
  removeNotification: PropTypes.func,
  children: PropTypes.func.isRequired
}

const state_notifications = new Set();

const NotificationList = ({
  notifications: props_notifications,
  removeNotification
}) => {

  const ref = React.createRef();

  const set_notifications = (add_notifications) => {
      for (let {id} of add_notifications) { state_notifications.add(id); }
  }

  async function add_notification_to_queue(notifications) {
    for (let noti of notifications) {
      await ref.current(noti);
    }
  }

  React.useEffect(() => {

    async function add_notification() {
      const new_notifications = props_notifications.filter(noti => {
        return Boolean(noti.id && !state_notifications.has(noti.id));
      });

      if (!Boolean(new_notifications.length)) { return; }

      await set_notifications(new_notifications);
      await add_notification_to_queue(new_notifications);
    }

    add_notification();

  }, [props_notifications])

    return (
      <NotificationItems
        children={ add_function => (ref.current = add_function) }
        removeNotification = {removeNotification}
      />
    )
}

NotificationList.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.oneOf(["success","fail","warning"]),
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  })),
  removeNotification: PropTypes.func
}

export default NotificationList;
