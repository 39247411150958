import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "../Typography";
import Button from "../Button";

const styles = () => ({
  banner: {
    background: "#E75F75",
    height: 32,
    width: "100%",
    padding: "4px 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  bannerText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  bannerAction: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    zIndex: 2000
  },
  buttonMargin: {
    margin: "0px 12px",
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.3
  },
  customButton: {
    borderRadius: "0px"
  }
});

class Banner extends React.Component {
  render() {
    const {
      classes,
      banner,
      laterButton,
      actionButton,
      handleLater,
      handleAction
    } = this.props;

    return (
      <div className={classes.banner}>
        <Typography
          type="body2"
          color="white"
          font_weight="500"
          className={classes.bannerText}
        >
          {banner}
        </Typography>
        <div className={classes.bannerAction}>
          <Button
            variant="secondary"
            size="sm"
            state="active"
            color="white"
            onClick={handleLater}
            className={classes.buttonMargin}
          >
            {laterButton}
          </Button>
          <Button
            variant="outlined"
            size="sm"
            state="active"
            color="white"
            onClick={handleAction}
            className={`${classes.buttonMargin} ${classes.customButton}`}
          >
            {actionButton}
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Banner);
