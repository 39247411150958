
import React from 'react';

const Yup = require('yup');

const regex = {
  price: /(:?^-?|\s)(?=.)((?:0|(?:[1-9](?:\d*|\d{0,2}(?:,\d{3})*)))?(?:\.\d*[1-9])?)((%|\$)?)(?!\S)/,
  phone: /^(\([0-9]{3}\) ?|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/,
  number: /^\d+$/,
  zip: /^\d{5}$/,
  password: /^(?=.*[a-z])(?=.*[a-z])(?=.*[0-9])(?=.{12,})/
}

function get_prefix_validation (field, errors) {
  const schema = Yup.string()
  switch(field) {
    case "email":
    case "url": return schema[field](errors[field]);
    case "phone":
    case "number":
    case "zip":
    case "password":
    case "price": return schema.matches(regex[field],errors[field]);
    case "size": return schema.matches(regex.number,errors.number);
    case "password_confirm": return schema.oneOf([Yup.ref("password__password__required")], errors[field])
    default: return schema;
  }
}

const default_errors = {
  email: "Please enter a correct email.",
  url: "Please enter a value in the format http://<domain>/<path>.",
  phone: "Please enter a value in the format (111)111-1111.",
  number: "Please enter a number.",
  price: "Please enter a price.",
  zip: "Please enter a 5 digit number for zip.",
  password: "Your password should be at least 12 characters long. It should contain at least one alphabetic character and one numeric character.",
  password_confirm: "Passwords must match",
  required: field => `${field.replace(/(^[a-z])/,o => o.toUpperCase())} is required.`,
}


export function create_address_validations(field, errors) {
  const required_error = c => errors[`required_${field}.${c}`] || (errors.required && errors.required(c));
  return Yup.object().shape({
      ...["street", "city","state"].reduce((a,c) => ({ ...a, [c]: Yup.string().trim().required(required_error(c)) }),{}),
      zip: Yup.string().matches(regex.zip, errors.zip)
  })
}

export function create_validation(fields, errors = default_errors) {
  const validation_errors = typeof errors === "function" ? errors(default_errors) : errors;

  return Yup.object().shape(
    fields.reduce((acc, field) => {

      let type, name, required_suffix;
      if (typeof field === "string") {
        [type, name, required_suffix] = field.split('__');
      }

      else if (typeof field === "object" && Object.hasOwnProperty.call(field, "type")) {
        type = field.type;
        name = field.name;
        required_suffix = field.required && 'required';
      }

    //  const [prefix, f, suffix] = field.split('__');
      const field_name = `${type}__${name}${Boolean(required_suffix)? `__${required_suffix}` : ""}`;

      if (name ==="current_password") {
        return {
          ...acc,
          [field_name]: Yup.string().trim().required(validation_errors.required('current_password'))
        }
      }

      if (type === "address") {
        return {
          ...acc,
          [field_name]: create_address_validations(field_name, validation_errors)
        }
      }

      const schema = get_prefix_validation(type, validation_errors);
      if (name === "password") {
        const additional_rule = "New password cannot be the same password as current password.";
        return {
          ...acc,
          [field_name]: schema.notOneOf([Yup.ref("password__current_password__required")], additional_rule).required(validation_errors.required('password__password__required'))
        }
      }

      return {
        ...acc,
        [field_name]: Boolean(required_suffix)
                  ? schema.trim().required(validation_errors[`required_${name}`] || (validation_errors.required && validation_errors.required(name)))
                  : schema
      }
    },{})
  )
}
