/* eslint-disable no-control-regex */
/* eslint-disable no-useless-escape */
import { Auth } from "aws-amplify";

export const maxFailedAllowed = 3;

export async function checkIfUserIsLoggedIn() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    if (user) return true;
    return false;
  } catch (err) {
    return false;
  }
}

export const welcomeMessages = {
  pai: {
    appName: "PAI",
    description1: "Multifamily",
    description2: "Reporting Made Easy.",
  },
  hyraf: {
    appName: "RAF",
    description1: "Multifamily",
    description2: "Because friends make the best neighbors.",
  },
  hyevents: {
    appName: "Events",
    description1: "Multifamily",
    description2: "Create happy moments.",
  },
  hycxp: {
    appName: "CXP",
    description1: "",
    description2: "Multifamily",
  },
  hytours: {
    appName: "Tours",
    description1: "Multifamily",
    description2:
      "Improve your experience. Show it off! A platform with style.",
  },
  hyblast: {
    appName: "Blast",
    description1: "Multifamily",
    description2: "Just 15 minutes. 15 Minutes to Blast off!",
  },
  hyalm: {
    appName: "ALM",
    description1: "Multifamily",
    description2: "Better results with better nurture.",
  },
  journey: {
    appName: "Journey",
    description1: "Multifamily",
    description2: "Communication Made Easy.",
  },
  "journey-ai": {
    appName: "Journey AI",
    description1: "Multifamily",
    description2: "Communication Made Easy.",
  },
  accounts: {
    appName: "hyly.ai",
    description1: "",
    description2: "Multifamily",
  },
  myhyly: {
    appName: "hyly.ai",
    description1: "",
    description2: "Multifamily",
  },
};

export const successNotification = (id) => ({
  id: `success-email-reset-notification${id}`,
  type: "warning",
  body:
    "Please check your email to reset password: If your email is valid, we have sent you instructions to reset your password.",
});

export const limitExceededNotification = (id) => ({
  id: `limit-exceeded-reset-notification${id}`,
  type: "warning",
  body: "Attempt limit exceeded, please try after some time.",
});

export const accountNotConfirmed = (id) => ({
  id: `account-not-confirmed-${id}`,
  type: "warning",
  body:
    "Your account is not confirmed yet. We have sent you an email to set your password.",
});

export const rEmail = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
