import {DateTime} from 'luxon';
import {aiOrApp} from '../utils/auth_functions';

const expiration_set = DateTime.local().setZone("utc").plus({ days: 7 }).toString();
const expiration_remove = DateTime.local().setZone("utc").minus({ years: 1 }).toString();

class Storage {
    // set item with the key
    static setItem(key, value) {
      document.cookie = "" + key + "=" + value
                        + ";Expires=" + new Date(expiration_set).toUTCString()
                        + ";Domain="+ process.env[`REACT_APP_domain_${aiOrApp}`]
                        + (process.env.NODE_ENV==="production" ? ";Secure" : "")
                        + ";Path=/";
      return Storage.getItem(key);
    }
    // get item with the key
    static getItem (key) {

      let name = key + "=";

      for(let cookie of decodeURIComponent(document.cookie).split(';')) {
        while (cookie.charAt(0) == ' ') {
          cookie = cookie.substring(1);
        }
        if (cookie.indexOf(name) == 0) {
          return cookie.substring(name.length, cookie.length);
        }
      }
      return "";
    }
    // remove item with the key
    static removeItem (key) {
      document.cookie = "" + key + "=;Expires="
                        + new Date(expiration_remove).toUTCString()
                        + ";Domain=" + process.env[`REACT_APP_domain_${aiOrApp}`]
                        + (process.env.NODE_ENV==="production" ? ";Secure" : "")
                        + ";Path=/";
    }
    // clear out the storage
    static clear () {
      document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
    }
}

Storage.workaround = null;

export default Storage;
