import React from "react";
import { Formik } from "formik";

import { LOGIN_SCHEMA } from "../common/validationSchema";
import * as utils from "../common/utils";
import Form from "./Form";

import Loader from "../../../components/loading";
import localStorage from "../../../components/localStorage";
import logger from "../../../components/Logger";
import {
  redirectAfterAuth,
  login,
  logout,
} from "../../../utils/auth_functions";

let failedAttemps = 0;

const LoginForm = ({
  userEmail,
  redirectAppAfterLogin,
  redirectAppAfterLoginAlias,
  history,
  location,
  ...rest
}) => {
  const [notificationId, setNotificationId] = React.useState(0);

  async function unexpectedError() {
    rest.set_notifications({
      id: `refresh-token-error-${notificationId + 1}`,
      type: "fail",
      body: "An unexpected error occured. Please try again.",
    });
    setNotificationId(notificationId + 1);
  }

  const handleSubmit = async (
    { email, password },
    { setFieldValue, setStatus, setFieldError, setSubmitting }
  ) => {
    try {
      if (await utils.checkIfUserIsLoggedIn()) {
        await redirectAfterAuth(
          redirectAppAfterLogin,
          redirectAppAfterLoginAlias
        );
        return;
      }

      if (+localStorage.getItem(`${email}-login-limit`) > 2) {
        history.push("/set-password");
        rest.set_notifications({
          id: `login-attempts-error-${notificationId + 1}`,
          type: "warning",
          body:
            "Have you forgotten your username/password? Please reverify your email to set a new password.",
        });
        setNotificationId(notificationId + 1);
        return;
      }

      await login(email.toLowerCase(), password);

      localStorage.removeItem(`${email}-login-limit`);
      localStorage.removeItem("userEmail");

      if (localStorage.getItem("join-redirect"))
        localStorage.removeItem("join-redirect");

      await logger.infoPromise(email, "login", "login");

      await redirectAfterAuth(
        redirectAppAfterLogin,
        redirectAppAfterLoginAlias
      );
    } catch (err) {
      logger.error(email, "login", err, "handleSubmit");

      localStorage.setItem(
        `${email}-login-limit`,
        +localStorage.getItem(`${email}-login-limit`) + 1
      );

      setSubmitting(false);

      let global_error;

      switch (err.code) {
        case "UserNotConfirmedException":
          history.push("/verify");
          break;
        case "PasswordResetRequiredException":
          history.push("/set-password");
          break;
        case "NotAuthorizedException":
          if (err.message === "Refresh Token has expired") {
            localStorage.removeItem(`${email}-login-limit`);
            await logout();

            if (failedAttemps < utils.maxFailedAllowed) {
              failedAttemps++;

              await handleSubmit(
                { email, password },
                { setFieldValue, setStatus, setFieldError, setSubmitting }
              );
            } else {
              await unexpectedError();
            }
          } else {
            global_error = `There is no valid account with that email and password`;
          }
          break;
        default:
          global_error = `There is no valid account with that email and password`;
      }

      if (global_error) {
        setStatus({ global: global_error });

        rest.set_notifications({
          id: `account-confirmed-error-${notificationId + 1}`,
          type: "fail",
          body: global_error,
        });
        setNotificationId(notificationId + 1);
      }
    }
  };

  return (
    <Formik
      initialValues={{ email: userEmail, password: "" }}
      validationSchema={LOGIN_SCHEMA}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={true}
    >
      {(props) => {
        if (props.isSubmitting) return <Loader />;
        return (
          <Form
            location={location}
            history={history}
            setNotifications={rest.set_notifications}
            notificationId={notificationId}
            setNotificationId={setNotificationId}
            {...props}
          />
        );
      }}
    </Formik>
  );
};

export default React.memo(LoginForm);
