import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '../../StorybookComponents/Typography';
//import {ReactComponent as ErrorImage} from '../images/hylyLogo.svg';

import {ReactComponent as ErrorImage} from '../../images/404-image.svg';
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  root: {
    position:'absolute',
    top:0,
    left:0,
    bottom:0,
  },
  flexShrink: {
    minWidth: 0,
    minHeight: 0,
    flexShrink: 1
  }
});

export default withStyles(styles)(({
  errors,
  isRoot,
  classes: {
    root,
    flexShrink,
  }
}) => {

  return (
    <Grid
      container
      direction = "column"
      alignItems = "center"
      justify = "center"
      className = {root}
    >
    <Grid item
      container
      direction = "row"
      justify = "center"
    >
        <Typography noWrap type = "h4" color = 'hylyBlue' >
        404
        </Typography>
        &nbsp;&nbsp;
        <Typography type = "body1">
          Page not found
        </Typography>
      </Grid>

      <Grid item className = {flexShrink} >
        <ErrorImage />
      </Grid>

      <Grid item>
        <Typography color = "deepGrey" variant = "body1">
          We are working to fix this problem, please try again soon.
        </Typography>
      </Grid>

    </Grid>
  )
})
/*
Array.isArray(errors) ?
  errors.map(error => (
      <Typography
          variant = "subtitle1"
          key = {error.message}
          color = "textSecondary"
       >
        { error.message || error }
      </Typography>
    )
  ) :
  <Typography color = "textSecondary">
    { errors.message || errors }
  </Typography>
*/
