import React from "react";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Expansion from "../../../StorybookComponents/Expansion";
import Loader from "../../../StorybookComponents/Loader";
import Icon from "../../../StorybookComponents/Icon";
import Typography from "../../../StorybookComponents/Typography";
import Button from "../../../StorybookComponents/Button";
import {
  appsAndHubs,
  switcherNotify_link,
  getBanner,
  setBanner,
  setCurrentDate,
} from "../../../dammy_data/appSwitcher";
import Banner from "../../../StorybookComponents/Banner";
import { globalTohyMail } from "../../../../common";

const styles = (theme) => ({
  root: {
    position: "relative",
    width: "100%",
    height: "calc(100vh - 50px)",
    overflowY: "scroll",
  },
  closeIcon: {
    position: "absolute",
    cursor: "pointer",
    right: 16,
    top: 16,
    zIndex: 1000,
    [theme.breakpoints.up("md")]: {
      right: 24,
      top: 24,
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    padding: 8,
    borderRadius: "50%",
    width: 40,
    height: 40,
    "&:hover": {
      background: "#f5f5f5",
    },
  },
  expansion: {
    display: "flex",
  },
  expansionBody: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  icon: {
    transform: "rotate(270deg)",
    marginRight: 8,
  },
  mediumApp: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    padding: 16,
    margin: 8,
    border: "1px solid #EEE",
    width: 220,
    height: 220,
    cursor: "pointer",
    "&:hover": {
      background: "#26bbed",
    },
    "&:hover $mediumAppIcon": {
      color: "#fff !important",
    },
    "&:hover $mediumAppTitle": {
      color: "#fff !important",
    },
    "&:hover $mediumAppSubTitle": {
      color: "#fff !important",
    },
  },
  smallApp: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    padding: 16,
    margin: 8,
    borderRadius: 20,
    width: 100,
    height: 100,
    cursor: "pointer",
    "&:hover": {
      background: "#26bbed",
    },
    "&:hover $smallAppIcon": {
      color: "#fff !important",
    },
    "&:hover $smallTitle": {
      color: "#fff !important",
    },
  },
  activeSmallApp: {
    border: "1px solid #999",
  },
  disableSmallApp: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    padding: 16,
    margin: 8,
    borderRadius: 20,
    width: 100,
    height: 100,
  },
  smallAppIcon: {
    margin: 8,
    width: 24,
    height: 24,
    fontSize: 24,
  },
  mediumAppIcon: {
    width: 48,
    height: 48,
    fontSize: 48,
    marginTop: 24,
  },
  mediumAppTitle: {
    margin: 16,
  },
  separator: {
    borderBottom: "1px solid #EEE",
    width: "20%",
  },
  smallTitle: {
    width: 90,
  },
  banner: {
    background: "#E75F75",
    padding: "8px 24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  bannerAction: {
    display: "flex",
    alignItems: "center",
  },
  bannerClose: {
    display: "flex",
    color: "#fff",
    cursor: "pointer !important",
    marginLeft: 20,
  },
  mediumAppSubTitle: {},
});

const SmallApp = ({ classes, icon, title, url, handleClick, active }) => (
  <div
    className={
      active
        ? `${classes.smallApp} ${classes.activeSmallApp}`
        : url && url.includes("http")
        ? `${classes.smallApp}`
        : `${classes.disableSmallApp}`
    }
    onClick={() => url && url.includes("http") && handleClick(url)}
  >
    <i className={`icon ${icon} ${classes.smallAppIcon}`} />
    <Typography type="body1" color="black" className={classes.smallTitle}>
      {title}
    </Typography>
  </div>
);

const MediumApp = ({ classes, icon, title, subtitle, url, handleClick }) => (
  <div className={classes.mediumApp} onClick={() => handleClick(url)}>
    <i className={`icon ${icon} ${classes.mediumAppIcon}`} />
    <Typography
      type="h4"
      color="black"
      font_weight="600"
      className={classes.mediumAppTitle}
    >
      {title}
    </Typography>
    <Typography
      type="body1"
      color="deepGrey"
      className={classes.mediumAppSubTitle}
    >
      {subtitle}
    </Typography>
  </div>
);

class AppSwitcher extends React.Component {
  constructor(props) {
    super(props);
    setBanner();
    this.state = {
      loading: true,
      activeApp: "Accounts",
      apps: "",
      hubs: "",
      notAuthorized: "",
      appSection: true,
      notAuthorizedSection: true,
      banner: getBanner(),
      journeyAIModulesSection: true,
    };
  }

  async componentDidMount() {
    var allApps = await this.props.apps;
    if (allApps) {
      var data = await this._loadApps(allApps);
      if (data) {
        var apps = data.filter(
          (d) => d.appOrHub === "App" && d.authorized === true
        );
        var hubs = data.filter(
          (d) => d.appOrHub === "Hub" && d.authorized === true
        );
        var notAuthorized = data.filter((d) => d.authorized === false);
        var journeyAIModules = data.filter((d) => d.journeyAIModules === true);
        this.setState({
          apps,
          hubs,
          notAuthorized,
          journeyAIModules,
          loading: false,
        });
      }
    }
  }

  handleClick = (url) => {
    if (/(^https:\/\/)(my|qa)(\.hy\.?ly)/.test(url)) {
      return window.open(
        `${url.replace(/\/$/, "")}?cognito_token=${this.props.token}`,
        "_blank"
      );
    } else {
      return window.open(`${url}`, "_blank");
    }
  };

  _loadApps = (allApps) => {
    let journeyAIModules = [];
    for (let i = 0; i < allApps.length; i++) {
      if (globalTohyMail[allApps[i].name]) {
        const { name } = globalTohyMail[allApps[i].name];
        if (allApps[i].authorized) {
          journeyAIModules.push({
            id: `journeyAIModules_${name}`,
            name: name === "Raf" ? "Refer-a-Friend" : name,
            title: name,
            icon: this.getIcon(allApps[i].name),
            isAccessibleToUser: allApps[i].access !== "none",
            authorized: allApps[i].authorized,
            journeyAIModules: true,
            url: allApps[i].url,
          });
        }
      }
    }

    var myApps = allApps.reduce((r, e) => {
      r.push({
        id: e.id,
        title: e.name,
        icon: this.getIcon(e.name),
        url: e.url,
        appOrHub: e.appOrHub ? e.appOrHub : "App",
        authorized: e.authorized,
      });
      return r;
    }, []);
    var accounts = appsAndHubs.find((app) => app.name === "Accounts");
    var hySurvey = appsAndHubs.find((app) => app.name === "hySurvey");
    var checkAccounts = myApps.find((app) => app.title === "Accounts");
    var checkhySurvey = myApps.find((app) => app.title === "hySurvey");
    if (!checkhySurvey)
      myApps.push({
        id: hySurvey.id,
        title: hySurvey.name,
        icon: hySurvey.icon,
        url: hySurvey.url,
        appOrHub: hySurvey.appOrHub,
        authorized: hySurvey.authorized,
      });
    if (!checkAccounts)
      myApps.push({
        id: accounts.id,
        title: accounts.name,
        icon: accounts.icon,
        url: accounts.url,
        appOrHub: accounts.appOrHub,
        authorized: accounts.authorized,
      });
    return [...myApps, ...journeyAIModules];
  };

  getIcon = (name) => {
    var icon = appsAndHubs.find((e) => e.name === name);
    if (icon) return icon.icon;
    else return "icon-system-update";
  };

  handleChange = (expanded, sectionName) => {
    if (sectionName === "appSection") {
      this.setState({
        appSection: !this.state.appSection,
      });
    } else if (sectionName === "notAuthorizedSection") {
      this.setState({
        notAuthorizedSection: !this.state.notAuthorizedSection,
      });
    } else if (sectionName === "journeyAIModulesSection") {
      this.setState({
        journeyAIModulesSection: !this.state.journeyAIModulesSection,
      });
    }
  };

  handleLearn = () => {
    setCurrentDate();
    this.setState(
      {
        banner: false,
      },
      () => window.open(`${switcherNotify_link}`, "_blank")
    );
  };

  handleCancel = () => {
    setCurrentDate();
    this.setState({
      banner: false,
    });
  };

  render() {
    const { classes, closeAppSwitcher } = this.props;
    const {
      loading,
      activeApp,
      apps,
      hubs,
      notAuthorized,
      appSection,
      notAuthorizedSection,
      journeyAIModules,
      journeyAIModulesSection,
      banner,
    } = this.state;

    if (loading) {
      return <Loader />;
    } else {
      return (
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className={classes.root}>
              {banner && (
                <Banner
                  banner="The App Switcher has been improved! Jump over to our KB to
                  learn more."
                  laterButton="Later"
                  actionButton="Learn More"
                  handleLater={this.handleCancel}
                  handleAction={this.handleLearn}
                />
              )}

              <Expansion
                controlled
                open={appSection}
                defaultPadding
                id="appSection"
                type="children"
                section="My Apps & Hubs"
                children={
                  <div>
                    <div className={classes.expansionBody}>
                      {apps &&
                        apps.length > 0 &&
                        apps.map((app, index) => (
                          <SmallApp
                            key={"app-" + index}
                            {...this.props}
                            icon={app.icon}
                            title={app.title}
                            url={app.url}
                            handleClick={this.handleClick}
                            active={app.title === activeApp ? true : false}
                          />
                        ))}
                    </div>

                    {hubs && hubs.length > 0 && (
                      <div>
                        <div class={classes.separator} />
                        <div className={classes.expansionBody}>
                          {hubs.map((app, index) => (
                            <SmallApp
                              key={"app-" + index}
                              {...this.props}
                              icon={app.icon}
                              title={app.title}
                              url={app.url}
                              handleClick={this.handleClick}
                              active={app.title === activeApp ? true : false}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                }
                checkIcon={false}
                disabled={false}
                handleChange={this.handleChange}
              />

              {journeyAIModules && journeyAIModules.length > 0 && (
                <Expansion
                  controlled
                  open={journeyAIModulesSection}
                  defaultPadding
                  id="journeyAIModulesSection"
                  type="children"
                  section="Journey AI (New)"
                  children={
                    <div className={classes.expansionBody}>
                      {journeyAIModules.map(
                        (app, index) =>
                          app.title !== activeApp && (
                            <SmallApp
                              key={"app-" + index}
                              {...this.props}
                              icon={app.icon}
                              title={app.title}
                              url={app.url}
                              handleClick={this.handleClick}
                              active={app.title === activeApp ? true : false}
                            />
                          )
                      )}
                    </div>
                  }
                  checkIcon={false}
                  disabled={false}
                  handleChange={this.handleChange}
                />
              )}

              {notAuthorized && notAuthorized.length > 0 && (
                <Expansion
                  controlled
                  open={notAuthorizedSection}
                  defaultPadding
                  id="notAuthorizedSection"
                  type="children"
                  section="Not Authorized"
                  children={
                    <div className={classes.expansionBody}>
                      {notAuthorized.map(
                        (app, index) =>
                          app.title !== activeApp && (
                            <SmallApp
                              key={"app-" + index}
                              {...this.props}
                              icon={app.icon}
                              title={app.title}
                              url={app.url}
                              handleClick={this.handleClick}
                              active={app.title === activeApp ? true : false}
                            />
                          )
                      )}
                    </div>
                  }
                  checkIcon={false}
                  disabled={false}
                  handleChange={this.handleChange}
                />
              )}
            </div>
          </Grid>
        </Grid>
      );
    }
  }
}

export default withStyles(styles)(AppSwitcher);
