import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
// import environment from '../../environment';
import myhyly_environment from '../myhyly_environment';

const mutation = graphql`
    mutation confirmAccountMutation($input: confirmAccountInput!){
      confirmAccount(input:$input) {
        clientMutationId
        response
      }
}`;

function commit(email) {
  return new Promise((res, rej) => (
      commitMutation(myhyly_environment, {
      mutation,
      variables: {
        input: {
          email,
          clientMutationId: `adminConfirmAccount-${email}`
        }
      },
      onCompleted: response => res(response),
      onError: error => rej(error)
      })
    )
  );
}

export default commit;
