import React,{ useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';

import Typography from '../Typography';
import Icon from '../Icon';
import Button from '../Button';

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    background: theme.palette.whiteGrey,
    textAlign:'center',
    minHeight:55,
    padding: `16px`,
  },
  close_icon: {
      display:'block',
      position: 'absolute',
      right: '0px',//theme.spacing.unit,
      top: '0px',
      margin:theme.margins.xs
  },
  header: {
    paddingRight:theme.margins.xs,
    fontWeight:500
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography className = {classes.header} variant="h4" color = "darkBlack">
        {children}
      </Typography>
      {onClose ? (
          <div className = {classes.close_icon}>
            <Icon
              size = "sm"
              color = "deepGrey"
              type = 'icon-e-remove'
              onClick={onClose}
            />
          </div>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    background:theme.palette.white,
    margin: 0,
    padding: theme.spacing.md,//unit * 2,
    textAlign:'center'
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    background:theme.palette.white,
    margin: 0,
    padding: `0 ${theme.spacing.md}px ${theme.spacing.md}px ${theme.spacing.md}px`,
    alignItems:'center'
  },
}))(MuiDialogActions);


const ModalNotification = ({
  header,
  body,
  primary_cta_text,
  secondary_cta_text,
  ...props
}) => {
  const [{open}, set] = useState({open: false});

  const handleClose = () => set({open: false})

  async function button_actions(e, func) {
    await set({ open: false });
    if (Boolean(props[func])) { await props[func](e) }
  }

  useEffect(() => {
    if (props.open !== undefined ) { set({ open: props.open }) }
  },[props.open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth = "xs"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {header}
      </DialogTitle>
      <DialogContent>
        <Typography variant = "body1" color = "darkBlack">
          {body}
        </Typography>
      </DialogContent>
      <DialogActions>
      <Grid container justify = "center">
        <Button
          size = "md"
          onClick = {e => button_actions(e, "primary_cta_action")}
        >{primary_cta_text}
       </Button>
         <Button
          size = "md"
          variant = "secondary"
          onClick = {e => button_actions(e, "secondary_cta_action")}
         >
       {secondary_cta_text}
       </Button>
      </Grid>
     </DialogActions>
   </Dialog>
  )
}

ModalNotification.propTypes = {
  open: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  primary_cta_text: PropTypes.string.isRequired,
  primary_cta_action: PropTypes.func.isRequired,
  secondary_cta_text: PropTypes.string.isRequired,
  secondary_cta_action: PropTypes.func.isRequired
}
ModalNotification.defaultProps = {
  cancel_button_text: "cancel",
  open: false
}

export default ModalNotification;
