import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

import SvgIcon from '@material-ui/core/SvgIcon';

import { HylyCheckbox, HylyRadio } from '../dropDown/icons';

const SelectorsGroup = withStyles(theme => ({
    "selector-radio": {
      padding:0,
      margin:`4px 0 4px -10px`
    },
    "selector-checkbox": {
      padding:0,
      margin:`4px 0 4px -10px`
    },
    label: {
      marginBottom:'12px',
    //  color: theme.palette.darkBlack,
      '&$labe_focused': {
    //    color: theme.palette.darkBlack,
      }
    },
    labe_focused: {},
    control: {
      fontSize:'16px !important',
      padding:theme.spacing.xs,
    }
  }))(({

    value,
    onChange,
    classes,
    values,
    Control,
    FormGroupComponent,
    label,
    name,
    variant,
    disabled_fields

  }) => {

  const handle_change = (e, checked) => {
    let { value: target_value } = e.target;
    if ( checked ) {
      onChange({...e, target: { name, value: value.filter(v => v !== target_value)} })
    }
    else {
      onChange({...e, target: { name, value: [ ...value, e.target.value ]} })
    }
  }


  return (
      <FormControl component="fieldset">
        <FormLabel
          component="legend"
          classes = {{
            root: classes.label,
            focused: classes.labe_focused
          }}
        >
        {label}
        </FormLabel>
        <FormGroupComponent
          aria-label={label}
          name= {name}
          value= {value}
          onChange={variant === "selector-radio" ? onChange : undefined}
        >
        {
          values.map((name) =>  {
            const checked = variant === "selector-checkbox" ? value.indexOf(name) > -1 : undefined;
            return (
              <FormControlLabel
                  key = {name}
                  className = {classes[variant]}
                  value={name}
                  control={
                    <Control
                      checked = { checked }
                      onChange={ variant === "selector-checkbox" ? e => handle_change(e, checked) : undefined }
                      className = { classes.control }
                      icon_styles = {{fontSize: 16}}
                    />
                  }
                  label={name}
                  disabled = {new RegExp(`(^${name}|,${name})+(,(?!$)|$)+`, "gi").test(disabled_fields)}
              />
            )
          })
        }
      </FormGroupComponent>
    </FormControl>
  );
})

SelectorsGroup.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string,PropTypes.array]).isRequired,
  onChange: PropTypes.func,
  values: PropTypes.arrayOf(PropTypes.string),
  label:PropTypes.string,
  name: PropTypes.string.isRequired,
  Control: PropTypes.oneOf([HylyRadio, HylyCheckbox]),
  FormGroupComponent: PropTypes.oneOf([RadioGroup, FormGroup]),
  disabled_fields: PropTypes.string
};

SelectorsGroup.defaultProps = {
  variant: "checkbox",
  value: [],
  Control: HylyRadio,
  FormGroupComponent: RadioGroup,
  disabled_fields: ""
}

export default React.memo(props => {
  switch(props.variant) {
    case "selector-radio": return <SelectorsGroup Control = {HylyRadio} FormGroupComponent = {RadioGroup} {...props}/>
    case "selector-checkbox":
    default: return <SelectorsGroup Control = {HylyCheckbox} FormGroupComponent = {FormGroup} {...props}/>
  }
});
