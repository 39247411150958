import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Typography from '../Typography'
import Input from '../HylyField/HylyField';
import Icon from '../Icon';
import ListItemIcon from '@material-ui/core/ListItemIcon';

const styles = theme => ({
  container: {
    position:'absolute',
    top:0,
    bottom: 0,
    left:0,
    paddingTop:16
  },
  list_container: {
    display:'flex',
    flexDirection: 'column',
    flex:1,
    minHeight:0,
  },
  content: {
    display:"flex",
    flexDirection:'column',
    flex:1,
    overflow:'auto'
  },
  icon_class_container: {
    marginTop: -2
  },
  root_list_item: {
    cursor: "pointer",
    padding: theme.margins.xs,
    '&:hover': {
      background: theme.palette.lightGrey,
    },
  },
  selected: {
    fontWeight: theme.fontWeight.bold,
  },
  input: {
    background: theme.palette.white,
    padding: 8
  }
})

/*
 * Search list is a generic list of items that is suitable for a search component
 * The search can have links or simple text elements and each item can have an onClick function
 * The search can also have initial icon type
*/

const SearchList = ({
  suggestions,
  classes,
  input_props ={},
  handleChange,
  search_value,
  selected_value,
  onSelect,
  icon_type,
}) => {

  return (
    <Grid direction = "column" container className = {classes.container}>
      <div style = {{ dispaly:'inline-block', padding:'0 16px'}}>
        <Input
          onChange = {handleChange}
          endAdornment = { <Icon type = "icon-zoom" size ='sm'/> }
          input_props = {{
            className: `${classes.input} ${(input_props.input_class||'')}`
          }}
          {...input_props}
        />
      </div>
      <div className = {classes.list_container}>
        <List className = {classes.content}>
        {
          Boolean(suggestions && suggestions.length)
          ? suggestions.map((item, index) => {
            return (
              <ListItem
                 key = {item.id || `list-itme-${index}`}
                 onClick = { e => onSelect(e, item) }
                 selected = {selected_value === item.id}
                 classes = {{
                   root: classes.root_list_item,
                   selected:  classes.selected
                 }}
                 {...item}
              >
                  {
                    Boolean(icon_type)
                    ? <ListItemIcon className = {classes.icon_class_container}>
                        <Icon type = {icon_type}/>
                      </ListItemIcon>
                    : null
                  }
                  <Typography type = "body1" font_weight = {600}>{item.name.slice(0, search_value.length)}</Typography>
                  <Typography
                     type = "body1"
                     font_weight = {selected_value && selected_value === item.id ? 600 : 400}
                  >
                  {item.name.slice(search_value.length)}
                 </Typography>
              </ListItem>
            )

          })
          : null
        }
        </List>
     </div>
   </Grid>
  )
}

SearchList.propTypes = {
  suggestions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  })).isRequired,
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  input_props: PropTypes.object,
  selected_value: PropTypes.string,
  icon_type: PropTypes.string,
  search_value: PropTypes.string,
  selected_value: PropTypes.string,
  onSelect: PropTypes.func
}

export default React.memo(withStyles(styles)(SearchList));
