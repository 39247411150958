import React from 'react';

import PropTypes from 'prop-types';

import ForgotPasswordPage from './forgot_password_page';
import ChangePasswordPage from './ChangePasswordPage';
import ChangeKnownPasswordPage from './change_known_password_page';

import Loader from '../../components/loading';

import { forgotPasswordSubmit, changePassword, getQueryStringValue } from '../../utils/auth_functions';

import {convert_uri} from '../../../app/utils/handle_information_transfer';

import { withRouter } from "react-router";

let notification_id = 0;

const no_user_notification = id => ({
  id: `no-user-notification${id++}`,
  type: "fail",
  body: "The email you entered didn't match any existing member."
});

const retry_notification = id => ({
  id: `retry-notification${id++}`,
  type: "fail",
  body: `The password reset link has expired since it has been used or is too old. Please Resend Verification Email.`
});



class ResetPassword extends React.Component {

  constructor(props) {
    super(props);
    let query = convert_uri(props.location.search);
    this.state = {
      email: query.email,
      code: query.code,
      oldPassword: "passwordatLeast12Ch@rs",
      isLoading: false,
      displayPage: query.displayPage || 'resetPassword',
      globalError: ""
    }

    this.set_dispaly_page = this.set_dispaly_page.bind(this);
  }

  removeGlobalError() {
    this.setState({ globalError: "" });
  }

  findCurrentPage(type) {
    switch (type) {
      case "changePasswordRequired":
      case "changePassword": return ChangePasswordPage;
      case "changeKnownPassword": return ChangeKnownPasswordPage;
      default: return ForgotPasswordPage;
    }
  }

  set_dispaly_page(displayPage) {
    this.setState({displayPage})
  }

  render() {

    const Comp = this.findCurrentPage(this.state.displayPage);

    return this.state.isLoading
            ? <Loader />
            : <Comp
                {...this.state}
                {...this.props}
                set_dispaly_page = {this.set_dispaly_page}
              />
  }
}

const {object, bool} = PropTypes;

ResetPassword.propTypes = {
  successEmailReset: bool,
  errors: object
};


export default withRouter(ResetPassword);
