import {DateTime} from 'luxon';
import {aiOrApp} from '../utils/auth_functions';

const expiration_set = DateTime.local().plus({ hours: 1 }).toString();
const expiration_remove = DateTime.local().setZone("utc").minus({ years: 1 }).toString();
const sleep = (t) => new Promise(r => window.setTimeout(r, t))

class Storage {
    // set item with the key
    static setItem(key, value, expiration) {
      document.cookie = "" + key + "=" + value
                        + ";Expires=" + (Boolean(expiration) ?
                            expiration
                            : new Date(expiration_set).toUTCString())
                        + ";Domain="+ process.env[`REACT_APP_domain_${aiOrApp}`]
                        + (process.env.NODE_ENV==="production" ? ";Secure" : "")
                        +";Path=/;SameSite=Strict;";
      return Storage.getItem(key);
    }
    // get item with the key
    static getItem (key) {

      let name = key + "=";

      for(let cookie of decodeURIComponent(document.cookie).split(';')) {
        while (cookie.charAt(0) == ' ') {
          cookie = cookie.substring(1);
        }
        if (cookie.indexOf(name) == 0) {
          return cookie.substring(name.length, cookie.length);
        }
      }
      return "";
    }
    // remove item with the key
    static removeItem (key) {
        const remove_cookie = "" + key + "=;Expires="
                          + new Date(expiration_remove).toUTCString()
                          + ";Domain=" + process.env[`REACT_APP_domain_${aiOrApp}`]
                          + (process.env.NODE_ENV==="production" ? ";Secure" : "")
                          +";Path=/;SameSite=Strict;";
        document.cookie = remove_cookie
        return remove_cookie;
    }

    static getCookiesNames () {
      return document.cookie.split(";").map(c => c.replace(/^ +/, "").replace(/=.*/,""))
    }
    // clear out the storage
    static async clear () {

      try {
        while (document.cookie !== "") {
            Storage.getCookiesNames().forEach(Storage.removeItem)
            await sleep(10)
        }

        return document.cookie;
      }
      catch (e){
        console.log("Error in localStorage.clear - ", e);
      }

    }
}

Storage.workaround = null;

export default Storage;
