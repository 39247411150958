import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {Link} from 'react-router-dom';

import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';

import { Formik } from 'formik'

import HylyField, {create_validation} from '../../StorybookComponents/HylyField'
import HylyRegularField from '../../StorybookComponents/HylyField/HylyField';

import Button from '../../StorybookComponents/Button';
import Typography from '../../StorybookComponents/Typography';

import Loader from '../../components/loading';
import { isEmpty } from 'lodash';

import {difference} from './index';
import {debounce} from 'lodash';


const default_fields = [
  { name: 'property', type: 'text', label:'Property Name',required: true },
  { name: 'name', type: 'text', label:'Contact Name',required: true },
  { name: 'email', type: 'email', label:'Email',required: true },
];

const styles = theme => ({
  button_section: {
    marginTop: theme.margins.lg,
    [theme.breakpoints.up('md')]: {
      marginTop: theme.margins.xl,
    }
  },
  field: {
    marginTop: `${theme.margins.md}px !important`,
    [theme.breakpoints.up("md")]: {
      marginTop: `${theme.margins.lg}px !important`
    }
 }
})

const ProfileFormComponent = React.memo(withStyles(styles)(
  ({
    classes,
    fields,
    handleSubmit,
    button_text,
    isSubmitting,
    checkCurrentPassword,
    touched,
    errors,
    status,
    current_disabled,
    reset_password_form,
    handleReset,
    setValues,
    setErrors,
    setStatus,
    values,
    isValid
  }) => {

    const status_is_not_empty = React.useMemo(() => {
      const found = fields.find(
        ({name, type, required: required_suffix}) =>
          status && status[`${type}__${name}${Boolean(required_suffix) ? `__required` : ''}`]
      );
      return Boolean(found);
    },[])

    return (
      <form onSubmit = {handleSubmit}>
        <Grid container direction = "column">
          {
            fields.map(({skip, initial_value, ...field}, idx) => {
              return (<HylyField
                        key = {`invite-form-field-${idx}`}
                        className = {classes.field}
                        handleBlur = {field.name === "current_password" ? checkCurrentPassword : undefined}
                        disabled = {field.name === "current_password" ? current_disabled : undefined}
                        {...field}
                      />)
            })
          }
          <Grid item className = {classes.button_section}>
            <Button
              type = "submit"
              onClick = {handleSubmit}
              size = 'md'
              state = {
                (
                  !isValid || (status && status.password__current_password__required)
                )
                ? "inactive"
                : undefined
              }
            >
             {button_text}
            </Button>
            <Button
              variant = "secondary"
              onClick = {(e) => reset_password_form(e, handleReset, setValues, setErrors, setStatus, values)}
              size = 'md'
            >
            CANCEL
           </Button>
         </Grid>
       </Grid>
     </form>
    )
}));

const form_styles = theme => ({
  spacing: {
    position:'relative',
    padding: `${theme.margins.xl+6}px ${theme.margins.xs}px ${theme.margins.xl}px ${theme.margins.xs}px`,
    [theme.breakpoints.up('md')]: {
      padding: `${theme.margins.xl+6}px ${theme.margins.xs}px ${theme.margins.xl}px ${theme.margins.md}px`,
    }
  },
  field: {
    marginTop: 0
  }
})

const ProfileForm = ({
  classes,
  title,
  handleSubmit,
  button_text,
  values,
  create_validation: custom_create_validation,
  email,
  fields:form_fields,
  checkCurrentPassword,
  current_disabled,
  reset_password_form,
  initialStatus
}) => {

  const [{fields, initialValues,validationSchema, loading}, set_initialValues] = React.useState({ initialValues:[], loading: true });


  React.useEffect(() => {

    const fields = typeof form_fields === "function"
        ? form_fields(default_fields)
        : form_fields;
    const formfields = fields.filter( ({ skip }) => !skip );
    set_initialValues({
      fields,
      initialValues: formfields.reduce(
          (
            a,
           { type, name, required: required_suffix, initial_value = "", skip }
          ) => {
          const field_name = `${type}__${name}${Boolean(required_suffix) ? `__required` : ''}`;
          return { ...a, [field_name] : initial_value }
        },{}),
      validationSchema: Boolean(custom_create_validation)
        ? custom_create_validation(formfields)
        : create_validation(formfields),
      loading: false
  }
)},[email]);

  if (loading) { return null;}

  return (
        <div className = {classes.spacing}>
          <HylyRegularField
              className = {classes.field}
              value = {email}
              name = 'email'
              type = 'email'
              label = 'Email Address'
              disabled
          />
          <Formik
            initialValues = {initialValues}
            validateOnBlur = {true}
            validateOnChange = {false}
            validationSchema = { validationSchema }
            onSubmit = {handleSubmit}
            initialStatus = {initialStatus}
            render = {
              ({ isSubmitting, ...props }) => {
                return <ProfileFormComponent
                        {...props}
                        title = {title}
                        fields = {fields}
                        button_text = {button_text}
                        isSubmitting = {isSubmitting}
                        checkCurrentPassword = {checkCurrentPassword}
                        current_disabled = {current_disabled}
                        reset_password_form = {reset_password_form}
                      />
              }
            }/>
        </div>
   );
}

ProfileForm.propTypes = {
  fields: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string.isRequired,
      name:PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })),
    PropTypes.func
  ]),
  handleSubmit: PropTypes.func.isRequired,
  button_text: PropTypes.string
}

ProfileForm.defaultProps = {
  fields: default_fields,
  button_text: "save"
}


export default React.memo(withStyles(form_styles)(ProfileForm));
