import React from 'react';

import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';

import Loader from '../../../components/loading';

import Button from '../../../StorybookComponents/Button';
import Typography from '../../../StorybookComponents/Typography';
import Logo from '../../../StorybookComponents/hylyLogo';
import HylyField, {create_validation} from '../../../StorybookComponents/HylyField';

import { Formik } from 'formik'


const check_if_has_values = obj => {
  for (let i in obj) {
    if (obj[i]) { return true; }
  }
  return false;
}

const styles = theme => ({
  container: {
    position:'absolute',
    top:0,
    left:0,
    bottom:0,
    flex:1,
    minHeight:'500px',
  },
  skip: {
    position:"absolute",
    top: theme.margins.xs,
    right: theme.margins.xs,
 },
 spacing: {
   marginTop: `${theme.margins.xl}px !important`
 },
 small_spacing: {
   marginTop: theme.margins.md
 },
 form: {
   width: '90%',
   maxWidth:'350px',
   minHeight:'400px',
   textAlign: 'center',
   flexWrap:'nowrap'
 },
 link: {
   cursor:'pointer',
   '&:hover': {
     color: theme.palette.deepBlue
   }
 }
})


const ChangePassword = ({
  classes,
  email,
  onSubmit,
  resendEmail
}) => {

    const fields = [
      {name: "email", type: "email", label: "Email", required: true},
      {name: "password", type: "password", label: "New Password", required: true},
      {name: "ps_confirm", type: "password_confirm", label: "Confirm Password", required: true}
    ];

    return (
        <Formik
          initialValues = {fields.reduce(
            (a, { name, type, required: required_suffix }) => ({
            ...a,
            [`${type}__${name}${required_suffix ? '__required' : ""}`]:
                name === "email"
                  ? email
                  : ""
          }),{})}
          validateOnBlur = {true}
          validateOnChange = {true}
          validationSchema = { create_validation(fields)}
          onSubmit = { onSubmit }
        >
      {
        ({
            values,
            isSubmitting,
            handleSubmit,
            errors,
            status,
            setFieldValue,
            setSubmitting,
            setStatus,
            isValid,
            setErrors
        }) => {
            if (isSubmitting) { return <Loader /> }

            return (
              <form onSubmit = {handleSubmit}>
                <Grid
                  container
                  direction = "column"
                  justify = "center"
                  alignItems = "center"
                  className = {classes.container}
                >
                  <Grid
                    container
                    direction = "column"
                    className = {classes.form}
                    alignItems = "center"
                  >
                      <Logo />
                      <Typography gutterBottom type='h3' font_weight = {500} className = {classes.spacing}>
                        NEW PASSWORD
                      </Typography>
                      {
                        fields.map((field,idx) => (
                          idx
                          ? <HylyField
                              key = {`change-password-${idx}`}
                              {...field}
                            />
                          : <HylyField
                              key = {`change-password-${idx}`}
                              className = {classes.spacing}
                              disabled
                              {...field}
                            />
                        ))
                      }
                      <Button
                        size = "md"
                        type = 'submit'
                        variant="primary"
                        className = {classes.spacing}
                        onClick = {handleSubmit}
                        state = {
                          check_if_has_values(errors)
                          || !isValid
                          ? "inactive"
                          : undefined
                        }
                      >
                       Set New Password
                      </Button>
                      {
                        Boolean(resendEmail)
                        ? <Typography
                             color = "hylyBlue"
                             className = {classes.small_spacing + ' ' + classes.link }
                             onClick = {
                               e => (
                                 resendEmail(
                                   values.email__email__required,
                                   setFieldValue,
                                   setSubmitting,
                                   setStatus,
                                   setErrors,
                                   values.password__password__required
                                 )
                               )
                             }
                          >
                          Resend Verification Email
                      </Typography>
                      : null
                    }
                 </Grid>
              </Grid>
            </form>
          )
        }
       }
    </Formik>
    );
}

ChangePassword.propTypes = {
  classes: PropTypes.object.isRequired,
  email: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
//  resendEmail: PropTypes.func.isRequired
};

export default React.memo(withStyles(styles)(ChangePassword));
