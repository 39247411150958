import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import NewLogo from "./NewLogo";
import * as utils from "../common/utils";
import styles from "../styles";

const defaultWMessage = utils.welcomeMessages["accounts"];

const WelcomeBoard = ({ classes, appKey }) => {
  const { appName, description1, description2 } = utils.welcomeMessages[appKey] || defaultWMessage;

  return (
    <div className={classes.welcomeBoard}>
      <div className={classes.logo}>
        <NewLogo />
        <div className={classes.logoText}>
          {(appName === "hyly.ai" || appName === "Journey" || appName === "Journey AI" || appName === "PAI") ? (
            <span>{appName}</span>
          ) : (
            <>
              hy<span>{appName}</span>
            </>
          )}
        </div>
      </div>
      <div className={classes.descText1}>{description1}</div>
      <div className={classes.descText2}>{description2}</div>

      <div className={classes.shyArtie}>
        <img src="/Shy.png" alt="Shy Artie" />
      </div>
    </div>
  );
};

export default withStyles(styles)(WelcomeBoard);
