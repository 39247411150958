import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import environment from "./environment";
import logger from './loggerPayload';

const mutation = graphql`
  mutation LoggerMutation($input: writeLogInput!) {
    writeLog(input: $input) {
      signal {
        errorDetails
        status
      }
    }
  }
`;


function handleMutation(input, res, rej) {
  return commitMutation(environment, {
      mutation,
      variables: { input },
      onCompleted: res,//d => {console.log("oncomplete: ",d); return res(d)},
      onError: e => {
        console.error(e);
        if (rej) { rej(e);}
      }
    });
}

function handleMutationPromise(input) {
  return new Promise((res, rej) => handleMutation(input, res, rej));
}

export default {
    error: (...i) => handleMutation(logger.error(...i)),
    info: (...i) => handleMutation(logger.info(...i)),
    infoPromise: (...i) => handleMutationPromise(logger.info(...i))

}
