import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';

import Input from '../HylyField/HylyField';
import Icon from '../Icon';

import SearchList from './SearchList';


const SuperficialSearch = ({
  suggestions,
  input_props,
  ...props
}) => {

  const autocomplete_map = new Map();

  const [search_value, set_value] = React.useState("");
  const [autocomplete_list, set_autocomplete_list] = React.useState([]);

  React.useMemo(() => {
    set_autocomplete_list(suggestions)
  },[suggestions]);

  /* set_map */
  function set_map(value) {
    let phrase = "",
        value_arr = value.split('');

    for (let letter of value_arr) {

      if (autocomplete_map.get(phrase + letter)) {

        phrase += letter;
        continue;
      }

      const last_values = autocomplete_map.get(phrase) || suggestions;

      if (!last_values || !last_values.length) { return ""; }

      autocomplete_map.set(
          phrase + letter,
          last_values.filter(o => new RegExp(`^${phrase+letter}`,"i").test(o.name))
      );

      phrase += letter;
    }

    set_value(value);
    return autocomplete_map.get(value) || suggestions;
  }

  /* handleChange */
  function handleChange(e) {

    const {value} = e.target;

    if (autocomplete_map.get(value)) {
      return set_autocomplete_list(autocomplete_map.get(value));
    }

    return set_autocomplete_list(set_map(value));
  }

  /* create_props */
  function create_props() {
    return {
      handleChange,
      search_value,
      suggestions: autocomplete_list,
      input_props,
      ...props
    }
  }

  return <SearchList {...create_props()} />;
}

SuperficialSearch.propTypes = {
  suggestions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }))
}

export default React.memo(SuperficialSearch);
