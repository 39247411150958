import React, {useState} from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import {debounce} from 'lodash'

import AppBar from '@material-ui/core/AppBar';

const styles = theme => ({
  container: {
    display:'flex',
    flexDirection:'column',
    position:'absolute',
    top:0,
    bottom: 0,
    left:0,
    //width:'100%'
  },
  bar: {
    background:theme.palette.white,
    color: theme.palette.black,
    boxShadow: "none",
    borderBottom:`1px solid ${theme.palette.divider}`,
    minHeight:'50px',
  },
  bar_with_shadow: {
    boxShadow:theme.google_shadow
  },
  no_border_class: {
    borderBottom:'none'
  },
  content_container: {
    display:'flex',
    flexDirection: 'column',
    flex:1,
    minHeight:0,
    position:'relative'
  },
  content: {
    display:"flex",
    flexDirection:'column',
    flex:1,
    overflow:'auto',
    minHeight:0
  }
})


const FixedTabContainer = withStyles(styles)(({
  classes: {
    container,
    content_container,
    content,
    bar,
    bar_with_shadow,
    no_border_class,
    ...other_classes
  },
  container_class,
  tab_component,
  children,
  toggleDrawer,
  no_shadow,
  controlled_scroll,
  no_border,
  content_container_override_class,
  ...other
}) => {

  let ref;

  const [s_scroll, set] = useState(null);

  const handleScroll = () => {
    if (Boolean(ref)) {
      set(ref.scrollTop);
    }
  }

  const is_scrolled = (state_scroll, no_shadow, controlled_scroll) => {
    if (controlled_scroll) { return true; }
    else if (s_scroll && !no_shadow) { return true; }
    return false;
  }

  const root = bar +
               (no_border ? ` ${no_border_class}` : '') +
               (is_scrolled(s_scroll, no_shadow, controlled_scroll) ? ` ${bar_with_shadow}` : '');


  const construct_props = () => {
    let base_props = { className : content };
    if (no_shadow) { return base_props; }
    return {
      ...base_props,
      ref: d => ref = d,
      onScroll: debounce(handleScroll, 150, { leading: true, trailing: false })
    }
  }


  return (
    <div className = {container + ' ' + container_class}>
        <AppBar
           position = "static"
           classes = {{ root }}
        >
         {React.cloneElement(tab_component, {toggleDrawer})}
       </AppBar>
       <div
          className = { content_container }
          {...other}
       >
          <div {...construct_props()}>
          { children }
          </div>
      </div>
   </div>
  )
});

FixedTabContainer.propTypes = {
  classes: PropTypes.shape({
    bar: PropTypes.string.isRequired,
    bar_with_shadow: PropTypes.string.isRequired,
    no_border_class: PropTypes.string.isRequired,
  }),
  container_class: PropTypes.string,
  TabComponent: PropTypes.func,
  no_shadow: PropTypes.bool,
  no_border: PropTypes.bool,
  children: React.isValidElement,
  container_override_class: PropTypes.string, // override the container class
  controlled_scroll: PropTypes.bool

}


export default withStyles(styles)(FixedTabContainer);
