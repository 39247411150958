/**
 * @flow
 * @relayHash 836b1f5c57c53bb5d10dd3e02e128408
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DashboardQueryVariables = {|
  orgId?: ?string,
  propertyId?: ?string,
|};
export type DashboardQueryResponse = {|
  +apps: $ReadOnlyArray<{|
    +id: string,
    +name: string,
    +url: string,
    +authorized: boolean,
    +appOrHub: string,
  |}>
|};
export type DashboardQuery = {|
  variables: DashboardQueryVariables,
  response: DashboardQueryResponse,
|};
*/


/*
query DashboardQuery(
  $orgId: ID
  $propertyId: ID
) {
  apps(orgId: $orgId, propertyId: $propertyId) {
    id
    name
    url
    authorized
    appOrHub
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orgId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "propertyId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "apps",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "orgId",
        "variableName": "orgId",
        "type": "ID"
      },
      {
        "kind": "Variable",
        "name": "propertyId",
        "variableName": "propertyId",
        "type": "ID"
      }
    ],
    "concreteType": "App",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "url",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "authorized",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "appOrHub",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "DashboardQuery",
  "id": null,
  "text": "query DashboardQuery(\n  $orgId: ID\n  $propertyId: ID\n) {\n  apps(orgId: $orgId, propertyId: $propertyId) {\n    id\n    name\n    url\n    authorized\n    appOrHub\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DashboardQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "DashboardQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '35b4ab4c3c1d5b3d2e243fb4ea94ebe4';
module.exports = node;
