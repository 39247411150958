import React from 'react';
import PropTypes from 'prop-types';
import {Auth} from 'aws-amplify';

import { withRouter } from "react-router";


import LazyComp from './lazy';

import withStyles from '@material-ui/core/styles/withStyles';

import NavDrawer from '../../StorybookComponents/NavDrawer';
import TopNav from '../../StorybookComponents/TopNav';
import Loader from '../../components/loading';
import Dashboard from './Dashboard';
import PasswordPage from './password_change'
import {Link} from 'react-router-dom';

import { transform, isEqual, isObject, isEmpty } from 'lodash';
import { handleGotoPath, gotoMyhyly, aiOrApp, redirectAfterAuth } from '../../utils/auth_functions';


export function difference(object, base) {
	return transform(object, (result, value, key) => {
		if (!isEqual(value, base[key])) {
			result[key] = isObject(value) && isObject(base[key]) ? difference(value, base[key]) : value;
		}
	});
}

const profileMenu = [
  {
    "to": "/",
    "path": '/',
    "title": "Apps",
    "icon_type": "icon-apps-1"
  },
	{
    "to": "/change-password",
    "path": '/change-password',
    "title": "Change Password",
    "icon_type": "icon-access-key"
  }
]

const profileMenuSAML = [
	{
		"to": "/",
		"path": '/',
		"title": "Apps",
		"icon_type": "icon-apps-1"
	}
]

const { goto, alias } = handleGotoPath(aiOrApp);

const ProfilePage = ({
	set_notifications,
	page,
	user,
	redirectToAuthRoute,
	classes
}) => {

	const attr  		= user && user.attributes;
  const userEmail = attr.email || JSON.parse(attr.identities)[0].userId;

	// console.log(JSON.stringify(user, null, 2));
	const [loading, setLoading] = React.useState(true);
	const navMenu = (attr && attr.identities)
	? profileMenuSAML
	: profileMenu

	const navPath = (attr && attr.email)
	? pathNav : pathNavSAML

	const currentUser = React.useMemo(() => {
		const { name, email = userEmail, sub: id } 	= attr || {};
		const { jwtToken: token } 									= user && user.signInUserSession && user.signInUserSession.idToken;
		return { name: name || email, email, id, token }
	}, [user])


	React.useEffect(() => {
		async function redirectUser () {
			if (!goto || alias == "accounts") {
				setLoading(false);
				return;
			}
	
			console.log(
				"redirectToGoto start. \n"+
				"localStorage.redirect-app-after-auth is: "+ window.localStorage.getItem('redirect-app-after-auth')+",\n",
				"goto is: " + goto+ ", alias is: " + alias
			);
	
			window.localStorage.removeItem('redirect-app-after-auth')
	
			// if (['myhyly', 'myhyly-probe'].includes(alias)) {
			// 	gotoMyhyly(user, alias);
			// }
	
			// else 
			if (!['set-password','login','verify'].includes(goto) && alias !== "accounts"){ 
				// window.location.href = goto; 
				await redirectAfterAuth(goto, alias);
			}
		}
		
		redirectUser();

	},[user, goto, alias])


// let PageComponent
	const PageComponent = React.useMemo(() => {
		switch(page) {
			case 'change-password': return PasswordPage;
			case 'profile':
			default: return Dashboard;
		}
	},[page])

	const pathNav = React.useMemo(() => {
		switch(page) {
			case 'profile': return 'Apps';
			case 'change-password': return 'Change Password';
			default: return "Page not found";
		}
	},[page]);

	const pathNavSAML = React.useMemo(() => {
		switch(page) {
			case 'profile': return 'Apps';
			default: return "Page not found";
		}
	},[page]);

	const logout = async(e) => redirectToAuthRoute("accounts");

	if (loading) { return <Loader /> }

	return (
		<NavDrawer
				user = {currentUser}
				menu = {navMenu}
				pathname = {"/" + page}
				logout_function = {logout}
				link_component = {Link}
		>
			<TopNav pathnames = {pathNav}>
				<PageComponent
						set_notifications = {set_notifications}
						redirectToAuthRoute = {redirectToAuthRoute}
						{...currentUser}
				/>
			</TopNav>
	 </NavDrawer>
	);
}

export default React.memo(ProfilePage);
