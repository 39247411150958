import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import logger from "../../../components/Logger";
import { confirmSignup } from "../../../utils/auth_functions";
import { convert_uri } from "../../../utils/handle_information_transfer";

import * as utils from "../common/utils";
import { EyeIcon, EyeClosedIcon } from "../components/Icons";
import styles from "../styles";

const Form = ({
  classes,
  location,
  history,
  values,
  errors,
  touched,
  isSubmitting,
  handleChange,
  handleBlur,
  handleSubmit,
  setNotifications,
  notificationId,
  setNotificationId,
}) => {
  const [showPwd, setShowPwd] = React.useState(false);

  const togglePwd = () => setShowPwd((prev) => !prev);

  const renderEyeIcon = () => {
    if (values.password.length < 1) return null;
    if (showPwd)
      return (
        <EyeClosedIcon
          viewBox="0 0 20 20"
          className={classes.showPwdIcon}
          onClick={togglePwd}
        />
      );

    return (
      <EyeIcon
        viewBox="0 0 20 20"
        className={classes.showPwdIcon}
        onClick={togglePwd}
      />
    );
  };

  const confirmSignupCode = async (code, email) => {
    try {
      if (!/^[a-z0-9A-Z]+$/.test(code) || !utils.rEmail.test(email)) {
        return history.replace("/login");
      }

      const user = await confirmSignup(email, code);
      if (user === "SUCCESS") {
        logger.info(email, "login", "confirmSignupCode");

        setNotifications({
          id: "account-confirmed",
          type: "success",
          body: "Congratulations! You have confirmed your email",
        });
        history.replace("/login");
      }
    } catch (error) {
      logger.error(email, "login", error, "confirmSignupCode");

      if (error === "Code cannot be empty") {
        history.push("/verify");
        return;
      }
      const { code } = error;

      switch (code) {
        case "CodeMismatchException":
        case "InvalidParameterException":
        case "ExpiredCodeException":
          history.push("/verify");
          break;
        case "LimitExceededException":
        case "TooManyFailedAttemptsException":
        case "TooManyRequestsException":
          setNotifications(utils.limitExceededNotification(notificationId + 1));
          setNotificationId(notificationId + 1);
          break;
        case "UserNotFoundException":
        case "NotAuthorizedException":
        default:
          if (process.env.REACT_APP_env === "dev")
            console.error(`Error in confirmSignup(): ${error}`);
          history.replace("/login");
      }
    }
  };

  React.useEffect(() => {
    const confirm_account = async () => {
      let query = convert_uri(location.search) || {};
      if (query.code && query.email) {
        await confirmSignupCode(query.code, query.email.toLowerCase());
      }
    };

    confirm_account();
  }, []);

  return (
    <form className={classes.formRoot} onSubmit={handleSubmit}>
      <div className={classes.formTitle}>Welcome Back!</div>
      <div className={classes.formDescText1} style={{ marginTop: "0.25rem" }}>
        Sign in to your account.
      </div>
      <div style={{ marginTop: "2rem" }}>
        <TextField
          label="Email"
          name="email"
          type="email"
          className={classes.inputField}
          fullWidth
          InputProps={{ classes: { underline: classes.underline } }}
          helperText={!!errors.email && touched.email ? errors.email : null}
          error={!!errors.email && touched.email}
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div style={{ marginTop: "1.5rem" }}>
        <TextField
          label="Password"
          name="password"
          type={showPwd ? "text" : "password"}
          className={classes.inputField}
          fullWidth
          InputProps={{
            classes: { underline: classes.underline },
            endAdornment: renderEyeIcon(),
          }}
          helperText={
            !!errors.password && touched.password ? errors.password : null
          }
          error={!!errors.password && touched.password}
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>

      <div style={{ marginTop: "1.25rem" }}>
        <div className={classes.extraFields}>
          <FormControlLabel
            control={
              <Checkbox
                classes={{
                  root: classes.checkbox,
                  checked: classes.checkedbox,
                }}
              />
            }
            label={<div className={classes.rememberMeLabel}>Remember me</div>}
            className={classes.rememberMeGroup}
          />
          <Button
            variant="text"
            className={classes.forgotPassword}
            onClick={() => history.push("/set-password")}
          >
            Forgot password?
          </Button>
        </div>
      </div>

      <div style={{ marginTop: "1.5rem" }}>
        <Button
          type="submit"
          className={classes.signin}
          variant="contained"
          fullWidth
          disabled={isSubmitting}
        >
          Sign In
        </Button>
      </div>
    </form>
  );
};

export default withStyles(styles)(Form);
